<template>
  <el-form
      :model="paymentData" :rules="rules"
      class="PaymentMethod__Form--MercadoPago"
      id="mercadoPago-form"
    >
    <el-row :gutter="20">
      <el-col :span="24" :sm="12">
        <div class="el-form-outer">
          <el-form-item
            prop="full_name"
            :label="`Nome impresso no cartão`"
            :class="{
              'el-form-item--active':
                activeInput === `Nome impresso no cartão`,
            }"
          >
            <el-input
              :placeholder="``"
              name="cc-name"
              id="mercadoPago-form__cardholderName"
              autocomplete="cc-name"
              @focus="setActiveInput(`Nome impresso no cartão`)"
              @blur="setActiveInput('')"
              v-model="paymentData.full_name"
            ></el-input>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="24" :sm="12">
        <div class="el-form-outer">
          <el-form-item
            prop="document"
            :label="`CPF/CNPJ do titular do cartão`"
            v-facade="['###.###.###-##', '##.###.###/####-##']"
            :class="{
              'el-form-item--active':
                activeInput === `CPF/CNPJ do titular do cartão`,
            }"
          >
            <el-input
              :placeholder="``"
              name="identificationNumber"
              id="mercadoPago-form__identificationNumber"
              pattern="\d*" inputmode="numeric"
              @focus="setActiveInput(`CPF/CNPJ do titular do cartão`)"
              @blur="setActiveInput('')"
              v-model="paymentData.document"
            ></el-input>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="el-form-outer">
          <label>
              <el-form-item
                prop="cc_number"
                :label="`Número do cartão de crédito`"
                v-facade="['#### #### #### ####', '#### #### #### #####']"
                :class="{'el-form-item--active': activeInput === `Número do cartão de crédito`}"
              >
                <el-input
                  v-model="paymentData.cc_number"
                  name="cardNumber"
                  pattern="\d*" inputmode="numeric"
                  id="mercadoPago-form__cardNumber"
                ></el-input>
              </el-form-item>
          </label>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24" :xs="24" :sm="24" :md="8">
        <div class="el-form-outer">
          <label>
            <el-form-item
                prop="cc_cvv"
                :label="`CVV`"
                :class="{'el-form-item--active': activeInput === `CVV`}"
              >
                <el-input
                  v-model="paymentData.cc_security_code"
                  name="securityCode"
                  id="mercadoPago-form__securityCode"
                ></el-input>
              </el-form-item>
          </label>
        </div>
      </el-col>
      <el-col  :span="12" :xs="12" :sm="12" :md="8">
          <div class="el-form-outer">
            <label>
              <el-form-item
                  :label="`Mês de validade`"
                  :class="{'el-form-item--active': activeInput === `Data de validade`}"
                >
                  <el-input
                    v-model="paymentData.cc_expiration_month"
                    name="cardExpirationMonth"
                    id="mercadoPago-form__cardExpirationMonth"
                    maxlength="2"
                  ></el-input>
              </el-form-item>
            </label>
          </div>
      </el-col>
      <el-col  :span="12" :xs="12" :sm="12" :md="8">
          <div class="el-form-outer">
            <label>
              <el-form-item
                  prop="cc_expiration_year"
                  :label="`Ano de validade`"
                  :class="{'el-form-item--active': activeInput === `Data de validade`}"
                >
                  <el-input
                    v-model="paymentData.cc_expiration_year"
                    name="cardExpirationYear"
                    id="mercadoPago-form__cardExpirationYear"
                    maxlength="2"
                  ></el-input>
              </el-form-item>
            </label>
          </div>
      </el-col>
      <el-col :span="24">
        <div class="el-form-outer">
          <el-form-item
            prop="cc_installments"
            :label="`Número de parcelas`"
            :class="{
              'el-form-item--active': activeInput === `Número de parcelas`,
            }"
          >
            <el-select
              v-model="paymentData.cc_installments"
              :placeholder="`Selecione`"
              @focus="setActiveInput(`Número de parcelas`)"
              @blur="setActiveInput('')"
            >
              <el-option
                v-for="installment in EligibleInstallments"
                :key="installment.installments"
                :label="installmentMessage(installment)"
                :value="installment"
              >
                <span style>{{ installmentMessage(installment) }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div v-show="showInstallments" class="el-form-outer">
          <el-form-item
            prop="cc_installments"
            :label="`Número de parcelas`"
            :class="{
              'el-form-item--active': activeInput === `Número de parcelas`,
            }"
          >
            <div class="el-select">
              <div class="el-input el-input--suffix">
                <select v-model="mercadoPagoSelectedInstallment" class="el-input__inner" name="installments" id="mercadoPago-form__installments"></select>
              </div>
            </div>
          </el-form-item>
        </div>
        <div>
          <select v-model="identificationType" style="height: 0px; width: 0px; visibility: hidden;" name="indentificationType" id="mercadoPago-form__identificationType"></select>
        </div>
        <div>
          <select style="height: 0px; width: 0px; visibility: hidden;" v-model="paymentData.cc_brand" name="issuer" id="mercadoPago-form__issuer"></select>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import { cardValidatorFactory } from 'evhub-card-validator'

export default {
  name: 'MercadoPagoForm',
  data () {
    return {
      showInstallments: false,
      cardForm: null,
      mercadoPagoSDK: null,
      activeInput: '',
      cardValidator: null,
      mercadoPagoSelectedInstallment: null,
      identificationType: null,
      brand: null,
      paymentData: {
        full_name: null,
        document: null,
        cc_number: null,
        cc_security_code: null,
        cc_expiration_year: null,
        cc_expiration_month: null,
        cc_brand: '',
        cc_last_4: '',
        cc_hash: null,
        cc_installments: null
      },
      rules: {
        full_name: [
          { validator: this.ValidateUserName }
        ],
        document: [
          {
            required: true,
            message: `Digite seu CPF OU CNPJ`,
            trigger: 'change'
          },
          { validator: this.ValidateUserDocument, trigger: 'change' }
        ],
        'cc_number': [
          { validator: this.ValidateCardNumber }
        ],
        'cc_cvv': [
          { validator: this.ValidateCVV }
        ],
        'cc_expiration_year': [
          { validator: this.ValidateCardDate }
        ],
        cc_installments: [
          {
            required: true,
            message: 'Selecione suas parcelas',
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutConfig']),
    EligibleInstallments () {
      let minimumInstallment = this.CheckoutConfig.paymentInformationConfig.credit_card_config.minimum_installment_value
      return this.method.installments.filter(_i => {
        return _i.installments === 1 || _i.value >= minimumInstallment
      })
    },
    CheckoutTotal () {
      return this.Checkout.totals.total
    },
    PublicKey () {
      return this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.pubKey
    },
    CCBrandName () {
      switch (this.GetCardBrand()) {
        case 'amex':
          return 'Amex'
        case 'argencard':
          return 'Argencard'
        case 'bcmc':
          return 'Bancontact/Mister Cash'
        case 'bijcard':
          return 'de Bijenkorf Card'
        case 'cabal':
          return 'Cabal'
        case 'cartebancaire':
          return 'Carte Bancaires'
        case 'codensa':
          return 'Codensa'
        case 'cup':
          return 'China Union Pay'
        case 'dankort':
          return 'Dankort'
        case 'diners':
          return 'Diners Club'
        case 'discover':
          return 'Discover'
        case 'electron':
          return 'Electron'
        case 'elo':
          return 'ELO'
        case 'forbrugsforeningen':
          return 'Forbrugsforeningen'
        case 'hiper':
          return 'Hiper'
        case 'hipercard':
          return 'HiperCard'
        case 'jcb':
          return 'JCB'
        case 'karenmillen':
          return 'Karen Millen GiftCard'
        case 'laser':
          return 'Laser'
        case 'maestro':
          return 'Maestro'
        case 'maestrouk':
          return 'Maestro UK'
        case 'mc':
          return 'master'
        case 'mcalphabankbonus':
          return 'Alpha Bank Mastercard Bonus'
        case 'mir':
          return 'MIR'
        case 'naranja':
          return 'Naranja'
        case 'oasis':
          return 'Oasis GiftCard'
        case 'rupay':
          return 'RuPay'
        case 'shopping':
          return 'Tarjeta Shopping'
        case 'solo':
          return 'Solo'
        case 'troy':
          return 'Troy'
        case 'uatp':
          return 'UATP'
        case 'visa':
          return 'Visa'
        case 'visaalphabankbonus':
          return 'Alpha Bank Visa Bonus'
        case 'visadankort':
          return 'Visa Dankort'
        case 'warehouse':
          return 'Warehouse GiftCard'
        default:
          return null
      }
      // return this.paymentData.cc_brand
    }
  },
  props: ['method'],
  created () {
    this.cardValidator = cardValidatorFactory.default.getValidator(this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name)

    if (this.EligibleInstallments.length > 1) {
      this.paymentData.cc_installments = this.EligibleInstallments[0]
    }
  },
  mounted () {
    this.initCheckout()
  },
  destroyed () {
    this.cardForm.unmount()
  },
  methods: {
    ...mapFilters(['formatMoney', 'checkUserDocument', 'onlyTextField']),
    ...mapActions([
      'UpdatePaymentState',
      'SetPaymentMethod',
      'setCheckoutStatus'
    ]),
    installmentMessage (installment) {
      if (installment.interest_rate !== undefined) {
        return `${installment.installments}x de ${this.formatMoney(
          installment.value
        )} | Total:  ${this.formatMoney(installment.total_in_installments)} ${
          installment.interest_rate > 0
            ? `(Juros de ${installment.interest_rate}%)`
            : ``
        }`
      }
    },
    ValidateForm () {
      var $this = this
      this.$refs.PaymentInformations.validate((valid) => {
        $this.$emit('paymentFormVerified', {
          isValid: valid
        })
      })
    },
    async initCheckout () {
      var publicKey = this.PublicKey
      var checkoutTotal = this.CheckoutTotal
      this.mercadoPagoSDK = null

      try {
        // eslint-disable-next-line no-undef
        this.mercadoPagoSDK = new MercadoPago(`${publicKey}`)

        // eslint-disable-next-line no-unused-vars
        this.cardForm = this.mercadoPagoSDK.cardForm({
          amount: `${checkoutTotal}`,
          autoMount: true,
          form: {
            id: 'mercadoPago-form',
            cardholderName: {
              id: 'mercadoPago-form__cardholderName',
              placeholder: 'Titular do cartão'
            },
            cardNumber: {
              id: 'mercadoPago-form__cardNumber',
              placeholder: 'Número do cartão'
            },
            cardExpirationMonth: {
              id: 'mercadoPago-form__cardExpirationMonth',
              placeholder: 'Mês de vencimento'
            },
            cardExpirationYear: {
              id: 'mercadoPago-form__cardExpirationYear',
              placeholder: 'Ano de vencimento'
            },
            securityCode: {
              id: 'mercadoPago-form__securityCode',
              placeholder: 'Código de segurança'
            },
            installments: {
              id: 'mercadoPago-form__installments',
              placeholder: 'Parcelas'
            },
            identificationType: {
              id: 'mercadoPago-form__identificationType',
              placeholder: 'Tipo de documento'
            },
            identificationNumber: {
              id: 'mercadoPago-form__identificationNumber',
              placeholder: 'Número do documento'
            },
            issuer: {
              id: 'mercadoPago-form__issuer',
              placeholder: 'Banco emissor'
            }
          },
          callbacks: {
            onFormMounted: (error) => {
              if (error) console.warn('onFormMounted error', error)
            }
          }
        })
      } catch (error) {
        console.log('erro: ', error)
      }
    },
    setActiveInput (input) {
      this.activeInput = input
    },
    ValidateUserName (rule, value, callback) {
      if (value == null || value == undefined) { //eslint-disable-line
        return callback(new Error('Digite o nome que está no cartão'))
      }
      if (this.onlyTextField(value)) {
        return callback()
      } else {
        return callback(new Error(`Números não são permitidos`))
      }
    },
    ValidateUserDocument (rule, value, callback) {
      if (!this.checkUserDocument(value)) {
        var documentType = value.replace(/[^\d]+/g, '').length <= 11 ? 'CPF' : 'CNPJ'
        this.paymentData.identificationType = documentType
        document.getElementById('mercadoPago-form__identificationType').value = documentType

        return callback(
          new Error(
            `${
              documentType
            } inválido`
          )
        )
      } else {
        callback()
      }
    },
    ValidateCardNumber (rule, value, callback) {
      if (this.paymentData.cc_number == null || this.paymentData.cc_number == undefined) { //eslint-disable-line
        return callback(new Error('Digite o número do cartão'))
      }

      if (this.paymentData.cc_number.length < 14) {
        return callback(new Error('cartão inválido'))
      }

      if (this.cardValidator) {
        var iscardValid = this.cardValidator.validateNumber(this.paymentData.cc_number)
        if (iscardValid) {
          return callback()
        }
      }

      return callback(new Error('cartão inválido'))
    },
    ValidateCVV (rule, value, callback) {
      if (this.paymentData.cc_security_code == null || this.paymentData.cc_security_code == undefined) { //eslint-disable-line
        return callback(new Error('Digite o CVV'))
      }

      if (this.paymentData.cc_number && this.paymentData.cc_security_code) {
        var isValid = false
        isValid = this.cardValidator.validateSecurityCode(
          this.paymentData.cc_security_code
        )

        return isValid
          ? callback()
          : callback(new Error(`CVV inválido`))
      }
      return callback()
    },
    ValidateCardDate (rule, value, callback) {
      var $this = this
      var isValid = this.cardValidator.validateExpirationDate(
        $this.paymentData.cc_expiration_month,
        $this.paymentData.cc_expiration_year
      )

      return isValid
        ? callback()
        : callback(new Error(`Data inválida`))
    },
    SetInstallmentByNumber () {
      var installmentId = ''
      var installmentNumber = this.paymentData.cc_installments.installments
      var paymentId = this.$store.getters.Payment.paymentId

      if (paymentId && installmentNumber) {
        var paymentOption = this.$store.getters.PaymentOptions.find(option => option.id === paymentId)
        if (paymentOption) {
          // eslint-disable-next-line
          var installment = paymentOption.installments.find(installment => installment.installments == installmentNumber)

          if (installment) installmentId = installment.id
        }

        this.paymentData.installmentId = installmentId
      }
    },
    PaymentDataIsValid () {
      return this.paymentData.full_name != null && this.paymentData.full_name !== undefined &&
             this.paymentData.document != null && this.paymentData.document !== undefined &&
             this.paymentData.cc_installments.id != null && this.paymentData.cc_installments.id !== undefined
    },
    GetCardBrand () {
      return this.cardValidator.getCardBrand(this.paymentData.cc_number)
    },
    async GenerateCardHash () {
      try {
        var cardToken = await this.mercadoPagoSDK.createCardToken({
          cardNumber: this.paymentData.cc_number.replace(/\D/g, ''),
          cardholderName: this.paymentData.full_name,
          cardExpirationMonth: this.paymentData.cc_expiration_month,
          cardExpirationYear: this.paymentData.cc_expiration_year,
          securityCode: this.paymentData.cc_security_code,
          identificationType: this.paymentData.identificationType,
          identificationNumber: this.paymentData.document.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
        })
      } catch (error) {
        console.log('Não foi possível criar hash', error)
      }
      return cardToken.id
    }
  },
  watch: {
    paymentData: {
      deep: true,

      async handler (val) {
        var lastFourNumbers = ''
        var $this = this

        if ($this.paymentData.cc_number &&
            $this.paymentData.cc_number.length > 14) {
          lastFourNumbers = $this.paymentData.cc_number.substr($this.paymentData.cc_number.length - 4)
        }
        try {
          if (this.PaymentDataIsValid()) {
            var cardToken = await this.GenerateCardHash()

            var cardBrand = this.GetCardBrand()
            if (cardBrand === 'mastercard') cardBrand = 'master'
            if (cardToken && cardBrand) {
              this.UpdatePaymentState({
                default_hash: {
                  successfullyCreated: true,
                  hash: cardToken
                },
                brand: cardBrand,
                last_four_numbers: lastFourNumbers,
                full_name: this.paymentData.full_name,
                document: this.paymentData.document,
                installmentId: this.paymentData.cc_installments.id
              })
            }
          }
        } catch (error) {
          console.log('Não foi possível gerar hash', error)
        }
      }
    },
    deep: true,
    'paymentData.cc_installments': function (installment) {
      this.SetPaymentMethod(installment)
    },
    identificationType: {

    },
    mercadoPagoSelectedInstallment: {
      deep: true,
      handler (val) {
        if (val) {
          this.paymentData.cc_installments = this.EligibleInstallments[val - 1]

          this.SetInstallmentByNumber()
        }
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
  opacity: 0;
}
</style>
