import Analytics from '@/services/analytics'
import Tracking from '@/services/tracking'

const state = {
}

const getters = {
}

const actions = {
  async setAnalyticsTools ({ commit, dispatch }, analyticsConfig) {
    if (analyticsConfig.google_analytics) { await Analytics.GoogleAnalytics._setup(analyticsConfig.google_analytics) }
    if (analyticsConfig.google_adwords) { await Analytics.GoogleAdwords._setup(analyticsConfig.google_adwords) }
    if (analyticsConfig.google_tag_manager) { await Analytics.GoogleTagManager._setup(analyticsConfig.google_tag_manager) }
    if (analyticsConfig.facebook_pixel) { await Analytics.FacebookPixel._setup(analyticsConfig.facebook_pixel) }
    if (analyticsConfig.tiktok_pixel) { await Analytics.TikTokPixel._setup(analyticsConfig.tiktok_pixel) }
  },
  async setConversion ({ commit, rootState, dispatch }, conversionOBJ) {
    let analyticsConfig = rootState.CheckoutConfig.CheckoutConfig.analyticsConfig
    Analytics.GlobalTracking.conversion({ order: conversionOBJ.order })
    if (analyticsConfig.google_analytics) { Analytics.GoogleAnalytics.conversion({ order: conversionOBJ.order }) }
    if (analyticsConfig.google_adwords) { Analytics.GoogleAdwords.conversion({ order: conversionOBJ.order }) }
    if (analyticsConfig.facebook_pixel) { Analytics.FacebookPixel.conversion({ order: conversionOBJ.order }) }
    if (analyticsConfig.tiktok_pixel) { Analytics.TikTokPixel.conversion({ order: conversionOBJ.order }) }
  },
  async setAnalyticsError ({ commit, rootState, dispatch }, { id, method, error, fatal }) {
    let analyticsConfig = rootState.CheckoutConfig.CheckoutConfig.analyticsConfig
    Tracking.event(`${method} Error`, {
      id,
      error,
      type: 'error'
    })
    if (analyticsConfig.google_analytics) {
      Analytics.GoogleAnalytics.event(`Checkout`, `Error: ${method}`, `${id}: ${error}`)
      Analytics.GoogleAnalytics.error(`${method}: ${error}`, fatal)
    }
  }
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
