
export class MaxiPagoCustomer {
  constructor(
    customerIdExt,
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    zip,
    country,
    phone,
    email,
    dob,
    sex) {
    this.customerIdExt = customerIdExt
    this.firstName = firstName
    this.lastName = lastName
    this.address1 = address1
    this.address2 = address2
    this.city = city
    this.state = state
    this.zip = zip
    this.country = country
    this.phone = phone
    this.email = email
    this.dob = dob
    this.sex = sex
  }
}
