import { PaymentServices } from './paymentServices'
import { PagarMeApi } from '../../api/pagarMeApi'
import validator from './evHubPaymentValidator'
import { Base64Helper } from '../../helpers/base64Helper'

export class PagarMeServices extends PaymentServices {
  #client;
  #helper = new Base64Helper();

  constructor () {
    super()

    if (this.pubKey) {
      this.#client = new PagarMeApi(this.pubKey)
    }
  }

  setPubKey (pubKey) {
    this.#client = new PagarMeApi(pubKey)
    return super.setPubKey(pubKey)
  }

  async createPaymentHash (number, cvc, expirationMonth, expirationYear) {
    var card = {
      number: number.replaceAll(' ', ''),
      holder_name: this.userName.normalize('NFKD').replace(/[^\w\s.-]/g, ''),
      holder_document: this.documentNumber.replaceAll('.', '').replaceAll('-', '').replaceAll('/', ''),
      exp_month: expirationMonth,
      exp_year: expirationYear,
      cvv: cvc
    }
    var pagarMeHash = await this.#client.createCardToken(card)
    var hashObj = {
      hash: pagarMeHash,
      code: cvc
    }

    return this.#helper.encode(hashObj)
  }

  validateNumber (cardNumber) {
    return validator.validateCardNumber(cardNumber)
  }

  getCardBrand (cardNumber) {
    return validator.getCardBrand(cardNumber)
  }

  validateSecurityCode (cardNumber, securityCode) {
    var isValid = validator.validateSecurityCode(securityCode)

    return isValid
  }

  validateExpirationDate (month, year) {
    return validator.validateExpirationDate(month, year)
  }
}
