<template>
  <el-card class="PaymentMethod__Form">
    <el-form
      ref="PaymentInformations" :model="paymentData"
      class="PaymentMethod__Form--Custom Form--Custom"
    >
      <div class="Checkout__Section" v-if="method.type === 'free' && CheckoutConfig.paymentInformationConfig.bankslip_config.disclaimer_at_checkout">
        <el-row :gutter="20">
          <el-col :span="24">
            <span class="Checkout__Section__Text--inner">Clique em <b>continuar</b> para finalizar seu pedido.</span>
          </el-col>
        </el-row>
      </div>
      <div class="Checkout__Section" v-if="method.type === 'boleto' && CheckoutConfig.paymentInformationConfig.bankslip_config.disclaimer_at_checkout">
        <el-row :gutter="20">
          <el-col :span="24">
            <span class="Checkout__Section__Text--inner" v-html="CheckoutConfig.paymentInformationConfig.bankslip_config.disclaimer_at_checkout"></span>
          </el-col>
        </el-row>
      </div>
      <div class="Checkout__Section" v-if="method.type === 'manual'">
        <el-row :gutter="20">
          <el-col :span="24">
            <span class="Checkout__Section__Text--inner" v-html="method.description"></span>
          </el-col>
        </el-row>
      </div>
      <div class="Checkout__Section" v-if="method.type === 'pix' && CheckoutConfig.paymentInformationConfig.pix_config.disclaimer_at_checkout">
        <el-row :gutter="20">
          <el-col :span="24">
            <span class="Checkout__Section__Text--inner" v-html="CheckoutConfig.paymentInformationConfig.pix_config.disclaimer_at_checkout"></span>
          </el-col>
        </el-row>
      </div>
      <div class="Checkout__Section" v-if="method.type === 'credit_card' && CheckoutConfig">
        <div v-if="CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name.toLowerCase() === 'adyen'">
          <AdyenForm :method="method" />
        </div>
        <div class="Checkout__Section" v-else-if="CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name.toLowerCase() === 'mercadopago'">
          <MercadoPagoForm :method="method" />
        </div>
        <div class="Checkout__Section" v-else-if="CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name.toLowerCase() === 'paypal'">
          <pay-pal-form :method="method"></pay-pal-form>
        </div>
        <div v-else>
          <ev-hub-form :method="method"></ev-hub-form>
        </div>

        <el-row :gutter="20" v-if="method.type === 'credit_card' &&
                                  AppConfig.payment_informations.billing_info_form_enabled">
          <el-col :span="24">
            <div class="el-form-outer">
                <el-checkbox v-model="BillingInfoFormOpen" @change="toggleBillingInfoForm">Endereço da fatura do cartão é diferente do endereço de entrega</el-checkbox>
            </div>
            <div class="PaymentMethod__Form"  v-if="BillingInfoFormOpen" >
              <BillingInfoForm v-if="BillingInfoFormOpen" />
            </div>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import AdyenForm from '@/components/_paymentOptions/CreditCardForm/Adyen/AdyenForm.vue'
import EvHubForm from '@/components/_paymentOptions/CreditCardForm/EvolveHub/EvHubForm.vue'
import MercadoPagoForm from '@/components/_paymentOptions/CreditCardForm/MercadoPago/MercadoPagoForm.vue'

import BillingInfoForm from '@/components/_paymentOptions/BillingInfoForm.vue'
import PayPalForm from './CreditCardForm/PayPal/PayPalForm.vue'

export default {
  name: 'PaymentForm',
  components: {
    BillingInfoForm,
    AdyenForm,
    EvHubForm,
    MercadoPagoForm,
    PayPalForm
  },
  data () {
    return {
      BillingInfoFormOpen: false,
      paymentData: {
        cc_installments: null
      }
    }
  },
  created () {
    if (this.method.installments.length === 1) {
      this.paymentData.cc_installments = this.method.installments[0]
    }
  },
  computed: {
    ...mapGetters(['CheckoutConfig', 'AppConfig'])
  },
  props: ['method'],
  methods: {
    ...mapFilters(['formatMoney', 'checkUserDocument', 'onlyTextField']),
    ...mapActions(['UpdateCheckoutState', 'UpdatePaymentState', 'SetPaymentMethod', 'setCheckoutStatus']),
    toggleBillingInfoForm (val) {
      this.BillingInfoFormOpen = val
    }
  },
  watch: {
    deep: true,
    'paymentData.cc_installments': function (installment) {
      this.SetPaymentMethod(installment)
    }
  }
}
</script>
