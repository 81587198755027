import Sugar from 'sugar'

class Filters {
    dateFormat = function (date, format = '%d/%h') {
      return Sugar.Date.format(new Date(date), format)
    }
    dateIsValid = function (date) {
      return Sugar.Date.isValid(new Date(date))
    }
    dateIsFuture = function (date) {
      return Sugar.Date.isFuture(new Date(date))
    }
    dateIsPast = function (date) {
      return Sugar.Date.isPast(new Date(date))
    }
    dateIsToday = function (date) {
      return !Sugar.Date.isBefore(new Date(date), 'yesterday')
    }
    dateIsValid = function (date) {
      return Sugar.Date.isValid(new Date(date))
    }
    dateYearsAgo = function (date) {
      return Sugar.Date.yearsAgo(new Date(date))
    }
    objectIsEmpty = function (obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object
    }
    objectIsFull = function (obj) {
      return Object.values(obj).length === Object.keys(obj).length && obj.constructor === Object
    }
    objectClear = (obj) => {
      var propNames = Object.getOwnPropertyNames(obj)
      for (var i = 0; i < propNames.length; i++) {
        var propName = propNames[i]
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
          delete obj[propName]
        }
      }
      return obj
    }
    formatMoney = function (amount, decimalCount = 2, decimal = ',', thousands = '.') {
      try {
        return `R$ ${amount.toFixed(2)}`
      } catch (e) {
        console.log(e)
      }
    }
}
export default new Filters()
