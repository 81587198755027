<template>
    <div class="el-card__list--item Checkout__Overview--gift-message" v-if="GiftMessageEnabled">
        <h6>
        Resumo do cartão personalizado:
        <el-button
            v-if="edit"
            style="float: right; padding: 3px 0"
            type="text"
            size="mini"
            @click="goTo(`/Checkout/${Checkout.id}/contact_information`)"
        >
            <i class="el-icon-edit"></i> Editar
        </el-button>
        </h6>
        <span class="Checkout__Overview--gift-message">
            <span v-if="GiftMessageFrom">
                <b>De:</b>
                {{GiftMessageFrom.value}}
                <br>
            </span>
            <span v-if="GiftMessageTo">
                <b>Para:</b>
                {{GiftMessageTo.value}}
                <br>
            </span>
            <span v-if="GiftMessageContent">
                <b>Mensagem:</b>
                {{GiftMessageContent.value}}
                <br>
            </span>
        </span>

        <!-- <ProductResume :products="data.products" :method="data.chosen_delivery_option" v-if="showProducts && data.chosen_delivery_option"/> -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RouterService from '@/services/routerService'
export default {
  name: 'GiftMessage-Preview',
  data () {
    return {
      activeInput: null
    }
  },
  created () {
  },
  computed: {
    ...mapGetters(['AppConfig', 'CheckoutStatus', 'Checkout', 'CustomAttributes']),
    GiftMessageEnabled () {
      return (this.AppConfig.custom_attributes.gift_message_from && this.AppConfig.custom_attributes.gift_message_to && this.AppConfig.custom_attributes.gift_message_content) && (!!this.GiftMessageFrom && !!this.GiftMessageTo && !!this.GiftMessageContent)
    },
    GiftMessageFrom () {
      return this.CustomAttributes.find(attr => attr.name === 'gift_message_from')
    },
    GiftMessageTo () {
      return this.CustomAttributes.find(attr => attr.name === 'gift_message_to')
    },
    GiftMessageContent () {
      return this.CustomAttributes.find(attr => attr.name === 'gift_message_content')
    }
  },
  watch: {
    gift_message: {
      deep: true,
      immediate: true,
      handler: function (val, oldVal) {
        this.$emit('input', val)
      }
    }
  },
  props: ['edit', 'showProducts', 'fields', 'data'],
  methods: {
    ...mapActions(['SetCustomAttribute', 'SendCustomAttributes']),
    setActiveInput (input) {
      this.activeInput = input
    },
    goTo (uri) {
      RouterService.goTo(uri)
    }
  }
}
</script>
