import evHubPaymentValidator from './evHubPaymentValidator'
import { PaymentServices } from './paymentServices'
import { IOPayApi } from '../../api/ioPayApi'
import { IOPayAntiFraud } from './AntiFraudServices/IOPayAntiFraud'
import { dateHelper } from '../../helpers/dateHelper'

export class IOPayServices extends PaymentServices {
  setPubKey (pubKey) {
    return super.setPubKey(pubKey)
  }

  async initiateFraudAnalysisToken (antiFraudToken) {
    const router = require('../../router/index').default

    await this.#waitUntilCheckoutIdFulfilled(router)

    var ioPayAntiFraud = this.#createIOPayAntiFraud(antiFraudToken, router)

    ioPayAntiFraud.executeAntiFraud()
  }

  #createIOPayAntiFraud(antiFraudToken, router) {
    var antiFraudTokenSplitted = antiFraudToken.split(':')
    var securityPlan = antiFraudTokenSplitted[0]
    var publicKey = antiFraudTokenSplitted[1]

    var cyberSource = new IOPayAntiFraud(securityPlan, publicKey, router.currentRoute.params.checkout_id)
    return cyberSource
  }

  // eslint-disable-next-line no-dupe-class-members
  async #waitUntilCheckoutIdFulfilled(router) {
    var count = 0
    // eslint-disable-next-line no-unmodified-loop-condition
    while (!router.currentRoute.params.checkout_id && count < 3) {
      await dateHelper.wait(1000)
      count++
    }
  }

  async createPaymentHash (number, cvv, expirationMonth, expirationYear) {
    var api = new IOPayApi()
    var pubKeySplitted = this.pubKey.split(':')

    var secretId = pubKeySplitted[0]
    var sellerId = pubKeySplitted[1]
    var companyEmail = pubKeySplitted[2]

    var authResponse = await api.authenticate(companyEmail, secretId, sellerId)

    var hashResponse = await api.tokenizeCard(authResponse.data.access_token, {
      holder_name: this.userName,
      expiration_month: Number(expirationMonth),
      expiration_year: Number(expirationYear),
      card_number: number.replaceAll(' ', '').replaceAll('.', ''),
      security_code: cvv
    })

    return hashResponse.data.id
  }

  validateNumber (cardNumber) {
    return evHubPaymentValidator.validateCardNumber(cardNumber)
  }

  getCardBrand (cardNumber) {
    return evHubPaymentValidator.getCardBrand(cardNumber)
  }

  validateSecurityCode (ccnumber, cvv) {
    return evHubPaymentValidator.validateSecurityCode(cvv)
  }
}
