/* eslint-disable no-dupe-class-members */
import axios from 'axios'
import { stringHelper } from '../helpers/stringHelper'

export class PagarMeApi {
  #url = 'https://api.pagar.me/core/v5/';
  #publicKey = '';

  constructor (publicKey) {
    this.#publicKey = publicKey
  }

  set url (val) {
    this.#url = val
  }

  set publicKey (val) {
    this.#publicKey = val
  }

  async createCardToken (card) {
    if (!this.#publicKey) {
      throw new Error('Public Key is required')
    }

    var cardIsValid = this.#validateCard(card)
    if (!cardIsValid.isValid) {
      throw new Error(cardIsValid.message)
    }

    if (!this.#url ||
        !this.#url.includes('api.') ||
        !this.#url.includes('/v')) {
      throw new Error('Url is invalid')
    }

    var createTokenUrl = this.#url + 'tokens?appId=' + this.#publicKey

    try {
      var response = await axios.post(createTokenUrl, {
        type: 'card',
        card: card
      })

      if (response.data.errors) {
        var errorMessage = this.#getErrorMessage(response.data.errors)
        throw new Error(errorMessage)
      }

      return response.data.id
    } catch (error) {
      throw new Error(error.message)
    }
  }

  #validateCard (card) {
    if (card.number.length < 15 ||
        card.number.length > 17 ||
        stringHelper.hasWhiteSpace(card.number) ||
        stringHelper.hasLetters(card.number) ||
        stringHelper.hasSpecialCharacters(card.number)) {
      return {
        isValid: false,
        message: 'Invalid card number'
      }
    }

    if (!this.#validateExpirationDate(card.exp_month, card.exp_year)) {
      return {
        isValid: false,
        message: 'Invalid expiration date'
      }
    }

    if (card.cvv.length < 3 ||
        card.cvv.length > 4) {
      return {
        isValid: false,
        message: 'Invalid cvv'
      }
    }

    return {
      isValid: true
    }
  }

  #validateExpirationDate (month, year) {
    var currentYear = (new Date()).getFullYear().toString().substring(-2)

    if (year.length === 4) {
      year = year.substring(-2)
    }
    if (currentYear < year) {
      return true
    }

    if (currentYear === year) {
      return (new Date()).getMonth() <= month
    }

    return false
  }

  #getErrorMessage (errors) {
    var message = ''

    if (errors['request.card']) {
      errors['request.card'].forEach(error => {
        message = error + '\n'
      })
    }
    return message
  }
}
