<template>
  <ul class="ShippingMethod__Resume__ProductList ProductList">
    <li
      v-for="(product, index) in products"
      :key="(index)"
      class="ProductList__Item"
      :data-product-id="product.product_id"
      :data-variant-id="product.variant_id"
      :data-product-title="product.title"
      data-customer-ready-visible
    >
      <div class="ProductList__Item__image">
        <div class="ProductList__Item-thumbnail">
          <div class="ProductList__Item-thumbnail__wrapper">
            <img
              :alt="product.title"
              class="ProductList__Item-thumbnail__image"
              :src="product.image"
            />
          </div>
          <span
            class="ProductList__Item-thumbnail__quantity"
            aria-hidden="true"
          >{{product.quantity}}</span>
        </div>
      </div>
      <div class="ProductList__Item__description">
            <span class="ProductList__Item__description__variant ProductList__Item__description--small-text">
                {{product.title}} - {{method.title}}
            </span>
            <span class="ProductList__Item__description__name ProductList__Item__description--emphasis-text" v-if="method && method.estimate_business_days">
                {{ GetInventoryMessage(product.variant_id, method) }}
            </span>
            <!-- <b class="ProductList__Item__description__shipping-method ProductList__Item__description--small-text">{{product.re_stock_date}}</b> -->
        <div>
        </div>
      </div>
      <!-- <div class="ProductList__Item__price">
        <span class="ProductList__Item__description--emphasis-text">{{formatMoney(product.price)}}</span>
      </div> -->
    </li>
  </ul>
</template>

<script>
// @ is an alias to /src
import { mapFilters } from '@/helpers/filters'
import { mapGetters } from 'vuex'

import productListMixin from '@/_mixins/productList'
export default {
  name: 'ProductList',
  components: {
  },
  props: ['products', 'method'],
  mixins: [productListMixin],
  beforeMount () {
    console.log('method:', this.method)
  },
  computed: {
    ...mapGetters(['InventoryIncomings', 'CheckoutConfig', 'Checkout', 'ShippingMethods'])
  },
  methods: {
    ...mapFilters(['formatMoney', 'dateFormat'])
  }
}
</script>
