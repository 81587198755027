import config from '../../_config'

const state = {
  AppConfig: config,
  CheckoutStatus: {
    Auth: {
      authorize: 'IDLE'
    },
    Checkout: {
      get: 'IDLE',
      create: 'IDLE',
      draft_order: 'IDLE',
      recover: 'IDLE'
    },
    DiscountCode: {
      add: 'IDLE',
      delete: 'IDLE'
    },
    GiftCard: {
      add: 'IDLE',
      delete: 'IDLE'
    },
    ContactInfos: {
      get: 'LOADING',
      update: 'IDLE'
    },
    ShippingMethods: {
      get: 'LOADING',
      update: 'IDLE'
    },
    Products: {
      add: 'IDLE',
      loading: false
    },
    PaymentOptions: {
      get: 'IDLE',
      update: 'IDLE',
      delete: 'IDLE'
    },
    Order: {
      get: 'IDLE',
      close: 'IDLE',
      update: 'IDLE'
    },
    Promotions: {
      get: 'IDLE',
      close: 'IDLE',
      update: 'IDLE'
    }
  }
}
const getters = {
  AppConfig: function (state) {
    return state.AppConfig
  },
  CheckoutStatus: function (state) {
    return state.CheckoutStatus
  }
}
const actions = {
  _buildErrorAlert ({ commit }, ErrorOBJ) {
    let $this = this

    $this._vm.$message({
      duration: 7500,
      dangerouslyUseHTMLString: true,
      message: `<b>${ErrorOBJ.title}:</b> <br/> ${ErrorOBJ.error}`,
      type: 'error'
    })
  },
  _buildSuccessAlert ({ commit }, SuccessOBJ) {
    let $this = this

    $this._vm.$message({
      dangerouslyUseHTMLString: true,
      message: `<b>${SuccessOBJ.title}:</b> <br/> ${SuccessOBJ.success}`,
      type: 'success'
    })
  },
  _buildInfoAlert ({ commit }, InfoOBJ) {
    let $this = this

    $this._vm.$message({
      dangerouslyUseHTMLString: true,
      message: `<b>${InfoOBJ.title}:</b> <br/> ${InfoOBJ.info}`,
      type: 'info'
    })
  },
  goToShop ({ rootState, state }, error) {
    // eslint-disable-next-line no-debugger
    // debugger
    window.location.href = `${rootState.CheckoutConfig.CheckoutConfig.shopify_api.domain}`
  },
  goToShopSupport ({ state }, error) {
    window.location.href = `mailto:${state.AppConfig.support_email}?subject=[${state.AppConfig.name}] Problemas para fechar o pedido&body=Olá, Estou com problemas para fechar meu pedido. Segue alguns detalhes do erro:  %0A%0A - Link: ${window.location.href}  %0A%0A - Código do erro: ${error}  %0A%0A`
  },
  setCheckoutStatus ({ commit }, CheckoutStateOBJ) {
    commit('setCheckoutStatus', CheckoutStateOBJ)
  }
}
const mutations = {
  setCheckoutStatus: function (state, CheckoutStateOBJ) {
    state.CheckoutStatus = {
      ...state.CheckoutStatus,
      ...CheckoutStateOBJ
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
