class DateHelper {
  wait(time) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }
}

var dateHelper = new DateHelper()

export { dateHelper }
