<template>
  <div class='Order'>
    <div class="Order__Content" v-if="!CheckoutID">
      <div class="Loading" >
        <div class="Loading__Content">
          <img alt='Vue logo' :src="AppConfig.assets.images.splash" />
          <div>
            <h3>Ops, parece que algo deu errado</h3>
            <p>O pedido solicitado não foi encontrado</p>
            <el-button @click="goToShopSupport('ORDER_NOT_FOUND')" type="primary">Entre em contato com nosso suporte</el-button> <br> ou <br> <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
            <small><em></em></small>
          </div>
        </div>
      </div>
    </div>
    <div class="Order__Content"  v-if="CheckoutStatus.Checkout.create === 'LOADING'">
      <div class="Loading">
        <div class="Loading__Content">
          <img alt='Vue logo' :src="AppConfig.assets.images.splash" />
          <div>
            <h3>Aguarde, estamos processando seu pedido </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="Order__Content" v-if="CheckoutStatus.Checkout.get === 'ERROR'">
      <div class="Loading" >
        <div class="Loading__Content" v-if="!Checkout.order.id">
          <img alt='Vue logo' :src="AppConfig.assets.images.splash" />
          <div>
            <h3>Ops, parece que algo deu errado</h3>
            <p>Ocorreu um erro ao recuperar suas informações de pedido</p>
            <el-button @click="goToShopSupport('ORDER_INFOS_NOT_FOUND')" type="primary">Entre em contato com nosso suporte</el-button> <br> ou <br> <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
            <small><em></em></small>
          </div>
        </div>
      </div>
    </div>

    <CheckoutHeader :checkout_id="Checkout.id" class="Checkout__Header--Mobile"  v-if="Checkout && Checkout.order"/>
    <div class="Checkout__Content"  v-if="Checkout">
      <CheckoutHeader :checkout_id="Checkout.id"/>
      <router-view :order="Checkout"/>
      <CheckoutFooter/>
    </div>
    <Sidebar class="Checkout__Sidebar"  v-if="Checkout"/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import RouterService from '@/services/routerService'
import { dateHelper } from '../../helpers'

import Sidebar from '@/components/Sidebar.vue'
import CheckoutHeader from '@/components/_structure/CheckoutHeader.vue'
import CheckoutFooter from '@/components/_structure/CheckoutFooter.vue'

// import Analytics from '@/services/analytics'

export default {
  name: 'Order',
  components: {
    Sidebar,
    CheckoutHeader,
    CheckoutFooter
  },
  data () {
    return {
    }
  },
  props: ['checkout_id'],
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutStatus', 'Order', 'CheckoutConfig']),
    CheckoutID () {
      let CheckoutID = this.checkout_id
      if (!CheckoutID && this.Checkout && this.Checkout.id) {
        return this.Checkout.id
      }
      return CheckoutID
    }
  },
  created () {
    this.OrderArrival()
  },
  async mounted () {
    await this.AddThankYouPageScripts()
  },
  methods: {
    ...mapFilters(['formatMoney', 'formatDocument']),
    ...mapActions(['GetOrder', 'GetCheckout', 'GetCheckoutV2', 'setConversion', 'ClearDiscounts', 'goToShop', 'goToShopSupport']),
    OrderArrival () {
      if (this.checkout_id) {
        this.GetCheckoutV2(this.checkout_id, '2.0').then(() => {
          this.orderSetup(this.Checkout.order.status)
        })
      }
    },
    async AddThankYouPageScripts() {
      if (!this.CheckoutConfig) {
        await dateHelper.wait(1000)
      }

      if (!this.CheckoutConfig.custom_scripts ||
          this.CheckoutConfig.custom_scripts.length === 0) {
        return
      }
      let thankYouPageScripts = this.CheckoutConfig
        .custom_scripts
        .filter(script =>
          script.target === 'ThankYouPage')

      if (!thankYouPageScripts || thankYouPageScripts.length === 0) {
        return
      }

      for (const thankYouPageScript of thankYouPageScripts) {
        var script = document.createElement('script')

        script.id = 'custom-script-' + thankYouPageScript.name
        script.setAttribute('async', true)
        script.src = thankYouPageScript.url

        document.body.appendChild(script)
      }
    },
    xpto () {
      RouterService.goTo({ name: 'OrderPending', params: { checkout_id: this.Checkout.id } })
    },
    orderSetup (orderStatus) {
      switch (orderStatus) {
        case 'pending':
          RouterService.goTo({ name: 'OrderPending', params: { checkout_id: this.Checkout.id } })
          break
        case 'paid':
          RouterService.goTo({ name: 'OrderSuccess', params: { checkout_id: this.Checkout.id } })
          break
        case 'cancelled':
          RouterService.goTo({ name: 'OrderCancelled', params: { checkout_id: this.Checkout.id } })
          break
        default:
          RouterService.goTo({ name: 'OrderPending', params: { checkout_id: this.Checkout.id } })
          break
      }
    }
  },

  watch: {
    'Checkout.order.status' (val) {
      if (val) {
        this.orderSetup(val)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.order.closed) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
