/* eslint-disable no-undef */

class Helpers {
  _loadJS = async function (url, implementationCode, scriptLocation = 'body') {
    var scriptTag = document.createElement('script')
    scriptTag.src = url

    scriptTag.onload = implementationCode
    // scriptTag.onreadystatechange = implementationCode
    scriptTag.onreadystatechange = () => {
      return true
      // return implementationCode()
    }
    if (scriptLocation === 'head') {
      document.head.appendChild(scriptTag)
    } else {
      document.body.appendChild(scriptTag)
    }
  }
  _timing = {
    timers: {},
    get: (id) => {
      return this.timers[id] ? this.timers[id] : null
    },
    start: (id) => {
      this.timers[id] = {}
      this.timers[id].open = performance.now()
    },
    end: (id) => {
      if (this.timers[id]) {
        this.timers[id] = {}
        this.timers[id].end = performance.now()
        return true
      } else {
        return new Error(`There's no open timer for id ${id}`)
      }
    }
  }
}
// Helpers._timing.start()
// Helpers._timing.end()
// Helpers._timing.get().end - Helpers._timing.get().start
export default new Helpers()
