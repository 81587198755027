<template>
    <div class="Checkout__Header">
        <div class="Checkout__Header__Logo">
          <a :href="storeURL">
            <img :alt="AppConfig.name" :src="AppConfig.assets.images.logo" />
          </a>
        </div>

        <div id='nav' class="Checkout__Header__Nav" v-if="Checkout && !Checkout.closed">

            <div class="item">
                <router-link class="Checkout__Header__Nav__Item" :to="'/Checkout/'+checkout_id+'/contact_information'" v-if="activeStep.stepNumber >= GetStep('contact_information').stepNumber">Dados pessoais</router-link>
                <span class="Checkout__Header__Nav__Item" v-else>Dados pessoais</span>
            </div>

            <div class="item" v-if="Checkout.requires_shipping">
                <i class="el-icon-arrow-right"></i>
                <router-link class="Checkout__Header__Nav__Item" :to="'/Checkout/'+checkout_id+'/shipping_methods'" v-if="activeStep.stepNumber >= GetStep('shipping_methods').stepNumber">Envio</router-link>
                <span class="Checkout__Header__Nav__Item" v-else>Envio</span>
            </div>

            <i class="el-icon-arrow-right"></i>

            <div class="item">
                <router-link class="Checkout__Header__Nav__Item" :to="'/Checkout/'+checkout_id+'/payment_methods'" v-if="activeStep.stepNumber >= GetStep('payment_methods').stepNumber">Pagamento</router-link>
                <span class="Checkout__Header__Nav__Item" v-else>Pagamento</span>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import RouterService from '@/services/routerService'

export default {
  name: 'CheckoutHeader',
  components: {

  },
  data () {
    return {
    }
  },

  props: ['checkout_id'],
  computed: {
    ...mapGetters(['AppConfig', 'CheckoutStatus', 'Checkout', 'currentStep', 'CheckoutConfig']),
    activeStep () {
      return RouterService.getActiveStep()
    },
    storeURL () {
      return `${this.CheckoutConfig.shopify_api.domain}`
    }
  },
  created () {
  },
  methods: {
    ...mapActions(['UpdateCheckout']),
    UpdateStep (step) {
      this.currentStep = this.Checkout.activeStep = step
      this.UpdateCheckout(this.Checkout)
    },
    GetStep (query) {
      return RouterService.getStepObj(query, 'url')
    }
  }
}
</script>
