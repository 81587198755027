import axios from 'axios'

export class SafraPayApi {
  #safraUrl = 'https://payment.safrapay.com.br/v2'
  #api = null
  constructor() {
    this.#api = axios.create({
      baseURL: this.#safraUrl,
      responseType: 'json'
    })
  }

  async authenticate(authToken, merchantCredential) {
    return this.#api.post('/merchant/auth', {}, {
      headers: {
        Authorization: authToken,
        merchantCredential: merchantCredential
      }
    })
  }

  async createTemporaryCard(authToken, body) {
    return this.#api.post('/temporary/card', JSON.stringify(body), {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
  }
}
