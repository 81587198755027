// import API from '@/api'

const state = {
  Notifications: []
  // Sidebar: {}
}
const getters = {
  Notifications: function (state) {
    return state.Notifications
  }
  // Sidebar: function (state) {
  //   return state.Sidebar
  // }
}
const actions = {
  SetNotification ({ commit }, notification) {
    commit('SetNotification', notification)
  },
  RemoveNotification ({ commit }, notification) {
    commit('RemoveNotification', notification)
  }
  // async SetCheckoutTotals ({ commit }, Totals) {
  //   commit('UpdateCheckoutTotals', Totals)
  // }
}
const mutations = {
  RemoveNotification: function (state, notification) {
    state.Notifications = state.Notifications.filter(n => n.message !== notification.message && n.type !== notification.type)
  },
  SetNotification: function (state, notification) {
    state.Notifications.push(notification)
  }
  // UpdateCheckoutTotals: function (state, Totals) {
  //   state.Sidebar.CheckoutTotals = {
  //     ...state.Sidebar.CheckoutTotals,
  //     ...Totals
  //   }
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
