<template>
  <div id='app'>
    <router-view />
    <div id="bankSlipDownload"></div>
  </div>
</template>

<style lang='scss'></style>

<script>
import { mapGetters, mapActions } from 'vuex'
import RouterService from '@/services/routerService'
import Analytics from '@/services/analytics'
import Tracking from '@/services/tracking'
import paymentFactory from './services/Factories/paymentFactory'

export default {
  name: 'Checkout',
  components: {},
  data () {
    return {
      CartToken: null
    }
  },
  created () {
    Tracking._setup()
    Analytics._init()
    if (this.$route.query.discount) {
      localStorage.setItem('discount_code', this.$route.query.discount)
      delete this.$route.query.discount
    }
    if (this.$route.query.show_discount_form) {
      sessionStorage.setItem('show_discount_form', true)
      delete this.$route.query.show_discount_form
    }
    if (this.$route.query.login) {
      localStorage.setItem('login', this.$route.query.login)
      delete this.$route.query.login
    }
    if (this.$route.query.zipcode) {
      localStorage.setItem('zipcode', this.$route.query.zipcode)
      delete this.$route.query.zipcode
    }
  },
  mounted () {
    var scriptTag = document.createElement('script')
    scriptTag.src = 'https://sdk.mercadopago.com/js/v2'
    document.body.appendChild(scriptTag)

    var scriptTagPayPal = document.createElement('script')
    scriptTagPayPal.src = 'https://www.paypalobjects.com/webstatic/ppplusdcc/ppplusdcc.min.js'
    document.body.appendChild(scriptTagPayPal)
    if (this.AppConfig.checkout_version !== 'subfolder') {
      document.head.innerHTML = document.head.innerHTML + `
        <title>${this.AppConfig.name} - Checkout</title>
        <link rel="stylesheet" href="https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/css/custom_checkout.css">
        <style>
          @font-face {
            font-family: "EvolveFontPrimary";
            font-weight: 300;
            font-style: normal;
            src: url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/light.woff") format("woff2"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/light.woff") format("woff"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/light.ttf") format("ttf");
          }

          @font-face {
            font-family: "EvolveFontPrimary";
            font-weight: 400;
            font-style: normal;
            src: url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/regular.woff") format("woff2"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/regular.woff") format("woff"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/regular.ttf") format("ttf");
          }

          @font-face {
            font-family: "EvolveFontPrimary";
            font-weight: 600;
            font-style: normal;
            src: url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/bold.woff") format("woff2"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/bold.woff") format("woff"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/primary/bold.ttf") format("ttf");
          }

          @font-face {
            font-family: "EvolveFontSecondary";
            font-weight: 300;
            font-style: normal;
            src: url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/light.woff") format("woff2"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/light.woff") format("woff"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/light.ttf") format("ttf");
          }

          @font-face {
            font-family: "EvolveFontSecondary";
            font-weight: 400;
            font-style: normal;
            src: url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/regular.woff") format("woff2"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/regular.woff") format("woff"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/regular.ttf") format("ttf");
          }

          @font-face {
            font-family: "EvolveFontSecondary";
            font-weight: 600;
            font-style: normal;
            src: url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/bold.woff") format("woff2"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/bold.woff") format("woff"),
              url("https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${this.AppConfig.handle}/fonts/secondary/bold.ttf") format("ttf");
          }
        </style>
      `
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutStatus', 'CheckoutConfig', 'currentStep'])
  },
  methods: {
    ...mapActions(['CreateCheckout', 'setAnalyticsTools', 'goToStep', 'ApplyDiscountCode', 'SetCheckoutStep']),
    goTo (url) {
      RouterService.goTo(url)
    },
    initiateFraudAnalysisToken () {
      var paymentGatewayProvider = this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider
      var paymentGatewayFallbackProvider = this.CheckoutConfig.paymentInformationConfig.credit_card_config.fallbackProvider

      var paymentGatewayName = paymentGatewayProvider.name
      var paymentFallbackGatewayName = paymentGatewayFallbackProvider.name

      let $paymentServices = paymentFactory.getPaymentServices(paymentGatewayName, paymentFallbackGatewayName)
      if (paymentGatewayProvider && paymentGatewayProvider.pagShieldToken) {
        $paymentServices.default.initiateFraudAnalysisToken(paymentGatewayProvider.pagShieldToken)
      } else if (paymentGatewayFallbackProvider && paymentGatewayFallbackProvider.pagShieldToken) {
        $paymentServices.fallback.initiateFraudAnalysisToken(paymentGatewayFallbackProvider.pagShieldToken)
      }
    }
  },

  watch: {
    'Checkout' (val) {
      if (val.customer && val.customer.email) {
        Tracking.alias(val.customer.email, val.id)
      }
      if (!val && this.$route.params.checkout_id) {
        let CheckoutID = this.$route.params.checkout_id
        this.GetCheckout(CheckoutID)
      }
    },
    'Checkout.activeStep' (val) {
      this.SetCheckoutStep(val)
    },
    'CheckoutStatus.Order' (val) {
      if (val.close === 'LOADING') {
        RouterService.goTo({ name: 'OrderProcessing' })
      }
      if (!val && this.$route.params.checkout_id) {
        let CheckoutID = this.$route.params.checkout_id
        this.GetCheckout(CheckoutID)
      }
    },
    'CheckoutConfig' (val) {
      if (val) {
        this.initiateFraudAnalysisToken()
      }
    },
    'currentStep' (val) {
      if (val) {
        RouterService.goToStep(val)
      }
    },
    deep: true
  }
}
</script>
