import Filters from '@/services/filters'
import Vue from 'vue'
import InputFacade from 'vue-input-facade'

import Vuelidate from 'vuelidate'
import App from './App.vue'
import './plugins/CustomPlugins/customPlugins.js'
import './plugins/element.js'
import './plugins/html2CanvasPlugin.js'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './_scss/App.scss'

import validateService from './services/validateService'

Vue.config.productionTip = false

Vue.filter('objectIsEmpty', function (date) {
  return Filters.objectIsEmpty(date)
})

Vue.filter('dateFormat', function (date) {
  return Filters.dateFormat(date)
})

Vue.filter('formatMoney', function (amount, decimalCount = 2, decimal = ',', thousands = '.') {
  try {
    return `R$ ${amount.toFixed(2).replace('.', ',')}`
  } catch (e) {
    console.log(e)
  }
})

Vue.filter('onlyTextField', function (text) {
  return !/\d/.test(text)
})

Vue.filter('formatDocument', function (UserDocument) {
  if (UserDocument.length === 11) {
    UserDocument = UserDocument.padStart(11, '0')
    UserDocument = UserDocument.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else {
    UserDocument = UserDocument.padStart(14, '0')
    UserDocument = UserDocument.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  return UserDocument
})

Vue.filter('checkEmailFormat', async function (email) {
  try {
    await validateService.isValidEmail(email)
    return true
  } catch (error) {
    throw new Error(error.message)
  }
  // return FormatIsValid
})

Vue.filter('checkPhoneNumber', function (Phone) {
  return validateService.isValidPhone(Phone)
})

Vue.filter('formatDiscountValue', function (discount) {
  if (discount.discountType === 'Percentage') {
    return `${discount.toFixed(2)}%`
  } else {
    return `R$ ${discount.toFixed(2)}`
  }
})

Vue.filter('checkUserDocument', function (UserDocument) {
  if (UserDocument) {
    UserDocument = UserDocument.replace(/[^\d]+/g, '')
    if (UserDocument.length === 11) {
      if (UserDocument.length !== 11 ||
        UserDocument === '00000000000' ||
        UserDocument === '11111111111' ||
        UserDocument === '22222222222' ||
        UserDocument === '33333333333' ||
        UserDocument === '44444444444' ||
        UserDocument === '55555555555' ||
        UserDocument === '66666666666' ||
        UserDocument === '77777777777' ||
        UserDocument === '88888888888' ||
        UserDocument === '99999999999') { return false }
      // Valida 1o digito
      let add = 0
      for (let i = 0; i < 9; i++) { add += parseInt(UserDocument.charAt(i)) * (10 - i) }
      let rev = 11 - (add % 11)
      if (rev === 10 || rev === 11) { rev = 0 }
      if (rev !== parseInt(UserDocument.charAt(9))) { return false }
      // Valida 2o digito
      add = 0
      for (let i = 0; i < 10; i++) { add += parseInt(UserDocument.charAt(i)) * (11 - i) }
      rev = 11 - (add % 11)
      if (rev === 10 || rev === 11) { rev = 0 }
      if (rev !== parseInt(UserDocument.charAt(10))) { return false }
      return true
    }
    if (UserDocument.length === 14) {
      UserDocument = UserDocument.replace(/[^\d]+/g, '')

      if (UserDocument === '') {
        return false
      }

      if (UserDocument.length !== 14) {
        return false
      }

      // Elimina UserDocuments invalidos conhecidos
      if (UserDocument === '00000000000000' ||
        UserDocument === '11111111111111' ||
        UserDocument === '22222222222222' ||
        UserDocument === '33333333333333' ||
        UserDocument === '44444444444444' ||
        UserDocument === '55555555555555' ||
        UserDocument === '66666666666666' ||
        UserDocument === '77777777777777' ||
        UserDocument === '88888888888888' ||
        UserDocument === '99999999999999') { return false }

      // Valida DVs
      let tamanho = UserDocument.length - 2
      let numeros = UserDocument.substring(0, tamanho)
      let digitos = UserDocument.substring(tamanho)
      let soma = 0
      let pos = tamanho - 7
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) { pos = 9 }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado !== parseInt(digitos.charAt(0))) { return false }

      tamanho = tamanho + 1
      numeros = UserDocument.substring(0, tamanho)
      soma = 0
      pos = tamanho - 7
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) { pos = 9 }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
      if (resultado !== parseInt(digitos.charAt(1))) {
        return false
      }

      return true
    }
  }
  return false
})
Vue.filter('checkPartialAmount', function (PartialAmountValue, Checkout) {
  if (!PartialAmountValue) {
    return false
  }
  if (!Checkout) {
    return false
  }

  let $PartialAmountValue = +PartialAmountValue
  let $CheckoutTotal = Checkout?.totals?.total

  if ($PartialAmountValue > 0 && $PartialAmountValue < $CheckoutTotal) {
    return true
  }

  return false
})
Vue.filter('checkBirthDate', function (BirthDate) {
  if (!BirthDate) {
    return true
  }
  if (BirthDate.length !== 10) {
    return false
  }
  if (BirthDate) {
    let BirthDateSplitted = BirthDate.split('/')
    if (BirthDateSplitted.length < 3) {
      return false
    }
    let BirthDateDay = BirthDateSplitted[0]
    let BirthDateMonth = BirthDateSplitted[1]
    let BirthDateYear = BirthDateSplitted[2]
    let BirthDateFormatted = `${BirthDateYear}-${BirthDateMonth}-${BirthDateDay}`
    let isBirthDateValid = Filters.dateIsValid(BirthDateFormatted)
    let isBirthDatePast = Filters.dateIsPast(BirthDateFormatted)
    let isBirthDateToday = Filters.dateIsToday(BirthDateFormatted)
    let isBirthDateYearsAgo = Filters.dateYearsAgo(BirthDateFormatted)
    if (isBirthDateValid && isBirthDatePast && !isBirthDateToday && isBirthDateYearsAgo < 120) {
      return true
    }
  }
  return false
})

Vue.filter('checkStateSubscription', function (StateSubscriptionObj) {
  var ie = require('inscricaoestadual')
  return ie(StateSubscriptionObj.StateSubscription, StateSubscriptionObj.State)
})
// migrating from v-mask
const facadeOptions = {
  tokens: {
    'F': { pattern: /[^,;]/i },
    '#': { pattern: /\d/ },
    X: { pattern: /[0-9a-z]/i },
    S: { pattern: /[a-z]/i },
    A: { pattern: /[a-z]/i, transform: (v) => v.toLocaleUpperCase() },
    a: { pattern: /[a-z]/i, transform: (v) => v.toLocaleLowerCase() },
    '\\': { escape: true }
  }
}

Vue.use(InputFacade, facadeOptions)
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
