import { mapGetters } from 'vuex'

const form = {
  data () {
    return {
    //   activeInput:xins null
    }
  },
  computed: {
    ...mapGetters(['AppConfig'])
  },
  methods: {
    GetInventoryMessage (variantID, shippingMethodOBJ) {
      let shippingInfos = shippingMethodOBJ.items.filter((item) => {
        return parseInt(item.variantId) === variantID
      })[0]

      if (!shippingInfos) {
        return ``
      }

      var minEstimatedDays = parseInt(shippingInfos.minRange)
      var maxEstimatedDays = parseInt(shippingInfos.maxRange)
      var sinceDate = shippingInfos.sinceDate

      minEstimatedDays = minEstimatedDays > 0 ? minEstimatedDays : 0
      maxEstimatedDays = maxEstimatedDays > 0 ? maxEstimatedDays : 0

      return `
        ${minEstimatedDays !== maxEstimatedDays
    ? `${minEstimatedDays > 0 ? `De ${minEstimatedDays} a` : 'Até'} ${maxEstimatedDays === 1 ? `${maxEstimatedDays} dia útil` : `${maxEstimatedDays} dias úteis`} `
    : `Até ${maxEstimatedDays === 1 ? `${maxEstimatedDays} dia útil` : `${maxEstimatedDays} dias úteis`}`} 
    a partir ${sinceDate ? `de ${this.dateFormat(sinceDate)}` : `${this.AppConfig.translations.product_list.estimated_shipping_criteria}`}`
    }
  }
}

export default form
