import API from '@/api'

const state = {
  _OrderStatus: 'POLLING',
  Order: null

}
const getters = {
  _OrderStatus: function (state) {
    return state._OrderStatus
  },
  Order: function (state) {
    return state.Order
  }
}
const actions = {
  async GetOrder ({ commit, dispatch }, checkoutId) {
    await API.get('/Orders/' + checkoutId)
      .then(resp => {
        commit('SetOrderState', resp.data, 'SUCCESS')
        // dispatch('UpdateCheckoutState', {
        //   order: resp.data
        // })
      })
      .catch((err) => {
        dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'GetOrder', error: `${err.message}`, fatal: true })
        commit('SetOrderState', null, 'ERROR')
        return new Error(`GetOrder: ${err.message}`)
      })
  },
  UpdateOrder ({ commit, dispatch }, Order) {
    API.put('/Orders/' + Order.id, Order)
      .then(Order => {
        commit('SetOrderState', Order.data, 'SUCCESS')
      })
      .catch((err) => {
        dispatch('setAnalyticsError', { id: `${Order.id}`, method: 'UpdateOrder', error: `${err.message}`, fatal: true })
        return new Error(`UpdateOrder: ${err.message}`)
      })
  },
  async SetOrderState ({ commit, dispatch }, value) {
    commit('SetOrderState', value)
  },
  async UpdateOrderState ({ commit }, value) {
    commit('UpdateOrderState', value)
  }

}
const mutations = {
  SetOrderState: function (state, Order, Status) {
    state.Order = (Order)
    state._OrderStatus = (Status)
  },
  SetOrderStep: function (state, step) {
    state.currentStep = step
  },
  UpdateOrderState: function (state, Data) {
    state.Order = {
      ...state.Order,
      ...Data
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
