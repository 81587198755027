<template>
  <div class="Order__Step">
    <div class="Order__Section Order__Confirmation-text">
      <div class="Order__Confirmation-text--header">
        <div class="Order__Confirmation-text--header--icon">
          <i class="el-icon-circle-check"></i>
        </div>
        <div class="Order__Confirmation-text--header--content">
          <small>
            <span>Compra: #{{order.order.number}}</span>
          </small>
          <h1
            v-if="AppConfig.thankyou_page.order_pending.title"
            class="Checkout__Section__Title Order__Section__Title Section__Title"
          >
            {{ AppConfig.thankyou_page.order_pending.title }}
          </h1>
          <h1
            v-else
            class="Checkout__Section__Title Order__Section__Title Section__Title"
          >
            Recebemos seu pedido, {{order.customer.first_name}}
          </h1>
        </div>
      </div>
      <div class="Order__Confirmation-text--content">
        <h4 v-if="AppConfig.thankyou_page.order_pending.subtitle">
          <b>{{AppConfig.thankyou_page.order_pending.subtitle}}</b>
        </h4>
        <el-row :gutter="20" v-if="AppConfig.thankyou_page.order_pending.product_scheduling_enabled">
          <el-col :span="24">
            <product-scheduling
              :products="order.products"
              :method="ActiveShippingMethod"></product-scheduling>
          </el-col>
        </el-row>
        <el-row class="Order__Confirmation__PostPurchase--Coupon PostPurchase--Coupon" :gutter="20"  v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.enabled">
          <el-col :span="24">
            <PostPurchaseCoupon />
            <br>
          </el-col>
        </el-row>
        <el-row class="Order__Confirmation__Payment-Widget--bankslip Payment-Widget Payment-Widget--bankslip" :gutter="20" v-if="order.payment_info.type === 'BOLETO' && AppConfig.thankyou_page.show_bankslip_as && AppConfig.thankyou_page.show_bankslip_as === 'iframe'">
          <el-col :span="24">
            <div v-if="AppConfig.thankyou_page.bankslip_iframe_description">
              <h3>{{ AppConfig.thankyou_page.bankslip_iframe_description.title }}</h3>
              <p>{{ AppConfig.thankyou_page.bankslip_iframe_description.subtitle }}</p>
            </div>
            <br>
            <span class="Iframe__Container">
              <iframe :src="order.payment_info.bankslip_url" frameborder="0"></iframe>
            </span>
            <br>
          </el-col>
        </el-row>
        <el-row class="Order__Confirmation__Payment-Widget--bankslip Payment-Widget Payment-Widget--bankslip" :gutter="20" v-if="order.payment_info.type === 'BOLETO' && AppConfig.thankyou_page.show_bankslip_as && AppConfig.thankyou_page.show_bankslip_as === 'widget'">
          <el-col :span="24">
            <BankslipWidget :line_code="order.payment_info.bankslip_line_code" />
            <br>
          </el-col>
        </el-row>
        <el-row class="Order__Confirmation__Payment-Widget--pix Payment-Widget Payment-Widget--pix" :gutter="20" v-if="order.payment_info.type === 'PIX'">
          <el-col :span="24">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>Escaneie este código para pagar</span>
              </div>
              <el-row>
                <el-col :xs="24" :md="8">
                  <div class="Payment-Widget--pix__QRCode">
                    <img v-if="order.payment_info.qr_code_base64" style="max-width: 100%" :src="`data:image/jpeg;base64,${order.payment_info.qr_code_base64}`">
                    <img v-else :src="order.payment_info.pix_url" style="max-width: 100%">
                  </div>
                </el-col>
                <el-col :xs="24" :md="16">
                  <h2>Escaneie este código para pagar</h2>
                  <ul>
                    <li>
                      1. Acesse seu Internet Banking ou app de pagamentos.
                    </li>
                    <li>
                      2. Escolha pagar via Pix.
                    </li>
                    <li class="Payment-Widget--pix__QRCode__CTA--desktop">
                      3. Escaneie o código ao lado:
                    </li>
                    <li class="Payment-Widget--pix__QRCode__CTA--mobile">
                      3. Escaneie o código acima:
                    </li>
                  </ul>
                  <el-divider>Ou</el-divider>
                  <h4>Copie este código para fazer o pagamento</h4>
                  <div class="Payment-Widget--pix__LineCode">
                    <Clipboard :value="order.payment_info.pix_line_code" :btn_type="'text'"/>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="order.customer.loyalty_optin && HasRewardProgram">
          <el-col :span="24" >
            <LoyaltyProgramConfirmation />
          </el-col>
        </el-row>
        <p v-html="AppConfig.thankyou_page.order_pending.instruction_text.bankslip.replace('[CustomerName]', order.customer.first_name)" v-if="order.payment_info.type === 'BOLETO'"></p>
        <p v-html="AppConfig.thankyou_page.order_pending.instruction_text.credit_card.replace('[CustomerName]', order.customer.first_name)" v-if="order.payment_info.type === 'CREDIT_CARD'"></p>
        <p v-html="AppConfig.thankyou_page.order_pending.instruction_text.pix.replace('[CustomerName]', order.customer.first_name)" v-if="order.payment_info.type === 'PIX'"></p>
      </div>
    </div>

    <OrderInfos :order="order"/>

  </div>
</template>

<script>
// @ is an alias to /src
import LoyaltyProgramConfirmation from '@/components/_loyaltyProgram/LoyaltyProgramConfirmation.vue'
import BankslipWidget from '@/components/_order/BankslipWidget.vue'
import PostPurchaseCoupon from '@/components/_order/PostPurchaseCoupon.vue'
import OrderInfos from '@/components/_order/OrderInfos.vue'
import Clipboard from '@/components/_ui-components/Clipboard.vue'
import { mapFilters } from '@/helpers/filters'
import { mapGetters } from 'vuex'
import ProductScheduling from '../../components/_order/ProductScheduling/ProductScheduling.vue'

export default {
  name: 'OrderPending',
  components: {
    OrderInfos,
    BankslipWidget,
    PostPurchaseCoupon,
    Clipboard,
    LoyaltyProgramConfirmation,
    ProductScheduling
  },
  data () {
    return {
    }
  },
  props: ['checkout_id', 'order'],
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'Order', 'ActiveShippingMethod']),
    HasRewardProgram () {
      return this.AppConfig.contact_information.has_reward_program
    }
  },
  created () {

  },
  methods: {
    ...mapFilters(['formatMoney', 'formatDocument'])
  },
  beforeRouteLeave (to, from, next) {
    if (!this.order.closed) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
