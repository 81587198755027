<template>
  <div class="VariantSelector">
        <div class="VariantSelector__Header">
            <a @click="CloseVariantSelector">Fechar</a>
        </div>
        <div class="VariantSelector__Content">
            <div class="VariantSelector__SelectedProducts" v-for="product in EnabledProducts" :key="product._id" v-show="product.variants.length > 1">
                <label>Selecione um tamanho para <b>{{product.name}}</b></label>
                <el-select v-model="product.variantSelected" placeholder="Selecione" class="VariantSelector__Select">
                    <el-option
                        v-for="variant in product.variants"
                        :key="variant._id"
                        :label="variant.name"
                        :value="variant._id">
                    </el-option>
                </el-select>
            </div>
            <el-button size="mini" class="btn" v-if="variantsSelected.length === products.length" @click="addBundleProducts(variantsSelected)">
                Adicionar PRODUTOS
            </el-button>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapFilters } from '@/helpers/filters'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'VariantSelector',

  created () {
    if (this.products) {
      this.products.map((product) => {
        if (product.variants.length === 1) {
          product.variantSelected = product.variants[0]._id
        }
        return product
      })
      if (this.variantsSelected.length === this.products.length) {
        this.addBundleProducts(this.variantsSelected)
      }
    }
  },
  data () {
    return {

    }
  },

  watch: {

  },

  computed: {
    ...mapGetters(['Promotions', 'AutomaticPromotions', 'ManualPromotions']),
    EnabledProducts () {
      return this.products.filter((product) => {
        product.variants = product.variants.filter((variant) => {
          return variant
        })
        return product
      })
    },
    variantsSelected () {
      return this.products.filter((item) => {
        return item.variantSelected
      }).map((item) => {
        return item.variantSelected
      })
    }
  },

  props: ['products'],

  methods: {
    ...mapFilters(['formatMoney', 'dateFormat']),
    ...mapActions(['AddProduct']),
    addBundleProducts (selectedBundleProducts) {
      function getOccurrence (array, value) {
        var count = 0
        array.forEach((v) => (v === value && count++))
        return count
      }
      let ProductsToAdd = selectedBundleProducts.map((variantItem) => {
        let addProductOBJ = {
          variant_id: variantItem,
          quantity: getOccurrence(selectedBundleProducts, variantItem) || 1
        }
        return addProductOBJ
      })
      this.AddProduct(ProductsToAdd)
      //   for (let i = 0; i < ProductsToAdd.length; i++) {
      //     const ProductToAdd = ProductsToAdd[i]

      //     this.AddProduct(ProductToAdd)
      //   }
      this.CloseVariantSelector()
    },
    CloseVariantSelector () {
      this.products.map((product) => {
        delete product.variantSelected
        return product
      })
      this.$emit('bundle-products-added')
    }
  }
}
</script>
