<template>
    <div class="LoyaltyProgram__ConfirmationWidget">
        <div v-if="optinType === 1" class="el-form-outer">
            <el-checkbox
                placeholder="Loyalty Optin"
                v-model="optinAccepted"
                name="LoyaltyOptin"
                @focus="enableInput()"
                @blur="disableInput()"
                :class="{'el-form-item--active': isActive}"
            >
                Aceito participar do programa de pontos da Simple Organic.
                <el-tooltip class="item" effect="dark" content="Acumule pontos e troque por produtos no site Inspire360" placement="top-start">
                    <i class="el-icon-warning"></i>
                </el-tooltip>
            </el-checkbox>
        </div>
        <div v-else-if="optinType === 2" class="el-form-outer">
            <el-alert
                title="Participe do melhor programa de fidelidade para produtos de skincare, ganhe pontos e troque por produtos novos!"
                type="info"
                show-icon
                :closable="false"
              >
                <el-checkbox
                    placeholder="Loyalty Optin"
                    v-model="optinAccepted"
                    name="LoyaltyOptin"
                    @focus="enableInput()"
                    @blur="disableInput()"
                    :class="{'el-form-item--active': isActive}"
                >
                    Clique aqui e seja bem-vindo ao Inspire 360.
                </el-checkbox>
            </el-alert>
        </div>
        <div v-else-if="optinType === 3" class="el-form-outer">
            <el-alert
                title="Acumule pontos e troque por produtos no site Inspire360"
                type="info"
                :closable="false"
              >
                <el-checkbox
                    placeholder="Loyalty Optin"
                    v-model="optinAccepted"
                    name="LoyaltyOptin"
                    @focus="enableInput()"
                    @blur="disableInput()"
                    :class="{'el-form-item--active': isActive}"
                >
                    Aceito participar do programa de pontos da Simple Organic.
                </el-checkbox>
            </el-alert>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoyaltyProgramOptIn',
  data () {
    return {
      optinAccepted: false,
      isActive: false,
      optinType: 2
    }
  },
  created() {
    this.optinAccepted = !!this.Checkout.customer.loyalty_optin
  },
  computed: {
    ...mapGetters(['Checkout'])
  },
  methods: {
    enableInput () {
      this.isActive = true
    },
    disableInput () {
      this.isActive = false
    }
  },
  watch: {
    'optinAccepted' (val) {
      this.$store.dispatch('setLoyaltyOptin', val)
    }
  }
}
</script>
