import store from '@/store'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

class Tracking {
    initialized = false
    appInsights = new ApplicationInsights({})

    getUserID = () => {
      const _store = store
      let Checkout = _store.state.Checkout.Checkout
      // let userIdentified = !!(Checkout && Checkout.customer.email)
      let userIdentified = !!(Checkout)
      return userIdentified ? Checkout.id : 'User Not Identified'
    }
    getCheckoutCompany = () => {
      const _store = store
      let CheckoutConfig = _store.state.CheckoutConfig.CheckoutConfig
      let companyIdentified = !!(CheckoutConfig)
      return companyIdentified ? CheckoutConfig.company.name : 'Company Not Identified'
    }
    _setup = () => {
      return new Promise((resolve, reject) => {
        if (this.initialized) {
          return resolve()
        }

        this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: '14b04c5b-1343-4d0f-b0a6-9057557c12fb',
            autoTrackPageVisitTime: true,
            enableAutoRouteTracking: true
          }
        }).loadAppInsights()

        this.initialized = true
        return resolve()
      })
    }
  event = (name, propsOBJ) => {
    this._setup().then(() => {
      propsOBJ['distinct_id'] = this.getUserID()
      propsOBJ['company'] = this.getCheckoutCompany()
      propsOBJ['url'] = window.location.href
      this.appInsights.trackEvent({
        name,
        properties: {
          ...propsOBJ
        }
      })
    })
  }
  identify = (USER_ID) => {
    this._setup().then(() => {})
  }
  alias = (newID, existingID) => {
    this._setup().then(() => {
      // this.appInsights.alias(existingID, newID)
    })
  }
  setUserProps = (propsOBJ = {}) => {
    propsOBJ['USER_ID'] = this.getUserID()
    this._setup().then(() => {
      // this.appInsights.people.set(this.getUserID(), propsOBJ)
    })
  }
  setCompany = (company) => {
    this._setup().then(() => {
      // this.appInsights.people.append(this.getUserID(), 'CompaniesVisited', `${company}`)
    })
  }
}

export default new Tracking()
