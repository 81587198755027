import Vue from 'vue'
import html2canvas from 'html2canvas'

let installed = false

const html2CanvasPlugin = Vue => {
  if (installed) {
    return
  }
  window.html2canvas = html2canvas
  installed = true
}

Vue.use(html2CanvasPlugin)
