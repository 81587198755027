<template>
    <el-button-group class="ProductUpsell ProductUpsell__Checkbox">
        <el-checkbox v-model="product_enabled" class="el-checkbox--large" v-loading="CheckoutStatus.Products.loading">
            <div class="description">
            <b>{{title}}</b><br>
            <small>{{subtitle}}</small>
            </div>
        </el-checkbox>
    </el-button-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ProductUpsellCheckbox',
  data () {
    return {
      product_enabled: !!this.UpsellProduct
    }
  },
  created () {
    this.product_enabled = !!this.UpsellProduct
  },
  computed: {
    ...mapGetters(['CheckoutStatus', 'Checkout']),
    UpsellProduct () {
      return this.Checkout.products.find(product => product.variant_id === this.variant_id)
    }
  },
  watch: {
    product_enabled: {
      deep: true,
      //   immediate: true,
      handler: function (val, oldVal) {
        if (!val && this.UpsellProduct) {
          this.RemoveUpsellProduct({ variantId: this.variant_id })
        }
        if (val && !this.UpsellProduct) {
          this.AddUpsellProduct([{ variant_id: this.variant_id, quantity: 1 }])
        }
      }
    }
  },
  props: ['variant_id', 'title', 'subtitle'],
  methods: {
    ...mapActions(['AddUpsellProduct', 'UpdateUpsellProduct', 'RemoveUpsellProduct'])
  }
}
</script>
