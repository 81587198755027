<template>
  <el-form
      ref="PaymentInformations" :model="paymentData" :rules="rules"
      class="PaymentMethod__Form--Custom Form--Custom"
    >
    <el-row :gutter="20">
      <el-col :span="24" :sm="12">
        <div class="el-form-outer">
          <el-form-item
            prop="full_name"
            :label="`Nome impresso no cartão`"
            :class="{
              'el-form-item--active': activeInput === `Nome impresso no cartão`,
            }"
          >
            <el-input
              :placeholder="``"
              name="cc-name"
              autocomplete="cc-name"
              @focus="setActiveInput(`Nome impresso no cartão`)"
              @blur="setActiveInput('')"
              v-model="paymentData.full_name"
            ></el-input>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="24" :sm="12">
        <div class="el-form-outer">
          <el-form-item
            prop="document"
            :label="`CPF/CNPJ do titular do cartão`"
            v-facade="['###.###.###-##', '##.###.###/####-##']"
            :class="{
              'el-form-item--active':
                activeInput === `CPF/CNPJ do titular do cartão`,
            }"
          >
            <el-input
              :placeholder="``"
              pattern="\d*" inputmode="numeric"
              @focus="setActiveInput(`CPF/CNPJ do titular do cartão`)"
              @blur="setActiveInput('')"
              v-model="paymentData.document"
            ></el-input>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="16" :span="16">
        <div class="el-form-outer">
          <el-form-item
            prop="cc_number"
            :label="`Número do cartão de crédito`"
            :class="{
              'el-form-item--active':
                activeInput === `Número do cartão de crédito`,
            }"
          >
            <el-input
              :placeholder="`____ ____ ____ ____`"
              name="cc-number"
              autocomplete="cc-number"
              pattern="\d*" inputmode="numeric"
              @focus="setActiveInput(`Número do cartão de crédito`)"
              @blur="setActiveInput('')"
              v-model="paymentData.cc_number"
              v-facade="['#### #### #### ####']"
            ></el-input>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="8" :xs="24" :sm="24" :md="8">
        <div class="el-form-outer">
          <el-form-item
            prop="cc_cvv"
            :label="`CVV`"
            :class="{
              'el-form-item--active':
                activeInput === `Código de segurança do cartão`,
            }"
          >
            <div class="tooltip">
              <i class="el-icon-info"></i>
              <span class="tooltiptext">Código de segurança que se encontra atrás do cartão</span>
            </div>
            <el-input
              :placeholder="``"
              name="cc-csc"
              autocomplete="cc-csc"
              pattern="\d*" inputmode="numeric"
              @focus="setActiveInput(`Código de segurança do cartão`)"
              @blur="setActiveInput('')"
              v-model="paymentData.cc_cvv"
              v-facade="['###', '####']"
            ></el-input>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="12" :xs="24" :sm="24" :md="12">
        <div class="el-form-outer">
          <el-form-item
            prop="cc_expiration_month"
            :label="`Mês de validade`"
            :class="{
              'el-form-item--active': activeInput === `Mês de validade`,
            }"
          >
            <el-select
              v-model="paymentData.cc_expiration_month"
              :placeholder="`Mês de validade`"
              name="cc-exp-month"
              autocomplete="cc-exp-month"
              @focus="setActiveInput(`Mês de validade`)"
              @blur="setActiveInput('')"
            >
              <el-option
                v-for="item in months"
                :key="item.value"
                :label="item.value + ' - ' + item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.value }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.label
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="12" :xs="24" :sm="24" :md="12">
        <div class="el-form-outer">
          <el-form-item
            prop="cc_expiration_year"
            :label="`Ano de validade`"
            :class="{
              'el-form-item--active': activeInput === `Ano de validade`,
            }"
          >
            <el-select
              v-model="paymentData.cc_expiration_year"
              :placeholder="`Ano de validade`"
              name="cc-exp-year"
              autocomplete="cc-exp-year"
              @focus="setActiveInput(`Ano de validade`)"
              @blur="setActiveInput('')"
            >
              <el-option
                v-for="item in CurrentYears"
                :key="item.value"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="el-form-outer">
          <el-form-item
            prop="cc_installments"
            :label="`Número de parcelas`"
            :class="{
              'el-form-item--active': activeInput === `Número de parcelas`,
            }"
          >
            <el-select
              v-model="paymentData.cc_installments"
              :placeholder="`Selecione`"
              @focus="setActiveInput(`Número de parcelas`)"
              @blur="setActiveInput('')"
            >
              <el-option
                v-for="installment in EligibleInstallments"
                :key="installment.installments"
                :label="installmentMessage(installment)"
                :value="installment"
              >
                <span style>{{ installmentMessage(installment) }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { mapFilters } from '@/helpers/filters'
import { mapActions, mapGetters } from 'vuex'
import eventBus from '../../../../helpers/eventBus'
import paymentFactory from '../../../../services/Factories/paymentFactory'

export default {
  name: 'EvHubForm',
  data () {
    return {
      paymentService: null,
      fallbackPaymentService: null,
      activeInput: '',
      installmentSelected: '',
      paymentData: {
        full_name: null,
        document: null,
        cc_number: null,
        cc_brand: '',
        cc_cvv: null,
        cc_last_4: '',
        cc_expiration_month: null,
        cc_expiration_year: null,
        cc_installments: null
      },
      months: [
        {
          value: '01',
          label: 'Janeiro'
        },
        {
          value: '02',
          label: 'Fevereiro'
        },
        {
          value: '03',
          label: 'Março'
        },
        {
          value: '04',
          label: 'Abril'
        },
        {
          value: '05',
          label: 'Maio'
        },
        {
          value: '06',
          label: 'Junho'
        },
        {
          value: '07',
          label: 'Julho'
        },
        {
          value: '08',
          label: 'Agosto'
        },
        {
          value: '09',
          label: 'Setembro'
        },
        {
          value: '10',
          label: 'Outubro'
        },
        {
          value: '11',
          label: 'Novembro'
        },
        {
          value: '12',
          label: 'Dezembro'
        }
      ],
      rules: {
        full_name: [
          {
            required: true,
            message: 'Digite o nome impresso no cartão',
            trigger: 'change'
          },
          { validator: this.ValidateUserName, trigger: 'change' }
        ],
        document: [
          {
            required: true,
            message: `Digite seu CPF OU CNPJ`,
            trigger: 'change'
          },
          { validator: this.ValidateUserDocument, trigger: 'change' }
        ],
        cc_number: [
          {
            required: true,
            message: 'Digite o número do cartão',
            trigger: 'change'
          },
          { validator: this.Validatecc_number, trigger: 'blur' }
        ],
        cc_cvv: [
          { required: true, message: 'Digite o CVV', trigger: 'change' },
          { validator: this.Validatecc_cvv, trigger: ['change', 'blur'] }
        ],
        cc_expiration_month: [
          {
            required: true,
            message: 'Selecione o mês de validade',
            trigger: 'change'
          },
          { validator: this.ValidateCcExpiration, trigger: 'change' }
        ],
        cc_expiration_year: [
          {
            required: true,
            message: 'Selecione o ano de validade',
            trigger: 'change'
          },
          { validator: this.ValidateCcExpiration, trigger: 'change' }
        ],
        cc_installments: [
          {
            required: true,
            message: 'Selecione suas parcelas',
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutConfig']),
    userDocMask (value) {
      return !this.paymentData.document ||
        this.paymentData.document.replace(/[^\d]+/g, '').length <= 11
        ? '###.###.###-##'
        : '##.###.###/####-##'
    },
    CurrentYears: () => {
      var list = []
      for (var i = new Date().getFullYear(); i <= new Date().getFullYear() + 50; i++
      ) {
        list.push(i)
      }
      return list
    },
    EligibleInstallments () {
      let minimumInstallment = this.CheckoutConfig.paymentInformationConfig.credit_card_config.minimum_installment_value
      return this.method.installments.filter(_i => {
        return _i.installments === 1 || _i.value >= minimumInstallment
      })
    }
  },
  props: ['method'],
  created () {
    if (this.EligibleInstallments.length === 1) {
      this.paymentData.cc_installments = this.EligibleInstallments[0]
    }

    this.SetUpPaymentService()
  },
  mounted () {
    var scriptTag = document.createElement('script')
    scriptTag.src = 'https://service.pagbrasil.com/js/pagbrasil.js?20240311'
    document.body.appendChild(scriptTag)
  },
  methods: {
    ...mapFilters(['formatMoney', 'checkUserDocument', 'onlyTextField']),
    ...mapActions([
      'UpdateCheckoutState',
      'setAnalyticsError',
      'UpdatePaymentState',
      'SetPaymentMethod',
      'setCheckoutStatus'
    ]),
    SetUpPaymentService () {
      var paymentGatewayName = this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name
      var pubKey = this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.pubKey

      var paymentFallbackGatewayName = this.CheckoutConfig.paymentInformationConfig.credit_card_config.fallbackProvider.name
      var fallbackPubKey = this.CheckoutConfig.paymentInformationConfig.credit_card_config.fallbackProvider.pubKey

      let $paymentServices = paymentFactory.getPaymentServices(paymentGatewayName, paymentFallbackGatewayName)

      this.paymentService = $paymentServices.default
      this.fallbackPaymentService = $paymentServices.fallback

      paymentFactory.setPubKeys(pubKey, fallbackPubKey)

      // this.paymentService.setPubKey(pubKey)
    },
    setActiveInput (input) {
      this.activeInput = input
    },
    ValidateUserName (rule, value, callback) {
      if (this.onlyTextField(value)) {
        return callback()
      } else {
        return callback(new Error(`Números não são permitidos`))
      }
    },
    ValidateUserDocument (rule, value, callback) {
      if (!this.checkUserDocument(value)) {
        return callback(
          new Error(
            `${
              value.replace(/[^\d]+/g, '').length <= 11 ? 'CPF' : 'CNPJ'
            } inválido`
          )
        )
      } else {
        callback()
      }
    },
    Validatecc_number (rule, value, callback) {
      if (this.paymentData.cc_number) {
        return this.paymentService.validateNumber(
          this.paymentData.cc_number.replace(/\D/g, '')
        )
          ? callback()
          : callback(new Error(`Cartão de crédito inválido`))
      }
      return callback()
    },
    Validatecc_cvv (rule, value, callback) {
      if (this.paymentData.cc_number && this.paymentData.cc_cvv && this.paymentData.cc_cvv.length >= 3) {
        return this.paymentService.validateSecurityCode(
          this.paymentData.cc_number,
          this.paymentData.cc_cvv
        )
          ? callback()
          : callback(new Error(`CVV inválido`))
      }
      return callback(new Error(`CVV inválido`))
    },
    ValidateCcExpiration (rule, value, callback) {
      if (
        this.paymentData.cc_expiration_month &&
        this.paymentData.cc_expiration_year
      ) {
        return this.paymentService.validateExpirationDate(
          this.paymentData.cc_expiration_month,
          this.paymentData.cc_expiration_year
        )
          ? callback()
          : callback(new Error(`Data inválida`))
      }
      return callback()
    },
    GetCcBrand (ccNumber = this.paymentData.cc_number) {
      if (ccNumber) {
        console.log('Brand: ', this.paymentService.getCardBrand(ccNumber))
        var brand = this.paymentService.getCardBrand(ccNumber)
        return brand.toUpperCase()
      }

      return null
    },
    installmentMessage (installment) {
      if (installment.interest_rate !== undefined) {
        return `${installment.installments}x de ${this.formatMoney(
          installment.value
        )} | Total:  ${this.formatMoney(installment.total_in_installments)} ${
          installment.interest_rate > 0
            ? `(Juros de ${installment.interest_rate}%)`
            : ``
        }`
      }
    },
    IsValidPaymentService (paymentService) {
      return paymentService !== null &&
            paymentService !== undefined &&
            paymentService.pubKey !== null &&
            paymentService.pubKey !== undefined &&
            paymentService.pubKey !== ''
    },
    async generateHash (number, cvc, expirationMonth, expirationYear) {
      if (number && cvc && expirationMonth && expirationYear) {
        try {
          if (!this.IsValidPaymentService(this.paymentService)) {
            this.SetUpPaymentService()
          }

          eventBus.$emit('creditCardFormFulfilled')

          let paymentHashes = await paymentFactory.getPaymentHashes(
            number,
            cvc,
            expirationMonth,
            expirationYear,
            this.paymentData.full_name,
            this.paymentData.document
          )
          // console.log(paymentHashes)

          return paymentHashes
        } catch (error) {
          this.setAnalyticsError({
            id: `CREDIT_CARD_VALIDATION_ERROR`,
            method: 'generateHash',
            error: `createPaymentHash: ${error.message}`,
            fatal: true
          })
          this.setCheckoutStatus({
            PaymentOptions: {
              update: 'INVALID_CREDIT_CARD'
            }
          })
        }
      } else {
        return null
      }
    },
    async generateHashAndUpdatePaymentInfo () {
      var paymentHash = await this.generateHash(
        this.paymentData.cc_number,
        this.paymentData.cc_cvv,
        this.paymentData.cc_expiration_month,
        this.paymentData.cc_expiration_year
      )

      this.UpdatePaymentState({
        default_hash: paymentHash.default,
        fallback_hash: paymentHash.fallback
      })
    },
    enterAnimation (el) {
      const timeline = Animation.timeline
      timeline.from(el, {
        duration: 0.5,
        y: -5,
        opacity: 0,
        stagger: 0.1,
        ease: 'expo.inOut'
      })
    },
    leaveAnimation (el) {
      const timeline = Animation.timeline
      timeline.to(el, {
        duration: 0.5,
        y: -5,
        opacity: 0,
        stagger: 0.1,
        ease: 'expo.inOut'
      })
    }
  },
  watch: {
    paymentData: {
      deep: true,

      async handler (val) {
        this.$store.commit('setCheckoutStatus', {
          PaymentOptions: {
            update: 'IDLE'
          }
        })

        if (!(val.full_name && val.full_name.length >= 1)) {
          return
        }
        if (!(val.document && val.document.replace(/\D/g, '').length >= 11)) {
          return
        }
        if (!(val.cc_number && val.cc_number.replace(/\D/g, '').length >= 15)) {
          return
        }
        if (!(val.cc_cvv && val.cc_cvv.replace(/\D/g, '').length >= 3)) {
          return
        }
        if (!val.cc_expiration_month) {
          return
        }
        if (!val.cc_expiration_year) {
          return
        }
        let ccNumber = val.cc_number ? val.cc_number.replace(/\D/g, '') : null
        this.paymentData.cc_brand =
          ccNumber &&
          ccNumber.length >= 15 &&
          this.GetCcBrand(ccNumber.toString())
            ? this.GetCcBrand(ccNumber.toString())
            : null
        this.paymentData.cc_last_4 =
          ccNumber && ccNumber.length >= 15 ? ccNumber.slice(12, 16) : null
        this.paymentData.cc_expiration_month = this.paymentData
          .cc_expiration_month
          ? this.paymentData.cc_expiration_month.toString()
          : null

        if (this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name !== 'SafraPay') {
          this.paymentData.cc_expiration_year = this.paymentData
            .cc_expiration_year
            ? this.paymentData.cc_expiration_year.toString().slice(-2)
            : null
        }

        this.UpdatePaymentState({
          brand: this.paymentData.cc_brand,
          last_four_numbers: this.paymentData.cc_last_4,
          full_name: this.paymentData.full_name,
          document: this.paymentData.document
        })
      }
    },
    deep: true,
    'paymentData.cc_installments': function (installment) {
      this.SetPaymentMethod(installment)
    }
  }
}
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
  padding: 1% 0 0 0;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #303133;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 100%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #303133 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
