<template>
    <div class="Checkout__Section">
        <el-form :ref="`CustomAttributeWidget__${attribute_key}`" :model="CustomAttribute" class="Checkout__Form--Custom Form--Custom">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="24" :md="24" :span="24">
                    <div class="el-form-outer" v-if="type === 'radio'">
                        <el-form-item
                          class="el-form-item--radio"
                          :class="{'el-form-item--active': activeInput === attribute_key}"
                          :label="label"
                          :prop="attribute_key"
                          :ref="attribute_key"
                          :rules="{
                            required: required, message: 'Escolha uma opção', trigger: 'change'
                          }"
                        >
                            <div class="el-radio-group__inner">
                                <el-radio-group
                                    inline
                                    :ref="attribute_key"
                                    v-model="CustomAttribute[attribute_key]"
                                    @change="processCustomAttributes()"
                                >
                                    <el-radio v-for="(v, index) in attribute_value" :key="index" :label="v.value" >{{v.label}}</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                    </div>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CustomAttribute-Widget',
  data () {
    let CustomAttribute = {}
    CustomAttribute[this.attribute_key] = this.CurrentAttributeValue ? this.CurrentAttributeValue.value : null
    return {
      activeInput: null,
      CustomAttribute
    }
  },
  created () {
    this.CustomAttribute[this.attribute_key] = this.CurrentAttributeValue ? this.CurrentAttributeValue.value : null
  },
  computed: {
    ...mapGetters(['AppConfig', 'CheckoutStatus', 'Checkout', 'CustomAttributes']),
    CurrentAttributeValue () {
      return this.CustomAttributes.find(attr => attr.name === this.attribute_key)
    }
  },
  watch: {
  },
  props: ['attribute_key', 'type', 'label', 'attribute_value', 'placeholder', 'required'],
  methods: {
    // ...mapActions(['AddCustomAttribute'])
    ...mapActions(['SetCustomAttribute', 'SendCustomAttributes', 'DeleteCustomAttribute']),
    setActiveInput (input) {
      this.activeInput = input
    },
    async processCustomAttributes () {
      try {
        let $valid = await this.$refs[`CustomAttributeWidget__${this.attribute_key}`].validate()
        if (!$valid) {
          throw new Error()
        }
        await this.SetCustomAttribute([this.attribute_key, this.CustomAttribute[this.attribute_key]])
        if (this.CustomAttributes) { await this.SendCustomAttributes() }
        return true
      } catch (e) {
        throw new Error(`${e.message}`)
      }
    }
  }
}
</script>
