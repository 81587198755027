<template>
    <el-button-group class="ProductUpsell">
        <div class="el-form-item el-quantity" v-loading="CheckoutStatus.Products.loading">
            <el-button type="text" class="el-quantity__button" icon="el-icon-minus" @click="decrementProductQuantity"></el-button>
            <input type="text" name="" id="" class="el-quantity__input" :value="variant_value" >
            <el-button type="text" class="el-quantity__button" icon="el-icon-plus" @click="incrementProductQuantity"></el-button>
        </div>
        <div class="description">
          <b>{{title}}</b><br>
          <small>{{subtitle}}</small>
        </div>
    </el-button-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ProductUpsellSelector',
  data () {
    return {
    }
  },
  created () {
    this.variant_value = this.UpsellProduct ? this.UpsellProduct.quantity : 0
  },
  computed: {
    ...mapGetters(['CheckoutStatus', 'Checkout']),
    UpsellProduct () {
      return this.Checkout.products.find(product => product.variant_id === this.variant)
    }
  },
  props: ['variant', 'title', 'subtitle'],
  methods: {
    ...mapActions(['AddUpsellProduct', 'UpdateUpsellProduct', 'RemoveUpsellProduct']),
    incrementProductQuantity () {
      this.variant_value++
      this.updateProductUpsellQuantity(this.variant_value, 'increment')
    },
    decrementProductQuantity () {
      if (this.variant_value > 0) {
        this.variant_value--
        this.updateProductUpsellQuantity(this.variant_value, 'decrement')
      }
    },
    updateProductUpsellQuantity (ProductQuantity, operation) {
      var variantId = this.variant
      var quantity = ProductQuantity
      if (ProductQuantity === 1 && operation === 'increment') {
        this.AddUpsellProduct([{ variant_id: variantId, quantity }])
      } else if ((ProductQuantity === 1 && operation === 'decrement') || ProductQuantity > 1) {
        this.UpdateUpsellProduct({ variantId, quantity })
      } else {
        this.RemoveUpsellProduct({ variantId })
      }
    }
  }
}
</script>
