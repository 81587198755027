import Vue from 'vue'
import Vuex from 'vuex'

import General from '../modules/General'
import Auth from '../modules/auth'
import BillingInfo from '../modules/billingInfo'
import Cart from '../modules/cart'
import Checkout from '../modules/checkout'
import CheckoutConfig from '../modules/checkoutConfig'
import CheckoutAnalytics from '../modules/checkoutAnalytics'
import productList from '../modules/productList'
import ContactInfo from '../modules/contactInfo'
import Promotion from '../modules/promotion'
import Product from '../modules/product'
import Discount from '../modules/discount'
import Addition from '../modules/addition'
import GiftCard from '../modules/giftCard'
import Incoming from '../modules/incoming'
import Order from '../modules/order'
import PaymentOptions from '../modules/paymentOptions'
import ShippingMethods from '../modules/shippingMethods'

import Sidebar from '../modules/Sidebar'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    General,
    Auth,
    BillingInfo,
    Cart,
    Checkout,
    CheckoutConfig,
    CheckoutAnalytics,
    productList,
    ContactInfo,
    Product,
    Promotion,
    Discount,
    Addition,
    GiftCard,
    Incoming,
    Order,
    PaymentOptions,
    ShippingMethods,
    Sidebar
  }
})
