<template>
  <div>
    <LoadingFullscreen :description="'Aguarde, estamos processando seu pedido'"  :image="AppConfig.assets.images.splash"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingFullscreen from '@/components/_structure/LoadingFullscreen.vue'
export default {
  name: 'OrderProcessing',
  components: {
    LoadingFullscreen
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutStatus', 'currentStep'])
  },
  methods: {
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    let isOrderProcessing = this.CheckoutStatus.Order.close === 'LOADING'
    !isOrderProcessing ? next(true) : next(false)
  }
}
</script>
