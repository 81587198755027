import API from '@/api'
import EventBus from '@/helpers/eventBus'
import Search from '@/services/search'

const state = {
  Discounts: []
}

const getters = {
  Discounts: function (state) {
    return state.Discounts
  }
}

const actions = {
  async AddDiscount ({ commit, getters, dispatch }, { id, category, amount, type, name }) {
    if (id !== undefined && category !== undefined && amount !== undefined && name !== undefined) {
      if (type === 'Percentage') {

      }

      commit('AddDiscount', { id, category, amount, type, name })
    }
  },
  async DeleteDiscount ({ commit, getters, dispatch }, { key, value }) {
    commit('DeleteDiscount', { key, value })
  },
  addLineItemsDiscounts ({ commit, dispatch }, checkout) {
    var discounts = checkout.products.map(product => {
      if (!product.discounts || product.discounts.length === 0) {
        return
      }

      return product.discounts.reduce((prev, cur) => {
        return prev + cur.amount
      }, 0)
    })

    var lineItemsDiscount = discounts.reduce((prev, cur) => {
      let prevAmount = prev || 0
      let curAmount = cur || 0
      return prevAmount + curAmount
    }, 0)

    if (lineItemsDiscount > 0) {
      // lineItemsDiscount = lineItemsDiscount / 100

      // dispatch('AddDiscount', {
      //   id: `lineitem_discount`,
      //   category: 'lineitem_discount',
      //   amount: lineItemsDiscount,
      //   type: 'fixed_amount',
      //   name: `Descontos de bundle`
      // })
    }
  },
  async ClearDiscounts ({ commit, getters }) {
    commit('RemoveDiscounts')
  },
  async ApplyDiscountCode ({ rootState, commit, getters, dispatch }, { checkoutId, discountCode }) {
    dispatch('setCheckoutStatus', {
      DiscountCode: {
        ...rootState.General.CheckoutStatus.DiscountCode,
        add: 'LOADING'
      }
    })
    const promotionCoupon = getters.AppConfig.ProductPromotions ? getters.AppConfig.ProductPromotions.find(promotion => promotion.promotion_type === 'coupon' && promotion.promotion_type_id.toLowerCase() === discountCode.toLowerCase()) : null
    if (!!promotionCoupon && promotionCoupon.variant_triggers) {
      let promotionTriggered = rootState.Checkout.Checkout.products.some(item => promotionCoupon.variant_triggers.includes(item.variant_id))
      if (!promotionTriggered) {
        let cupomErrorMessage = `Este cupom não é válido para este carrinho.`
        dispatch('setCheckoutStatus', {
          DiscountCode: {
            ...rootState.General.CheckoutStatus.DiscountCode,
            add: 'ERROR'
          }
        })
        return commit('UpdateCheckoutState', {
          ...getters.Checkout,
          messages: [{
            message: cupomErrorMessage,
            type: 'error'
          }]
        })
      }
    }
    await API.post(`/checkouts/${checkoutId}/ApplyDiscount`, {
      'discount_code': discountCode
    }).then(res => {
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: res.data.products,
        discount_codes: res.data.discount_codes,
        messages: res.data.messages,
        totals: {
          ...getters.Checkout.totals,
          total_discount: res.data.totals.total_discount,
          total_without_discounts: res.data.totals.total_without_discounts,
          total: res.data.totals.total,
          shipping: res.data.totals.shipping
        }
      })

      dispatch('GetCheckout', rootState.Checkout.Checkout.id).then(() => {
        if (rootState.Checkout.Checkout.shipping_info.address.zip_code && rootState.Checkout.Checkout.activeStep && rootState.Checkout.Checkout.requires_shipping) {
          dispatch('GetShippingMethods', rootState.Checkout.Checkout.id)
        }
      })
      EventBus.$emit('checkout-updated')

      if (res.data.messages.filter(message => (message.type !== 'info')).length) {
        if (discountCode.toLowerCase().indexOf('friend-') > -1) {
          return dispatch('setCheckoutStatus', {
            DiscountCode: {
              ...rootState.General.CheckoutStatus.DiscountCode,
              add: 'FRIEND-COUPON-ERROR'
            }
          })
        }
        return dispatch('setCheckoutStatus', {
          DiscountCode: {
            ...rootState.General.CheckoutStatus.DiscountCode,
            add: 'ERROR'
          }
        })
      } else {
        return dispatch('setCheckoutStatus', {
          DiscountCode: {
            ...rootState.General.CheckoutStatus.DiscountCode,
            add: 'IDLE'
          }
        })
      }
    }).catch(err => {
      dispatch('setCheckoutStatus', {
        DiscountCode: {
          ...rootState.General.CheckoutStatus.DiscountCode,
          add: 'ERROR'
        }
      })
      dispatch('setAnalyticsError', { id: `checkoutId=${checkoutId}&code=${discountCode}`, method: 'ApplyDiscountCode', error: `${err.message}`, fatal: false })
      return new Error(`ApplyDiscountCode: ${err.message}`)
    })
  },
  async RemoveDiscountCode ({ rootState, commit, dispatch, getters }, { checkoutId, discount }) {
    dispatch('setCheckoutStatus', {
      DiscountCode: {
        ...rootState.General.CheckoutStatus.DiscountCode,
        delete: 'LOADING'
      }
    })
    await API.delete(`/checkouts/${checkoutId}/RemoveDiscount/${discount.id}`).then(res => {
      dispatch('setCheckoutStatus', {
        DiscountCode: {
          ...rootState.General.CheckoutStatus.DiscountCode,
          delete: 'IDLE'
        }
      })
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: res.data.products,
        discount_codes: res.data.discount_codes,
        messages: res.data.messages,
        totals: {
          ...getters.Checkout.totals,
          total_discount: res.data.totals.total_discount,
          total_gift_cards: res.data.totals.total_gift_cards,
          total_without_discounts: res.data.totals.total_without_discounts,
          total: res.data.totals.total,
          shipping: res.data.totals.shipping
        }
      })

      dispatch('GetCheckout', rootState.Checkout.Checkout.id).then(() => {
        if (rootState.Checkout.Checkout.shipping_info.address.zip_code && rootState.Checkout.Checkout.activeStep && rootState.Checkout.Checkout.requires_shipping) {
          dispatch('GetShippingMethods', rootState.Checkout.Checkout.id)
        }
      })
      EventBus.$emit('checkout-updated')
    }).catch(err => {
      dispatch('setCheckoutStatus', {
        DiscountCode: {
          ...rootState.General.CheckoutStatus.DiscountCode,
          delete: 'IDLE'
        }
      })
      dispatch('setAnalyticsError', { id: `checkoutId=${checkoutId}&code=${discount.code}`, method: 'RemoveDiscountCode', error: `${err.message}`, fatal: false })
      return new Error(`RemoveDiscountCode: ${err.message}`)
    })
  }
}

const mutations = {
  AddDiscount: function (state, discount) {
    let discountsARR = [...state.Discounts]
    const discountIndex = state.Discounts.findIndex((discountItem) => discountItem.id === discount.id)

    discountIndex === -1
      ? discountsARR.push(discount)
      : discountsARR[discountIndex] = discount

    state.Discounts = discountsARR
  },
  DeleteDiscount: function (state, { key, value }) {
    if (value) {
      state.Discounts = Search.query(state.Discounts, key, 'not-equals', value)
    } else {
      state.Discounts = Search.query(state.Discounts, key, 'equals', value)
    }
  },
  RemoveDiscounts: function (state) {
    state.Discounts = []
    console.log(state.Discounts)
    console.log('RemoveDiscounts: ', state.Discounts)
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
