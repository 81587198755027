<template>
  <div class='Loading Loading-Fullscreen'>
    <div class='Loading-Fullscreen__content'>
        <img class="Loading-Fullscreen__loader--image" :alt='description' :src='image' ref="LoadingFullscreenLoaderImage"/>
        <h1 class="Loading-Fullscreen__loader--description" ref="LoadingFullscreenLoaderDescription">{{description}}</h1>
    </div>
  </div>
</template>

<script>
import Animation from '@/services/animation'
export default {
  name: 'LoadingFullscreen',
  components: {
  },
  props: ['description', 'image'],
  computed: {
  },
  mounted () {
    const { LoadingFullscreenLoaderImage, LoadingFullscreenLoaderDescription } = this.$refs
    const timeline = Animation.timeline
    timeline
      .from(LoadingFullscreenLoaderImage, { duration: 1, y: -5, opacity: 0, scale: 2, ease: 'back.inOut(1.7)' })
      .from(LoadingFullscreenLoaderDescription, { duration: 0.5, y: -5, opacity: 0, ease: 'back.inOut(1.7)' }, '-=.25')
      .call(() => {
        timeline.restart()
      }, null, '+=3')
  },
  methods: {
  }
}
</script>
