import API from '@/api'
// import RouterService from '../services/routerService'
// import CustomAttributesBase from '@/services/CustomAttributesBase'
// import EventBus from '@/helpers/eventBus'
// import Tracking from '@/services/tracking'
// import jsonp from 'jsonp'

const state = {
  CustomAttributes: []
}
const getters = {
  CustomAttributes: function (state) {
    let currentNoteAttributes = state.Checkout.note_attributes ? state.Checkout.note_attributes : []
    return [
      ...currentNoteAttributes,
      ...state.CustomAttributes
    ]
  }
}
const actions = {
  async SetCustomAttribute ({ commit, getters, dispatch }, [...args]) {
    commit('UpdateCustomAttributesState', [...args].slice(0, 2))
  },
  async SendCustomAttributes ({ rootState, state, commit, getters, dispatch }) {
    let $checkout = rootState.Checkout.Checkout
    dispatch('setCheckoutStatus', {
      CustomAttributes: {
        create: 'LOADING'
      }
    })

    await API.put(`/CheckOuts/${$checkout.id}/NoteAttributes`, state.CustomAttributes)
      .then(response => {
        dispatch('setCheckoutStatus', {
          CustomAttributes: {
            create: 'IDLE'
          }
        })
        return commit('UpdateCheckoutState', {
          ...getters.Checkout,
          note_attributes: response.data.data.note_attributes
        })
      })
      .catch((e) => {
        console.log(e)
        console.log(e.message)
        dispatch('setCheckoutStatus', {
          CustomAttributes: {
            create: 'ERROR'
          }
        })
        dispatch('_buildErrorAlert', {
          title: 'Erro no SetCustomAttributes',
          error: `Ocorreu um erro com a criação do seu carrinho: ${e}`
        })
        dispatch('setAnalyticsError', { id: `${$checkout.id}`, method: 'SetCustomAttributes', error: e.message, fatal: true })
        commit('UpdateCustomAttributesState', null)
      })
  },
  async DeleteCustomAttribute ({ rootState, state, commit, getters, dispatch }, attributeName) {
    let $checkout = rootState.Checkout.Checkout
    dispatch('setCheckoutStatus', {
      CustomAttributes: {
        delete: 'LOADING'
      }
    })

    await API.delete(`/CheckOuts/${$checkout.id}/NoteAttributes/${attributeName}`)
      .then(response => {
        dispatch('setCheckoutStatus', {
          CustomAttributes: {
            delete: 'IDLE'
          }
        })
        return commit('UpdateCheckoutState', {
          ...getters.Checkout,
          note_attributes: response.data.data.note_attributes
        })
      })
      .catch((e) => {
        console.log(e)
        console.log(e.message)
        dispatch('setCheckoutStatus', {
          CustomAttributes: {
            delete: 'ERROR'
          }
        })
        dispatch('_buildErrorAlert', {
          title: 'Erro no SetCustomAttributes',
          error: `Ocorreu um erro com a criação do seu carrinho: ${e}`
        })
        dispatch('setAnalyticsError', { id: `${$checkout.id}`, method: 'SetCustomAttributes', error: e.message, fatal: true })
        commit('UpdateCustomAttributesState', null)
      })
  },
  async UpdateCustomAttributesState ({ commit }, value) {
    commit('UpdateCustomAttributesState', value)
  }

}
const mutations = {
  UpdateCustomAttributesState: function (state, args) {
    let $currentAttribute = { name: args[0], value: args[1] }
    let $attributeToBeChanged = state.CustomAttributes.findIndex(attr => attr.name === args[0])
    if ($attributeToBeChanged !== -1) {
      state.CustomAttributes[$attributeToBeChanged] = $currentAttribute
    } else {
      state.CustomAttributes.push($currentAttribute)
    }
    state.CustomAttributes = [...state.CustomAttributes]
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
