import * as $locationPlugin from '../../services/location'

let installed = false

const install = Vue => {
  if (installed) {
    return
  }

  Object.defineProperties(Vue.prototype, {
    $locationPlugin: {
      get () {
        return $locationPlugin.default
      }
    }
  })

  installed = true
}

export default { install }
