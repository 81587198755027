<template>
  <el-form ref="BillingInformation" :model="Checkout" :rules="rules" class="PaymentMethod__Form--Custom Form--Custom">
    <div class="Checkout__Section">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :span="12">
          <h3>Insira o endereço da fatura do cartão</h3>
          <br>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="24" :span="12">
          <div class="el-form-outer">
            <el-form-item
              prop="billing_info.address.zip_code"
              label="CEP"
              :class="{'el-form-item--active': activeInput === 'CEP'}"
            >
              <el-input
                placeholder="CEP"
                @focus="setActiveInput('CEP')"
                @blur="setActiveInput('')"
                @input="zipInfo(Checkout.billing_info.address.zip_code)"
                v-model="Checkout.billing_info.address.zip_code"
                v-facade="'#####-###'"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :xs="24" :sm="24" :md="18">
          <div class="el-form-outer">
            <el-form-item
              prop="billing_info.address.street"
              label="Endereço"
              :class="{'el-form-item--active': activeInput === 'Endereço'}"
            >
              <el-input
                placeholder="Endereço"
                @focus="setActiveInput('Endereço')"
                @blur="setActiveInput('')"
                v-model="Checkout.billing_info.address.street"
                v-facade="'FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6">
          <div class="el-form-outer">
            <el-form-item
              prop="billing_info.address.street_number"
              label="Número"
              :class="{'el-form-item--active': activeInput === 'Número'}"
            >
              <el-input
                placeholder="Número"
                @focus="setActiveInput('Número')"
                @blur="setActiveInput('')"
                v-model="Checkout.billing_info.address.street_number"
                v-facade="'######'"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :span="12">
          <div class="el-form-outer">
            <el-form-item
              prop="billing_info.address.complement"
              label="Complemento"
              :class="{'el-form-item--active': activeInput === 'Complemento'}"
            >
              <el-input
                placeholder="Complemento"
                @focus="setActiveInput('Complemento')"
                @blur="setActiveInput('')"
                v-model="Checkout.billing_info.address.complement"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :span="12">
          <div class="el-form-outer">
            <el-form-item
              prop="billing_info.address.neighborhood"
              label="Bairro"
              :class="{'el-form-item--active': activeInput === 'Bairro'}"
            >
              <el-input
                placeholder="Bairro"
                @focus="setActiveInput('Bairro')"
                @blur="setActiveInput('')"
                v-model="Checkout.billing_info.address.neighborhood"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :span="12">
          <div class="el-form-outer">
            <el-form-item
              prop="billing_info.address.city"
              label="Cidade"
              :class="{'el-form-item--active': activeInput === 'Cidade'}"
            >
              <el-input
                placeholder="Cidade"
                @focus="setActiveInput('Cidade')"
                @blur="setActiveInput('')"
                v-model="Checkout.billing_info.address.city"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :span="12">
          <div class="el-form-outer">
            <el-form-item
                prop="billing_info.address.state"
                label="Estado"
                :class="{'el-form-item--active': activeInput === 'Estado'}"
              >
                <el-select
                  v-model="Checkout.billing_info.address.state"
                  placeholder="Selecione"
                  @focus="setActiveInput('Estado')"
                  @blur="setActiveInput(null)"
                >
                  <el-option
                    v-for="item in states"
                    :key="item.uf"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Location from '@/services/location'
import geoMixin from '../../_mixins/geoMixin'

export default {
  mixins: [geoMixin],
  data () {
    return {
      activeInput: '',
      billingData: {},
      stateList: [],
      billingFormLabels: {
        street: 'Endereço'
      },
      rules: {
        'billing_info.address.zip_code': [
          { required: true, message: 'Digite seu CEP', trigger: 'change' }
        ],
        'billing_info.address.street': [
          { required: true, message: 'Digite sua rua', trigger: 'change' }
        ],
        'billing_info.address.street_number': [
          { required: true, message: 'Digite o número', trigger: 'change' }
        ],
        'billing_info.address.neighborhood': [
          { required: true, message: 'Digite seu bairro', trigger: 'change' }
        ],
        'billing_info.address.city': [
          { required: true, message: 'Digite sua cidade', trigger: 'change' }
        ],
        'billing_info.address.state': [
          { required: true, message: 'Digite seu estado', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['Checkout', 'CheckoutConfig']),
    states () {
      return Location.states
    }
  },
  mounted () {
    this.IsNewBillingInfo(true)
  },
  beforeDestroy () {
    this.IsNewBillingInfo(false)
  },
  methods: {
    ...mapActions([
      'UpdateCheckoutState',
      'IsNewBillingInfo'
    ]),
    setActiveInput (input) {
      this.activeInput = input
    },
    zipInfo (zip) {
      this.getZipInfos(zip).then((addressObj) => {
        this.UpdateCheckoutState({
          billing_info: {
            ...this.Checkout.billing_info,
            address: {
              ...this.Checkout.billing_info.address,
              ...addressObj
            }
          }
        })
      }).catch((err) => {
        return new Error(`${err.message}`)
      })
    }
  }
}
</script>
