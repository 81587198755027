import API from '@/api'
import store from '@/store'
import EventBus from '@/helpers/eventBus'
const invalidDdds = [
  20,
  23,
  25,
  26,
  29,
  30,
  36,
  39,
  40,
  50,
  52,
  56,
  57,
  58,
  59,
  60,
  70,
  72,
  76,
  78,
  80,
  90
]

class ValidateService {
  async _validateEmailFormat (Email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const EmailFormatIsValid = re.test(Email)

    if (EmailFormatIsValid === true) {
      return true
    } else {
      throw new Error('Formatação de email inválida')
    }
  }
  async _validateEmailDomain (Email) {
    return new Promise(async (resolve, reject) => {
      let Checkout = store.getters.Checkout

      let CheckoutOperators = { // TO DO: Migrar objeto para uma estrutura de classe a ser importada
        ChangeCheckoutEmail: async (checkoutID, email) => {
          try {
            let $checkout = await API.post(`/checkouts/${checkoutID}/email/${email}`, {})
            EventBus.$emit('checkout-email-changed', $checkout.data)
            return $checkout
          } catch (error) {
            throw new Error(`Email não alterado: ${error.message}`)
          }
        }
      }
      try {
        let res = await CheckoutOperators.ChangeCheckoutEmail(Checkout.id, Email)
        resolve(res)
      } catch (error) {
        return reject(new Error('Email inválido: Verifique o email digitado'))
      }
    })
  }
  isValidPhone (phoneNumber) {
    if (!phoneNumber) {
      return false
    }

    var ddd = phoneNumber?.split(' ')[0]?.substring(phoneNumber.lastIndexOf(')'), phoneNumber.lastIndexOf('(') + 1)
    if (!phoneDddValid(ddd)) {
      return false
    }

    var firstPhoneDigit = phoneNumber?.split(' ')[1]?.charAt(0)

    if (isCellPhone(firstPhoneDigit)) {
      return isValidFormatCellPhoneNumber(phoneNumber)
    }
    return isValidFormatLandLinePhoneNumber(phoneNumber)
  }
  async isValidEmail (Email) {
    if (!Email) {
      throw new Error('Digite seu email')
    }
    try {
      await this._validateEmailFormat(Email)
      await this._validateEmailDomain(Email)
      return true
    } catch (error) {
      throw new Error(error.message)
    }
  }
}

function isCellPhone (firstPhoneDigit) {
  return firstPhoneDigit >= 6 && firstPhoneDigit <= 9
}

function isValidFormatLandLinePhoneNumber (phoneNumber) {
  var correctPhoneFormat = /\(\d{2}\)\s\d{4}-\d{4}/g.test(phoneNumber)

  if (!correctPhoneFormat) {
    return false
  }

  var firstPhoneDigit = phoneNumber?.split(' ')[1]?.charAt(0)

  if (firstPhoneDigit < 2) {
    return false
  }

  return correctPhoneFormat
}

function isValidFormatCellPhoneNumber (phoneNumber) {
  var correctPhoneFormat = /\(\d{2}\)\s[9]\d{4}-\d{4}/g.test(phoneNumber)

  if (!correctPhoneFormat) {
    return false
  }

  var secondPhoneDigit = phoneNumber?.split(' ')[1]?.charAt(1)

  if (secondPhoneDigit < 1) {
    return false
  }

  return correctPhoneFormat
}

function phoneDddValid (ddd) {
  if (ddd < 11 || ddd > 99 || invalidDdds.find(invalid => invalid == ddd)) { //eslint-disable-line
    return false
  }
  return true
}

export default new ValidateService()
