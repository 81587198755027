<template>
  <div class="Arrival">
    <div class="Loading" :class="'Loading--error'">
      <div class="Loading__Content" v-if="CheckoutStatus.Auth.authorize === 'LOADING'">
        <img alt="Vue logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Carregando....</h3>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Auth.authorize === 'ERROR'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('AUTHORIZATION_FAILED')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <small>
            <em>AUTHORIZATION_FAILED</em>
          </small>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.recover === 'MISSING_MANDATORY_FIELDS'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('MISSING_MANDATORY_FIELDS')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <small>
            <em>MISSING_MANDATORY_FIELDS</em>
          </small>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.recover === 'LOADING'">
        <img alt="Vue logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Aguarde, estamos recuperando seu carrinho</h3>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.recover === 'MISSING_MANDATORY_FIELDS'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('MISSING_MANDATORY_FIELDS')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <small>
            <em>MISSING_MANDATORY_FIELDS</em>
          </small>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.recover === 'ERROR'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('API_RECOVER_CHECKOUT_ERROR')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <br />
          <small>
            <em>API_RECOVER_CHECKOUT_ERROR</em>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Animation from '@/services/animation'
import RouterService from '@/services/routerService'

export default {
  name: 'Recover',
  components: {},
  data () {
    return {
    }
  },
  props: ['checkout_token', 'recovery_key'],
  computed: {
    ...mapGetters(['AppConfig', 'CheckoutStatus', 'Checkout', 'GetCredentials']),
    CheckoutURLNormalized () {
      let normalizedURL =
            this.$route.query.url
              ? this.$route.query.url
                .slice(this.$route.query.url.indexOf('checkouts/'), this.$route.query.url.length)
                .replace('checkouts/', '')
                .replace('recover?key=', '')
                .split('/')
              : null
      return normalizedURL
    },
    checkoutToken () {
      return this.CheckoutURLNormalized && this.CheckoutURLNormalized[0] ? this.CheckoutURLNormalized[0] : null
    },
    recoveryKey () {
      return this.CheckoutURLNormalized && this.CheckoutURLNormalized[1] ? this.CheckoutURLNormalized[1] : null
    }
  },
  created: async function () {
    await this.Authenticate(this.GetCredentials)
    await this.GetCheckoutConfig()
    await this.RecoverCheckout({
      checkoutToken: this.checkoutToken,
      recoveryKey: this.recoveryKey
    })
  },
  mounted () {
    const { ErrorAlertLoaderImage, ErrorAlertLoaderDescription } = this.$refs
    const timeline = Animation.timeline
    timeline
      .from(ErrorAlertLoaderImage, { duration: 0.5, y: -5, opacity: 0, scale: 2, ease: 'back.inOut(1.7)' })
      .from(ErrorAlertLoaderDescription, { duration: 0.5, y: -5, opacity: 0, ease: 'back.inOut(1.7)' }, '-=.25')
  },
  methods: {
    ...mapActions(['Authenticate', 'SetCart', 'RecoverCheckout', 'GetCheckoutConfig', 'goToShop', 'goToShopSupport']),
    goTo (url) {
      RouterService.goTo(url)
    }
  },
  watch: {
    // Checkout (val) {
    //   if (val.id) {
    //     let CheckoutID = val ? val.id : this.$route.params.checkout_id
    //     this.goTo('/Checkout/' + CheckoutID)
    //   } else {
    //     this.goTo('/Arrival')
    //   }
    // },
    // deep: true
  }
}
</script>
