<template>
  <div class="Checkout__Messages">
    <el-alert
        @close="handleClose(message)"
        v-for="(message, index) in Notifications"
        :key="index"
        :type="message.type || 'info'"
        :description="message.message"
        >
    </el-alert>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from 'vuex'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CheckoutMessages',
  components: {

  },
  data () {
    return {
      privacyModal: false
    }
  },
  props: ['messages'],
  computed: {
    ...mapGetters(['Notifications'])
    // CheckoutMessages () {

    // }
  },
  created () {
  },
  methods: {
    ...mapActions(['SetNotification', 'RemoveNotification']),
    handleClose (notification) {
      this.RemoveNotification(notification)
    }
  },
  watch: {
    'messages': {
      deep: true,
      handler (val) {
        if (val.length > 0) {
          if (!this.type) {
            let messagesToUpdate = this.messages.map(m => {
              if (m.message.toLowerCase().includes('inválido') && document.querySelector('.Discount--Form input[type="text"]').value.toLowerCase().includes('cashback')) {
                m.message = 'Cupom é inválido para esse e-mail, tente outro.'
              }
              return m
            })

            messagesToUpdate.forEach(messageToUpdate => {
              this.SetNotification(messageToUpdate)
            })
            return
          }
          let messagesToUpdate = this.messages.filter((message) => {
            return message.type === this.type
          })

          messagesToUpdate.forEach(messageToUpdate => {
            this.SetNotification(messageToUpdate)
          })
        }
      }
    }
  }
}
</script>
