import API from '@/api'
import EventBus from '@/helpers/eventBus'
import validateService from '@/services/validateService'

const state = {
  contactInfo: ''
}

const getters = {
  contactInfo: function (state) {
    return state.contactInfo
  }
}

const actions = {
  async sendEmail ({ commit, dispatch }, { checkoutId, email }) {
    dispatch('setCheckoutStatus', {
      ContactInfos: {
        update: 'LOADING'
      }
    })

    await validateService._validateEmailDomain.then(resp => {
      dispatch('setCheckoutStatus', {
        ContactInfos: {
          update: 'IDLE'
        }
      })
    }).catch(err => {
      dispatch('setCheckoutStatus', {
        ContactInfos: {
          update: 'ERROR'
        }
      })
      dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'sendEmail', error: `${err.message}`, fatal: false })
      return new Error(`sendContactInformation: ${err.message}`)
    })
  },
  async sendContactInformation ({ commit, dispatch }, { checkoutId, checkout }) {
    // console.log('checkout.shipping_info.address: ', checkout.shipping_info.address)
    checkout.billing_info.address.zip_code = checkout.shipping_info.address.zip_code
    checkout.billing_info.address.street_number = checkout.shipping_info.address.street_number
    checkout.billing_info.address.city = checkout.shipping_info.address.city
    checkout.billing_info.address.neighborhood = checkout.shipping_info.address.neighborhood
    checkout.billing_info.address.street = checkout.shipping_info.address.street
    checkout.billing_info.address.state = checkout.shipping_info.address.state
    checkout.billing_info.address.complement = checkout.shipping_info.address.complement
    dispatch('setCheckoutStatus', {
      ContactInfos: {
        update: 'LOADING'
      }
    })

    var contactInfo = {
      checkout_customer_optin: checkout.checkout_customer_optin,
      customer: checkout.customer,
      shipping_info: checkout.shipping_info,
      billing_info: checkout.billing_info
    }

    await API.put(`/checkouts/${checkoutId}/ContactInformation/`, contactInfo)
      .then(checkout => {
        dispatch('setCheckoutStatus', {
          ContactInfos: {
            update: 'IDLE'
          }
        })
        commit('SetCheckoutState', checkout.data, 'SUCCESS')
        EventBus.$emit('checkout-updated')
      })
      .catch((err) => {
        dispatch('setCheckoutStatus', {
          ContactInfos: {
            update: 'ERROR'
          }
        })
        dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'sendContactInformation', error: `${err.message}`, fatal: false })
        return new Error(`sendContactInformation: ${err.message}`)
      })
  }
}

const mutations = {
  contactInfo: function (state, contactInfo) {
    state.contactInfo = contactInfo
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
