/* eslint-disable no-undef */
import { sha256 } from 'js-sha256'
import store from '@/store'
import Helpers from '@/services/helpers'
import EventBus from '@/helpers/eventBus'
class TikTokAnalytics {
  constructor(id) {
    this.id = id
    this._init()
  }
    _store = {};
    _setup = async function (id = this.id) {
      this.id = id || store.getters.CheckoutConfig.analyticsConfig.tiktok_pixel
      if (this._store.ready) {
        return true
      }
      if (id) {
        let w = window
        // let d = document;
        let t = 'ttq'

        w.TiktokAnalyticsObject = t
        w.ttq = w[t] = w[t] || []
        ttq.methods = ['track', 'identify', 'instances', 'debug', 'on', 'off', 'once', 'ready', 'alias', 'group', 'enableCookie', 'disableCookie']
        ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
          }
        }
        for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])
        ttq.instance = function (t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n])
          return e
        }
        ttq.load = async function (e, n) {
          var i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
          ttq._i = ttq._i || {}
          ttq._i[e] = []
          ttq._i[e]._u = i
          ttq._t = ttq._t || {}
          ttq._t[e] = +new Date()
          ttq._o = ttq._o || {}
          ttq._o[e] = n || {}
          await Helpers._loadJS(`${i}?sdkid=${e}&lib=${t}`)
          return ttq
        }

        // Part 2
        ttq.load(`${id}`)
        this._store.ready = true
        return true
      } else {
        return new Error('Tiktok ID não encontrado')
      }
    };
    _init = async function (id = this.id) {
      if (!this._store.ready) {
        await this._setup(this.id)
      }
      await this.identify()
      if (id) {
        this._store.id = id
        this._store.ready = true
        return true
      } else {
        return new Error('TikTok ID não encontrado')
      }
    };
    error = async (description = `not set`, fatal = false) => {
      if (!this._store.ready) {
        await this._setup(this.id)
      }
      await this.identify()
    };
    event = async (category = `not set`, action = `not set`, label = `not set`, value = 1) => {
      if (!this._store.ready) {
        await this._setup(this.id)
      }
      await this.identify()
    };
    identify = async () => {
      if (!this._store.ready) {
        await this._setup(this.id)
      }
      let Checkout = store.getters.Checkout
      let eventDetails = Checkout
      if (eventDetails?.customer?.email && eventDetails?.customer?.phone && eventDetails?.id) {
        ttq.identify({
          email: eventDetails.customer.email,
          phone_number: eventDetails.customer.phone,
          external_id: sha256(eventDetails.id)
        })
      }
    };
    initiateCheckout = async () => {
      if (!this._store.ready) {
        await this._setup(this.id)
      }
      await this.identify()
      let Checkout = store.getters.Checkout
      let eventDetails = Checkout
      ttq.track('InitiateCheckout', {
        quantity: eventDetails.products.reduce((sum, curr) => { return sum + curr.quantity }, 0),
        currency: 'BRL',
        value: eventDetails.totals.total
      })
    };
    pageview = async () => {
      await this.identify()
    };
    conversion = async (conversion) => {
      if (!this._store.ready) {
        await this._setup(this.id)
      }
      await this.identify()
      let eventDetails = conversion.order
      ttq.track('CompletePayment', {
        quantity: eventDetails.products.reduce((sum, curr) => { return sum + curr.quantity }, 0),
        currency: 'BRL',
        value: eventDetails.totals.total
      })
    }
    _services = {
      setInitiateEvents: async () => {
        this.initiateCheckout()
      }
    }
    _init = () => {
      EventBus.$on('checkout-created', async () => {
        setTimeout(async () => {
          await this._services.setInitiateEvents()
        }, 1000)
      })
    }
}
export default TikTokAnalytics
