import API from '@/api'
import EventBus from '@/helpers/eventBus'

const state = {
  DraftOrder: null
}
const getters = {
  DraftOrder: function (state) {
    return {
      ...state.DraftOrder
    }
  }
}
const actions = {
  async CreateCheckoutFromDraftOrder ({ commit, dispatch }, DraftOrderId) {
    if (!DraftOrderId) {
      dispatch('setCheckoutStatus', {
        Checkout: {
          draft_order: 'MISSING_MANDATORY_FIELDS'
        }
      })
      dispatch('setAnalyticsError', { id: `Missing mandatory fields`, method: 'CreateCheckoutFromDraftOrder', error: 'Missing mandatory fields', fatal: true })
      throw new Error('CreateCheckoutFromDraftOrder: Missing mandatory fields')
    }
    dispatch('setCheckoutStatus', {
      Checkout: {
        draft_order: 'LOADING'
      }
    })
    try {
      let checkout = await API.post(`/checkouts/draftorder/${DraftOrderId}`, {})
      dispatch('setCheckoutStatus', {
        Checkout: {
          draft_order: 'IDLE'
        }
      })
      dispatch('addLineItemsDiscounts', checkout.data)
      dispatch('GetCheckoutConfig', checkout.data.id).then(() => {
        commit('SetCheckoutState', checkout.data)
        EventBus.$emit('checkout-updated')
        return checkout.data
      })
    } catch (err) {
      dispatch('setCheckoutStatus', {
        Checkout: {
          draft_order: 'ERROR'
        }
      })
      dispatch('setAnalyticsError', { id: `${DraftOrderId}`, method: 'CreateCheckoutFromDraftOrder', error: err.message, fatal: true })
      commit('SetCheckoutState', null)
      throw new Error(`CreateCheckoutFromDraftOrder: ${err.message}`)
    }
  }

}
const mutations = {

}

export default {
  state,
  getters,
  actions,
  mutations
}
