import axios from 'axios'

export class AppMaxApi {
  #baseUrl = 'https://admin.appmax.com.br/api/v3/'
  #api = null
  constructor() {
    this.#api = axios.create({
      baseURL: this.#baseUrl,
      responseType: 'json'
    })
  }

  async tokenizeCard(body) {
    return this.#api.post('/tokenize/card', JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
