<template>
  <ul class="ShippingMethod__Resume__ProductList ProductList">
    <li
      class="ProductList__Item"
      :data-product-id="unavailableItem.product.product_id"
      :data-variant-id="unavailableItem.product.variant_id"
      :data-product-title="unavailableItem.product.title"
      data-customer-ready-visible
    >
      <div class="ProductList__Item__image">
        <div class="ProductList__Item-thumbnail">
          <div class="ProductList__Item-thumbnail__wrapper">
            <img
              :alt="unavailableItem.product.title"
              class="ProductList__Item-thumbnail__image"
              :src="unavailableItem.product.image"
            />
          </div>
        </div>
      </div>
      <div class="ProductList__Item__description">
            <span class="ProductList__Item__description__variant ProductList__Item__description--small-text">
                {{unavailableItem.product.title}}
            </span>
        <div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ProductList',
  components: {
  },
  props: ['unavailableItem']
}
</script>
