<template>
  <div
    class="PaymentInformations__Form MultipleCreditCard"
    :class="{
      'PaymentInformations__Form--Error':
        CreditCardFormStatus === 'ERROR_FORM_NOT_EXIST',
      'PaymentInformations__Form--Success':
        CreditCardFormStatus === 'FULFILLED',
    }"
  >
    <div v-if="!CreditCardFormOpen && CreditCardFormStatus !== 'FULFILLED'">
      <div
        class="MultipleCreditCard__Card MultipleCreditCard--Empty"
        @click="openCreditCardForm"
      >
        <div class="MultipleCreditCard__Card__Icon">
          <icon
            :src="require('@/assets/icons/main/credit-card.svg')"
            width="20"
            class=""
          ></icon>
        </div>
        <el-card shadow="never">
          <div>
            <span>Adicionar cartão {{ credit_card_index }}</span>
          </div>
        </el-card>
      </div>
    </div>
    <div v-else>
      <div
        class="MultipleCreditCard--Pending"
        v-show="CreditCardFormOpen && CreditCardFormStatus !== 'FULFILLED'"
      >
        <div class="PaymentInformations__Form__CTA MultipleCreditCard__CTA">
          <span class="PaymentInformations__Form__Title"
            >Dados do cartão {{ credit_card_index }}:</span
          >
          <span class="PaymentInformations__Form__SubTitle"
            >Digite os dados do cartão que deseja utilizar na compra:</span
          >
        </div>
        <el-form
          v-loading="CreditCardFormStatus === 'LOADING'"
          ref="PaymentInformations"
          :model="CreditCardData"
          :rules="CreditCardRules"
          class="
            PaymentInformations__Form--Custom PaymentInformations__Form__Inner
            Form--Custom
          "
        >
          <div class="Checkout__Section">
            <el-row :gutter="10">
              <el-col :span="24" :sm="12">
                <div class="el-form-outer">
                  <el-form-item
                    prop="FullName"
                    :label="`Nome impresso no cartão`"
                    :class="{
                      'el-form-item--active':
                        ActiveInput === `Nome impresso no cartão`,
                    }"
                  >
                    <el-input
                      :placeholder="``"
                      name="cc-name"
                      autocomplete="cc-name"
                      @focus="setActiveInput(`Nome impresso no cartão`)"
                      @blur="setActiveInput('')"
                      v-model="CreditCardData.FullName"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="24" :sm="12">
                <div class="el-form-outer">
                  <el-form-item
                    prop="Document"
                    :label="`CPF/CNPJ do titular do cartão`"
                    v-facade="['###.###.###-##', '##.###.###/####-##']"
                    :class="{
                      'el-form-item--active':
                        ActiveInput === `CPF/CNPJ do titular do cartão`,
                    }"
                  >
                    <el-input
                      :placeholder="``"
                      pattern="\d*"
                      inputmode="numeric"
                      @focus="setActiveInput(`CPF/CNPJ do titular do cartão`)"
                      @blur="setActiveInput('')"
                      v-model="CreditCardData.Document"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="0">
              <el-col :span="24">
                <div class="el-input-combo" group-name="cc-infos">
                  <el-col :span="12" :sm="12">
                    <div class="el-form-outer">
                      <el-form-item
                        prop="Number"
                        :label="`Número do cartão`"
                        :class="{
                          'el-form-item--active':
                            ActiveInput === `Número do cartão`,
                        }"
                        grouped_by="cc-infos"
                      >
                        <el-input
                          :placeholder="`____ ____ ____ ____`"
                          name="cc-number"
                          autocomplete="cc-number"
                          pattern="\d*"
                          inputmode="numeric"
                          @change.native="handleInput"
                          @input.native="handleInput"
                          @focus="setActiveInput(`Número do cartão`)"
                          @blur="setActiveInput('')"
                          v-model="CreditCardData.Number"
                          v-facade="['#### #### #### ####']"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="6" :sm="6">
                    <div class="el-form-outer">
                      <el-form-item
                        prop="CVV"
                        :label="`CVV`"
                        :class="{
                          'el-form-item--active':
                            ActiveInput === `Código de segurança do cartão`,
                        }"
                        grouped_by="cc-infos"
                      >
                        <div class="tooltip">
                          <i class="el-icon-info"></i>
                          <span class="tooltiptext"
                            >Código de segurança que se encontra atrás do
                            cartão</span
                          >
                        </div>
                        <el-input
                          :placeholder="``"
                          name="cc-csc"
                          autocomplete="cc-csc"
                          pattern="\d*"
                          inputmode="numeric"
                          @change.native="handleInput"
                          @input.native="handleInput"
                          @focus="
                            setActiveInput(`Código de segurança do cartão`)
                          "
                          @blur="setActiveInput('')"
                          v-model="CreditCardData.CVV"
                          v-facade="['###', '####']"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                  <el-col :span="6" :sm="6">
                    <div class="el-form-outer">
                      <el-form-item
                        prop="ExpirationDate"
                        :label="`Validade`"
                        :class="{
                          'el-form-item--active': ActiveInput === `Validade`,
                        }"
                        v-facade="'##/##'"
                        grouped_by="cc-infos"
                      >
                        <el-input
                          v-model="CreditCardData.ExpirationDate"
                          :placeholder="`Validade`"
                          name="cc-exp-date"
                          autocomplete="cc-exp-date"
                          pattern="\d*"
                          inputmode="numeric"
                          @change.native="handleInput"
                          @input.native="handleInput"
                          @focus="setActiveInput(`Validade`)"
                          @blur="setActiveInput('')"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </el-col>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
              <el-col :span="12" :xs="12" :sm="12" :md="12">
                <div class="el-form-outer">
                  <el-form-item
                    prop="Amount"
                    :label="`Valor a ser descontado:`"
                    :class="{
                      'el-form-item--active':
                        ActiveInput === `Valor a ser descontado`,
                      'el-form-item--disabled':
                      !allow_edit_partial_amount,
                      'el-form-item--highlighted is-success':
                      CreditCardAmountStatus === 'AMOUNT_DECREASED',
                      'el-form-item--highlighted is-warning':
                      CreditCardAmountStatus === 'AMOUNT_INCREASED',
                    }"
                    :disabled="!allow_edit_partial_amount"
                  >
                    <el-input
                      :placeholder="`Valor a ser descontado`"
                      name=""
                      autocomplete=""
                      pattern="\d*"
                      inputmode="numeric"
                      :disabled="!allow_edit_partial_amount"
                      @focus="setActiveInput(`Valor a ser descontado`)"
                      @blur="setActiveInput('')"
                      v-model="CreditCardData.Amount"
                      v-facade="[
                        '#.##',
                        '##.##',
                        '###.##',
                        '####.##',
                        '#####.##',
                        '######.##',
                      ]"
                    >
                      <template slot="prepend">R$</template>
                    </el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12" :xs="12" :sm="12" :md="12">
                <div class="el-form-outer">
                  <el-form-item
                    prop="Installment"
                    :label="`Número de parcelas`"
                    :class="{
                      'el-form-item--active':
                        ActiveInput === `Número de parcelas`,
                    }"
                  >
                    <el-select
                      v-model="CreditCardData.Installment"
                      :placeholder="`Selecione`"
                      @focus="setActiveInput(`Número de parcelas`)"
                      @blur="setActiveInput('')"
                    >
                      <el-option
                        v-for="installment in EligibleInstallments"
                        :key="installment.installments"
                        :label="installmentMessage(installment)"
                        :value="installment"
                      >
                        <span style>{{ installmentMessage(installment) }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <el-row>
          <el-col :span="24" :sm="24">
            <div class="MultipleCreditCard__Remaining" v-if="PaymentAmountRemaining > 0">
              <span>
                Valor restante à pagar:
                <b>{{ formatMoney(PaymentAmountRemaining) }}</b>
              </span>
            </div>
            <div class="MultipleCreditCard__Messages">
              <el-alert
                v-if="CreditCardAmountStatus === 'AMOUNT_DECREASED'"
                type="success"
                effect="dark"
                :closable="false"
              >
                O valor a ser processado neste cartão ficou menor que o valor anterior
              </el-alert>
              <el-alert
                v-if="CreditCardAmountStatus === 'AMOUNT_INCREASED'"
                type="warning"
                effect="dark"
                :closable="false"
              >
                O valor a ser processado neste cartão ficou maior que o valor anterior
              </el-alert>
            </div>
            <div class="MultipleCreditCard__Actions">
              <el-alert
                v-if="CreditCardFormStatus === 'NOT_FULFILLED'"
                title="Erro ao adicionar o cartão"
                type="error"
                description="Não foi possível adicionar este cartão, verifique as informações digitadas e o saldo disponível no cartão ou utilize outro"
                show-icon
              ></el-alert>
              <el-button
                class="el-button--block"
                plain
                @click="doFulfillCreditCard"
                v-if="ShouldFulfillCreditCard || true"
                >{{
                  CreditCardFormStatus === "LOADING"
                    ? "Aguarde, adicionando o cartão"
                    : "Adicionar cartão"
                }}</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <div
        class="MultipleCreditCard__Card MultipleCreditCard--Resume CreditCard"
        v-if="!CreditCardFormOpen && CreditCardFormStatus === 'FULFILLED'"
      >
        <div class="MultipleCreditCard__Card__Icon">
          <i class="el-icon-check"></i>
        </div>
        <div class="CreditCard__Content">
          <div class="CreditCard__Infos">
            <h5 class="CreditCard__Infos__Label">N° do cartão {{ credit_card_index }}</h5>
            <div class="CreditCard__Infos__Number">
              <icon
                :src="getCCBrandIcon(CreditCardData.Brand)"
                width="20"
                class=""
              ></icon>
              <b>•••• •••• •••• {{ CreditCardData.LastFourDigits }}</b>
            </div>
          </div>
          <div class="CreditCard__Holder-Infos">
            <span>
              {{ CreditCardData.FullName }} - {{ CreditCardData.Document }}
              <br />
              {{ formatMoney(+CreditCardData.Amount) }} em
              {{ CreditCardData.Installment.installments }}x de
              {{ formatMoney(CreditCardData.Installment.value) }}
            </span>
          </div>
        </div>
        <div @click="openCreditCardForm" class="CreditCard__Edit">
          <a href="#">Editar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'

import eventBus from '@/helpers/eventBus'
import paymentFactory from '@/services/Factories/paymentFactory'

import Icon from 'vue-inline-svg'

export default {
  name: 'MultipleCreditCardForm',
  components: {
    Icon
  },
  data() {
    return {
      originalPaymentRemaining: this.amount_remaining,
      paymentService: null,
      fallbackPaymentService: null,

      ActiveInput: '',
      CreditCardFormID: Math.floor(Math.random() * Date.now()).toString(16),
      CreditCardFormOpen: false,
      CreditCardFormStatus: 'IDLE',
      CreditCardAmountStatus: 'IDLE',
      ShouldFulfillCreditCard: false,
      CreditCardData: {
        FullName: null,
        Document: null,
        Amount: null,
        Number: null,
        Brand: '',
        CVV: null,
        LastFourDigits: '',
        ExpirationDate: null,
        ExpirationMonth: null,
        ExpirationYear: null,
        Installment: null
      },

      CreditCardRules: {
        FullName: [
          {
            required: true,
            message: 'Digite o Nome impresso no cartão',
            trigger: 'change'
          },
          { validator: this.ValidateUserName, trigger: 'change' }
        ],
        Document: [
          {
            required: true,
            message: `Digite seu CPF OU CNPJ`,
            trigger: 'change'
          },
          { validator: this.ValidateUserDocument, trigger: 'change' }
        ],
        Amount: [
          {
            required: true,
            message: 'Digite um valor a ser processado no cartão',
            trigger: 'change'
          },
          {
            validator: this.ValidatePartialAmount,
            trigger: ['change', 'blur']
          }
        ],
        Number: [
          {
            required: true,
            message: 'Digite o número do cartão',
            trigger: 'change'
          },
          { validator: this.ValidateNumber, trigger: 'blur' }
        ],
        CVV: [
          { required: true, message: 'Digite o CVV', trigger: 'change' },
          { validator: this.ValidateCVV, trigger: ['change', 'blur'] }
        ],
        ExpirationDate: [
          {
            required: true,
            message: 'Digite a validade',
            trigger: 'change'
          },
          { validator: this.ValidateCcExpiration, trigger: ['change', 'blur'] }
        ],
        ExpirationMonth: [
          {
            required: true,
            message: 'Selecione o mês de validade',
            trigger: 'change'
          },
          { validator: this.ValidateCcExpiration, trigger: ['change', 'blur'] }
        ],
        ExpirationYear: [
          {
            required: true,
            message: 'Selecione o ano de validade',
            trigger: 'change'
          },
          { validator: this.ValidateCcExpiration, trigger: ['change', 'blur'] }
        ],
        Installment: [
          {
            required: true,
            message: 'Selecione suas parcelas',
            trigger: 'change'
          }
        ]
      }
    }
  },
  async created() {
    this.setupPaymentService()
  },
  mounted() {
    var scriptTag = document.createElement('script')
    scriptTag.src = 'https://service.pagbrasil.com/js/pagbrasil.js?20240311'
    document.body.appendChild(scriptTag)

    eventBus.$on('multiple_credit_card.payment.over', () => {
      let _this = this
      if (!_this.allow_edit_partial_amount && _this.amount_remaining < 0) {
        this.openCreditCardForm()
      }
    })
    eventBus.$on('multiple_credit_card.payment.under', () => {
      let _this = this
      if (!_this.allow_edit_partial_amount) {
        this.openCreditCardForm()
      }
    })
  },
  computed: {
    ...mapGetters(['CheckoutConfig', 'AppConfig', 'Payment', 'Checkout']),
    serviceName() {
      return this.CheckoutConfig.paymentInformationConfig.two_credit_cards_config.provider.name.toLowerCase()
    },
    servicePubKey() {
      return this.CheckoutConfig.paymentInformationConfig
        .two_credit_cards_config.provider.pubKey
    },
    EligibleInstallments() {
      let minimumInstallment = this.CheckoutConfig.paymentInformationConfig.credit_card_config.minimum_installment_value
      return this.method.installments.map(installment => {
        let $Amount = +this.CreditCardData.Amount || this.amount_remaining || this.Checkout.totals.total
        let $TotalInInstallments = $Amount * (1 + installment.interest_rate)
        return {
          'id': installment.id,
          'installments': installment.installments,
          'value': $TotalInInstallments / installment.installments,
          'total_in_installments': $Amount * (1 + installment.interest_rate),
          'interest_rate': installment.interest_rate,
          'addition': installment.addition,
          'discount_value': installment.discount_value
        }
      }).filter((_i) => {
        return _i.installments === 1 || _i.value >= minimumInstallment
      })
    },
    PaymentAmountRemaining() {
      let $Remaining = this.amount_remaining
      return $Remaining
    }
  },
  props: ['amount_remaining', 'allow_edit_partial_amount', 'method', 'payment_infos', 'credit_card_index'],
  methods: {
    ...mapFilters(['formatMoney', 'checkUserDocument', 'checkPartialAmount', 'onlyTextField']),
    ...mapActions([
      'UpdateCheckoutState',
      'UpdatePaymentState',
      'SetPaymentMethod',
      'SetPaymentInstrument',
      'RemovePaymentInstrument',
      'setAnalyticsError',
      'setCheckoutStatus'
    ]),
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    getCCBrandIcon(CCBrand = '') {
      CCBrand = CCBrand.toLowerCase()
      let CCBrandIcon = require('@/assets/icons/main/credit-card.svg')
      if (CCBrand === 'visa') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/visa.svg')
      } else if (CCBrand === 'master') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/mastercard.svg')
      } else if (CCBrand === 'elo') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/elo.svg')
      } else if (CCBrand === 'amex') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/amex.svg')
      }

      return CCBrandIcon
    },
    setupPaymentService () {
      if (this.serviceName && this.servicePubKey) {
        let $paymentServices = paymentFactory.getPaymentServices(this.serviceName, '')

        this.paymentService = $paymentServices.default
        paymentFactory.setPubKeys(this.servicePubKey, '')
        return
      }

      var paymentGatewayName = this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.name
      var pubKey = this.CheckoutConfig.paymentInformationConfig.credit_card_config.provider.pubKey

      var paymentFallbackGatewayName = this.CheckoutConfig.paymentInformationConfig.credit_card_config.fallbackProvider.name
      var fallbackPubKey = this.CheckoutConfig.paymentInformationConfig.credit_card_config.fallbackProvider.pubKey

      let $paymentServices = paymentFactory.getPaymentServices(paymentGatewayName, paymentFallbackGatewayName)

      this.paymentService = $paymentServices.default
      this.fallbackPaymentService = $paymentServices.fallback

      paymentFactory.setPubKeys(pubKey, fallbackPubKey)

      // this.paymentService.setPubKey(pubKey)
    },
    IsValidPaymentService (paymentService) {
      return paymentService !== null &&
            paymentService !== undefined &&
            paymentService.pubKey !== null &&
            paymentService.pubKey !== undefined &&
            paymentService.pubKey !== ''
    },
    setActiveInput(input) {
      this.ActiveInput = input
    },
    GetCcBrand (ccNumber = this.CreditCardData.Number) {
      if (!ccNumber || ccNumber.length < 15) {
        return null
      }
      if (ccNumber) {
        var brand = this.paymentService.getCardBrand(ccNumber.toString())

        return brand.toUpperCase()
      }

      return null
    },
    installmentMessage(installment) {
      if (installment.interest_rate !== undefined) {
        return `${installment.installments}x de ${this.formatMoney(
          installment.value
        )}`
      }
    },
    handleInput(e) {
      let $input = e.target
      this.handleInputCombo($input)
    },
    async _handleInputCombo(input) {
      if (!input) {
        return
      }

      let $form = this.$refs['PaymentInformations']
      let inputFormItem = input.closest('.el-form-item')
      let inputGroupedBy = inputFormItem.getAttribute('grouped_by')
      if (inputGroupedBy) {
        let inputGroup = document.querySelector(
          `.el-input-combo[group-name="${inputGroupedBy}"]`
        )
        let inputGroupValid = true
        let fields = $form.fields.filter(
          (field) => field?.$attrs.grouped_by === inputGroupedBy
        )

        if (fields.find((f) => f.validateState === 'validating')) {
          await this.sleep(100)
          this.handleInputCombo(input)
        }

        await this.sleep(100)
        inputGroupValid = fields.every((f) => {
          let valid = f.isRequired && f.validateState === 'success'
          let notErroring = !f.isRequired && f.validateState !== 'error'
          return valid || notErroring
        }, true)

        if (inputGroupValid) {
          inputGroup.classList.remove('is-error')
        } else {
          inputGroup.classList.add('is-error')
        }
      }
    },
    async handleInputCombo(input) {
      if (!input) {
        return
      }
      let inputFormItem = input.closest('.el-form-item')
      let inputGroupedBy = inputFormItem.getAttribute('grouped_by')
      setTimeout(() => {
        if (inputGroupedBy) {
          let inputGroup = document.querySelector(`.el-input-combo[group-name="${inputGroupedBy}"]`)
          let sameGroupFormItems = [...document.querySelectorAll(`.el-form-item[grouped_by="${inputGroupedBy}"]`)]
          let groupHasSomeError = sameGroupFormItems.some(formItem => {
            let formItemHasError = [...formItem.classList].includes('is-error')
            return !!formItemHasError
          })
          if (inputGroup) {
            if (groupHasSomeError) {
              inputGroup.classList.add('is-error')
            } else {
              inputGroup.classList.remove('is-error')
            }
          }
        }
      }, 100)
    },
    handleAmountAdjustedIncreased() {
      this.CreditCardAmountStatus = 'AMOUNT_INCREASED'
    },
    handleAmountAdjustedDecreased() {
      this.CreditCardAmountStatus = 'AMOUNT_DECREASED'
    },
    openCreditCardForm() {
      this.CreditCardFormOpen = true
      this.CreditCardFormStatus = 'IDLE'
    },
    async CalculateLastRemaining() {
      let _this = this
      if (!_this.allow_edit_partial_amount) {
        let $CurrentAmount = this.CreditCardData.Amount
        let $Amount = this.CreditCardData.Amount || 0

        if (_this.amount_remaining > 0) {
          await this.sleep(100)
          this.CreditCardData.Amount = (+$Amount) + (+_this.amount_remaining.toFixed(2))
          if ($CurrentAmount) {
            this.handleAmountAdjustedIncreased()
          }
        } else {
          await this.sleep(100)
          this.CreditCardData.Amount = (+$Amount) + (+_this.amount_remaining.toFixed(2))
          if ($CurrentAmount) {
            this.handleAmountAdjustedDecreased()
          }
        }
      }
    },
    ValidateUserName(rule, value, callback) {
      if (this.onlyTextField(value)) {
        return callback()
      } else {
        return callback(new Error(`Números não são permitidos`))
      }
    },
    ValidateUserDocument(rule, value, callback) {
      if (!this.checkUserDocument(value)) {
        return callback(
          new Error(
            `${
              value.replace(/[^\d]+/g, '').length <= 11 ? 'CPF' : 'CNPJ'
            } inválido`
          )
        )
      } else {
        callback()
      }
    },
    ValidateNumber (rule, value, callback) {
      if (this.CreditCardData.Number) {
        return this.paymentService.validateNumber(
          this.CreditCardData.Number.replace(/\D/g, '')
        )
          ? callback()
          : callback(new Error(`Cartão de crédito inválido`))
      }
      return callback()
    },
    ValidateCVV (rule, value, callback) {
      if (this.CreditCardData.Number && this.CreditCardData.CVV && this.CreditCardData.CVV.length >= 3) {
        return this.paymentService.validateSecurityCode(
          this.CreditCardData.Number,
          this.CreditCardData.CVV
        )
          ? callback()
          : callback(new Error(`CVV inválido`))
      }
      return callback(new Error(`CVV inválido`))
    },
    ValidateCcExpiration (rule, value, callback) {
      if (
        this.CreditCardData.ExpirationMonth &&
        this.CreditCardData.ExpirationYear
      ) {
        return this.paymentService.validateExpirationDate(
          this.CreditCardData.ExpirationMonth,
          this.CreditCardData.ExpirationYear
        )
          ? callback()
          : callback(new Error(`Data inválida`))
      }
      return callback(new Error(`Data inválida`))
    },
    ValidatePartialAmount (rule, value, callback) {
      let $CheckoutTotal = this.Checkout?.totals?.total
      let $PartialAmountValue = value

      if (!$CheckoutTotal) {
        return callback(
          new Error(
            `Valor inválido`
          )
        )
      }

      $CheckoutTotal = +`${$CheckoutTotal}`
      $PartialAmountValue = +`${$PartialAmountValue}`

      if (!$PartialAmountValue || $PartialAmountValue === 0) {
        return callback(
          new Error(
            `Digite um valor a ser processado no cartão`
          )
        )
      }
      if ($PartialAmountValue >= $CheckoutTotal) {
        return callback(
          new Error(
            `Valor precisa ser menor que o total da compra`
          )
        )
      }
      if (!this.Checkout) {
        return false
      }
      if (!this.checkPartialAmount(value, this.Checkout)) {
        return callback(
          new Error(
            `Valor inválido`
          )
        )
      } else {
        callback()
      }
    },
    async ValidatePaymentForm() {
      try {
        let $form = this.$refs['PaymentInformations']
        let $valid = false
        if (!$form) {
          throw new Error('ERROR_FORM_NOT_EXIST')
          // return false
        }
        $form.validate((valid) => {
          $valid = valid
        })
        if (!$valid) {
          this.CreditCardFormOpen = true
          throw new Error('ERROR_FORM_NOT_VALID')
        } else {
          // this.CreditCardFormStatus = 'VALID'
        }
        return $valid
      } catch (e) {
        this.CreditCardFormStatus = e.message
        throw new Error(e.message)
      }
    },
    ValidateField(fieldName) {
      let $form = this.$refs['PaymentInformations']
      let fieldIsValid = false
      $form.validateField(fieldName, (errorMessage) => {
        if (errorMessage) {
          fieldIsValid = false
        } else {
          fieldIsValid = true
        }
      })
      return fieldIsValid
    },
    async CheckCreditCardFormFields() {
      let $form = this.$refs['PaymentInformations']
      if (!$form) {
        return
      }
      let fields = $form.fields

      if (fields.find((f) => f.validateState === 'validating')) {
        await this.sleep(100)
        this.CheckCreditCardFormFields()
      }
      await this.sleep(100)
      this.$data.formValid = fields.every((f) => {
        let valid = f.isRequired && f.validateState === 'success'
        let notErroring = !f.isRequired && f.validateState !== 'error'
        return valid || notErroring
      }, true)

      return this.$data.formValid
    },
    async GenerateCreditCardHash(number, cvc, expirationMonth, expirationYear, fullName, document) {
      if (number && cvc && expirationMonth && expirationYear && fullName && document) {
        try {
          if (!this.IsValidPaymentService(this.paymentService)) {
            this.SetUpPaymentService()
          }

          let paymentHashes = await paymentFactory.getPaymentHashes(
            number,
            cvc,
            expirationMonth,
            expirationYear,
            fullName,
            document
          )

          return paymentHashes
        } catch (error) {
          this.setAnalyticsError({
            id: `CREDIT_CARD_VALIDATION_ERROR`,
            method: 'generateHash',
            error: `createPaymentHash: ${error.message}`,
            fatal: true
          })
          this.setCheckoutStatus({
            PaymentOptions: {
              update: 'INVALID_CREDIT_CARD'
            }
          })
        }
      } else {
        return null
      }
    },
    async doFulfillCreditCard() {
      try {
        if (this.CreditCardFormStatus === 'LOADING') {
          return
        }
        this.CreditCardFormStatus = 'LOADING'

        let ValidatePaymentFormIsValid = await this.ValidatePaymentForm()

        if (!ValidatePaymentFormIsValid) {
          return
        }

        let CreditCardHashes = await this.GenerateCreditCardHash(
          this.CreditCardData.Number,
          this.CreditCardData.CVV,
          this.CreditCardData.ExpirationMonth,
          this.CreditCardData.ExpirationYear,
          this.CreditCardData.FullName,
          this.CreditCardData.Document
        )

        let paymentHashesResponse = {
          default: CreditCardHashes?.default?.hash,
          fallback: CreditCardHashes?.fallback?.hash
        }

        if (CreditCardHashes?.default?.hash === 'eyJjYXJkSGFzaCI6bnVsbCwidmlzaXRvcklkIjoiIn0=') {
          paymentHashesResponse = {
            default: null,
            fallback: null
          }
        }

        const hasValidHash = !!paymentHashesResponse?.default

        if (CreditCardHashes && hasValidHash) {
          this.CreditCardFormStatus = 'FULFILLED'
          this.CreditCardFormOpen = false
          eventBus.$emit('multiple_credit_card.form.fulfilled', {
            id: this.CreditCardFormID,
            installmentId: this.CreditCardData.Installment.id,
            installment_number: this.CreditCardData.Installment.installments,
            installment_value: this.CreditCardData.Installment.value,
            hash: paymentHashesResponse.default,
            hash_fallback: paymentHashesResponse.fallback,
            brand: this.CreditCardData.Brand,
            last_four_numbers: this.CreditCardData.LastFourDigits,
            full_name: this.CreditCardData.FullName,
            document: this.CreditCardData.Document,
            amount: +this.CreditCardData.Amount
          })
        } else {
          this.CreditCardFormStatus = 'NOT_FULFILLED'
          this.CreditCardFormOpen = true
          eventBus.$emit('multiple_credit_card.form.not_fulfilled', {
            id: this.CreditCardFormID
          })
        }
      } catch (error) {

      }
    }
  },
  watch: {
    deep: true,
    Payment: function (payment) {},
    'amount_remaining': {
      deep: true,
      async handler(oldVal, val) {
        if (!val) {

        }
      }
    },
    CreditCardData: {
      deep: true,
      async handler(val) {
        if (!val) {
          return
        }
        let formatExpirationDate = (expirationDate) => {
          if (!expirationDate) {
            return
          }
          let ExpirationDate = expirationDate.split('/')
          if (!ExpirationDate) {
            return
          }
          ExpirationDate = ExpirationDate.filter(date => date)
          return {
            month: ExpirationDate[0],
            year: ExpirationDate[1]
          }
        }
        let $CreditCardNumber = val.Number ? val.Number.replace(/\D/g, '') : null
        let $ExpirationDate = val.ExpirationDate
        let $Amount = val.Amount

        if ($CreditCardNumber) {
          this.CreditCardData.Brand = this.GetCcBrand($CreditCardNumber)
          this.CreditCardData.LastFourDigits = $CreditCardNumber && $CreditCardNumber.length >= 15 ? $CreditCardNumber.slice(12, 16) : null
        }

        if ($ExpirationDate) {
          let $ExpirationDateFormatted = formatExpirationDate($ExpirationDate)

          this.CreditCardData.ExpirationMonth = $ExpirationDateFormatted?.month
          this.CreditCardData.ExpirationYear = $ExpirationDateFormatted?.year
        }

        if ($Amount && $Amount > 0 && !(this.checkPartialAmount($Amount, this.Checkout))) {
          this.CreditCardData.Amount = null
        }
        if ($Amount && this.checkPartialAmount($Amount, this.Checkout)) {
          eventBus.$emit('multiple_credit_card.form.amount.updated', {
            id: this.CreditCardFormID,
            amount: +this.CreditCardData.Amount
          })
        } else {
          eventBus.$emit('multiple_credit_card.form.amount.invalid', {
            id: this.CreditCardFormID
          })
        }

        let CreditCardDataValid = await this.CheckCreditCardFormFields()
        if (CreditCardDataValid) {
          this.ShouldFulfillCreditCard = true
          eventBus.$emit('multiple_credit_card.form.formatted.', {
            form_id: this.CreditCardFormID
          })
        } else {
          this.ShouldFulfillCreditCard = false
          eventBus.$emit('multiple_credit_card.form.not_formatted.', {
            form_id: this.CreditCardFormID
          })
        }
      }
    },

    CreditCardFormOpen: async function (status) {
      if (status === true) {
        this.CalculateLastRemaining()
        await this.sleep(250)
        this.CreditCardData.Installment = this.EligibleInstallments[0]
        eventBus.$emit('multiple_credit_card.form.open', {
          form_id: this.CreditCardFormID,
          index: this.credit_card_index
        })
      } else {
        eventBus.$emit('multiple_credit_card.form.close', {
          form_id: this.CreditCardFormID,
          index: this.credit_card_index
        })
      }
    },
    EligibleInstallments: async function (status) {
      this.CreditCardData.Installment = this.EligibleInstallments[0]
    }

  }
}
</script>

<style>
.tooltip {
  position: relative;
  display: inline-block;
  padding: 0;
  height: auto;
  line-height: 1;
  display: block;
}
.tooltip .el-icon-info {
  top: 0;
  left: 0.3em;
  position: relative;
  font-size: 0.8em;
  display: block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #303133;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 100%;
  margin-left: -100px;
  font-size: 0.9em;
  padding: 0.5em;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #303133 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
