import * as $analyticsPlugin from '../../services/analytics'

let installed = false

const install = Vue => {
  if (installed) {
    return
  }

  Object.defineProperties(Vue.prototype, {
    $analyticsPlugin: {
      get () {
        return $analyticsPlugin
      }
    }
  })

  installed = true
}

export default { install }
