/* eslint-disable object-curly-spacing */
/* eslint-disable no-dupe-class-members */
import { PaymentServices } from './paymentServices'
import { SafraPayApi } from '../../api/safraPayApi'
import { CyberSource } from './AntiFraudServices/CyberSource'
import { dateHelper } from '../../helpers/dateHelper'

class SafraPayCredentials {
  constructor(merchantCredential, merchantToken) {
    this.merchantCredential = merchantCredential
    this.merchantToken = merchantToken
  }
}

export class SafraPayServices extends PaymentServices {
  cardBrand = null

  #maxWaitTimeout = 20
  #credentials = new SafraPayCredentials()
  #safraApi = new SafraPayApi()
  #authToken = ''

  get isAuthenticated() {
    return !!this.#authToken
  }

  async initiateFraudAnalysisToken (antiFraudToken) {
    const router = require('../../router/index').default

    await this.#waitUntilCheckoutIdFulfilled(router)

    var cyberSource = this.#createCyberSourceAntiFraud(antiFraudToken, router)

    cyberSource.executeAntiFraud()
  }

  async setPubKey (pubKey) {
    super.setPubKey(pubKey)

    this.#credentials = this.#createSafraCredentials()

    var authResponse = await this.#safraApi.authenticate(this.#credentials.merchantToken, this.#credentials.merchantCredential)

    this.#authToken = authResponse.data.accessToken
  }

  getCardBrand(cardNumber) {
    var isBusy = false
    var cardBrandErrorMessage = null

    if (!cardNumber) {
      throw new Error('Cartão inválido.')
    }

    if (!this.#credentials.merchantCredential && !this.#credentials.merchantToken) {
      throw new Error('Merchant não autenticado na SafraPay.')
    }

    try {
      const AditumTransparent = require('adt-transparent')
      var success = (body) => {
        isBusy = false
        this.cardBrand = body.brand
      }

      var error = (body) => {
        isBusy = false
        // eslint-disable-next-line no-return-assign
        cardBrandErrorMessage = body.errors.reduce((accumulator, item) => accumulator += item.message, '/n')
      }

      AditumTransparent.getCardBrand({ bin: cardNumber.slice(0, 4), success, error })
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      var waitCount = 0

      // eslint-disable-next-line no-unmodified-loop-condition
      while (isBusy && waitCount < this.#maxWaitTimeout) {
        wait(1000)
        waitCount++
      }
    }

    if (cardBrandErrorMessage) {
      throw new Error(cardBrandErrorMessage)
    }

    return this.cardBrand
  }

  async createPaymentHash(number, cvv, expirationMonth, expirationYear) {
    try {
      var card = {
        brand: this.cardBrand,
        cardNumber: number.replaceAll(' ', ''),
        cardholderName: this.userName,
        cardholderDocument: this.documentNumber,
        expirationMonth: expirationMonth,
        expirationYear: expirationYear,
        cvv: cvv
      }

      var createCardResponse = await this.#safraApi.createTemporaryCard(this.#authToken, card)

      return createCardResponse.data.cardToken
    } catch (error) {
      if (error.response?.data?.errors) {
        // eslint-disable-next-line no-return-assign
        throw new Error(error.response.data.errors.reduce((accumulator, item) => accumulator += item.message, '/n'))
      }
      throw error
    }
  }

  #createSafraCredentials() {
    var pubKeySplitted = this.pubKey.split(':')
    var merchantCredential = pubKeySplitted[0]
    var merchantToken = pubKeySplitted[1]

    return new SafraPayCredentials(merchantCredential, merchantToken)
  }

  #createCyberSourceAntiFraud(antiFraudToken, router) {
    var antiFraudTokenSplitted = antiFraudToken.split(':')
    var orgId = antiFraudTokenSplitted[0]
    var sessionId = antiFraudTokenSplitted[1]

    var cyberSource = new CyberSource(orgId, sessionId, router.currentRoute.params.checkout_id)
    return cyberSource
  }

  async #waitUntilCheckoutIdFulfilled(router) {
    var count = 0
    // eslint-disable-next-line no-unmodified-loop-condition
    while (!router.currentRoute.params.checkout_id && count < 3) {
      await dateHelper.wait(1000)
      count++
    }
  }
}

function wait (time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
