<template>
  <div class='Checkout'>
    <div class="Checkout__Content" v-if="!Checkout && CheckoutStatus.Checkout.get === 'ERROR'">
      <div class="Loading" :class="{'Loading--has-token': Checkout, 'Loading--error': !Checkout}">
        <div class="Loading__Content" v-if="!Checkout">
          <img alt='Vue logo' :src="AppConfig.assets.images.splash" />
          <div>
            <h3>Ops, parece que algo deu errado</h3>
            <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
            <el-button @click="goToShopSupport('API_GET_CHECKOUT_ERROR')" type="primary">Entre em contato com nosso suporte</el-button> <br> ou <br> <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
            <small><em>API_GET_CHECKOUT_ERROR</em></small>
          </div>
        </div>
      </div>
    </div>
    <CheckoutHeader :checkout_id="checkout_id" class="Checkout__Header--Mobile" v-if="Checkout"/>
    <div class="Checkout__Content" v-if="Checkout">
      <CheckoutHeader :checkout_id="checkout_id"/>
      <router-view/>
      <CheckoutFooter/>
    </div>
    <Sidebar class="Checkout__Sidebar"  v-if="Checkout"/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'

import Sidebar from '@/components/Sidebar.vue'
import CheckoutHeader from '@/components/_structure/CheckoutHeader.vue'
import CheckoutFooter from '@/components/_structure/CheckoutFooter.vue'

export default {
  name: 'CheckoutContent',
  components: {
    Sidebar,
    CheckoutHeader,
    CheckoutFooter
    // HelloWorld
  },
  data () {
    return {
    }
  },
  props: ['checkout_id'],
  computed: {
    ...mapGetters(['AppConfig', 'CheckoutStatus', 'Checkout', 'currentStep'])

  },
  created () {
    if (!this.Checkout && this.checkout_id) {
      let CheckoutID = this.Checkout ? this.Checkout.id : this.checkout_id
      this.GetCheckout(CheckoutID)
    }
  },
  methods: {
    ...mapActions(['GetCheckout', 'SetCheckoutState', 'UpdateCheckoutState', 'UpdateCheckout', 'goToShop', 'goToShopSupport']),
    UpdateStep (step) {
      this.currentStep = this.Checkout.activeStep = step
      this.UpdateCheckout(this.Checkout)
    }
  }
}
</script>
