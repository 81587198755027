<template>
  <div class="Checkout__Footer">
    <el-row class="Checkout__Footer--Inner">
      <div class="Checkout__Footer__section">
        <div class="Checkout__Footer__Label">
          Certificados de segurança:
        </div>
        <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/security/shopify.png' />
        </div>
        <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/security/geotrust.png' />
        </div>
        <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/security/pci.png' />
        </div>
      </div>
      <div class="Checkout__Footer__section">
        <div class="Checkout__Footer__Label">
          Meios de pagamento:
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Visa.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Master.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/ELO.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Hiper.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Diners.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Hipercard.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('credit_card')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/AMEX.png' />
        </div>
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('pix')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/PIX.png' />
        </div>
        <!-- <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Itaú.png' />
        </div> -->
        <!-- <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Bradesco.png' />
        </div> -->
        <!-- <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Banco do Brasil.png' />
        </div> -->
        <!-- <div class="Checkout__Footer__Logo">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Banrisul.png' />
        </div> -->
        <div class="Checkout__Footer__Logo" v-if="AppConfig.payment_informations.payment_methods.includes('bankslip')">
          <img :alt="AppConfig.name" src='@/assets/icons/paymentOptions/Boleto.png' />
        </div>

      </div>
    </el-row>
    <el-row class="Checkout__Footer--Inner">
      <div class="Checkout__Footer__section">
        <el-link type="primary" @click="privacyModal = true" class="Checkout__Footer__Label" v-if="CheckoutConfig && CheckoutConfig.generalConfig.privacy_policy">
          Política de privacidade
        </el-link>
      </div>
    </el-row>

    <el-dialog
      title="Política de privacidade"
      :visible.sync="privacyModal"
      v-if="CheckoutConfig && CheckoutConfig.generalConfig.privacy_policy"
      >
      <span>
        {{CheckoutConfig.generalConfig.privacy_policy}}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="privacyModal = false">Li e aceito as politicas de privacidade</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import { mapGetters, mapActions } from 'vuex'
import { mapGetters } from 'vuex'
export default {
  name: 'CheckoutFooter',
  components: {

  },
  data () {
    return {
      privacyModal: false
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutConfig'])
  },
  created () {
  },
  methods: {
  }
}
</script>
