<template>
  <ul class="Sidebar__ProductList ProductList">
    <li
      v-for="(product, index) in products"
      :key="(index)"
      class="ProductList__Item"
      :data-product-id="product.product_id"
      :data-variant-id="product.variant_id"
      :data-product-title="product.title"
      data-customer-ready-visible
    >
      <div class="ProductList__Item__image">
        <div class="ProductList__Item-thumbnail">
          <div class="ProductList__Item-thumbnail__wrapper">
            <img
              :alt="product.title"
              class="ProductList__Item-thumbnail__image"
              :src="product.image"
            />
          </div>
          <span
            class="ProductList__Item-thumbnail__quantity"
            aria-hidden="true"
          >{{product.quantity}}</span>
        </div>
      </div>
      <div class="ProductList__Item__description">
        <span class="ProductList__Item__description__name ProductList__Item__description--emphasis-text">{{product.title}}</span>
        <span class="ProductList__Item__description__variant ProductList__Item__description--small-text" v-if="product.variant_title">{{product.variant_title.replace(/Default Title/g, '')}}</span>
        <b class="ProductList__Item__description__discount" v-for="(productDiscount, index) in productDiscounts(product)" :key="(index)">
          <icon
            :src="require('../../../src/assets/icons/main/price-tag.svg')"
            width="12"
            :aria-label="productDiscount.discount_code"
          ></icon>
          {{productDiscount.discount_code}}
        </b>
        <span class="ProductList__Item__description__name ProductList__Item__description--small-text" v-if="method && method.estimate_business_days && false">
          {{ GetInventoryMessage(product.variant_id, method) }}
        </span>
        <QuantitySelector v-if="QuantitySelectorEnabled && product.price > 0" :variant="product.variant_id" :quantity="product.quantity" />
      </div>
      <div class="ProductList__Item__price">
        <span class="ProductList__Item__description--muted-text" v-if="product.original_price > product.price">{{formatMoney((product.original_price))}}</span>
        <span class="ProductList__Item__description--emphasis-text moneyFormat">{{product.price > 0 ? formatMoney(product.price) : 'Grátis'}}</span>
      </div>
    </li>
  </ul>
</template>

<script>
// @ is an alias to /src
import { mapFilters } from '@/helpers/filters'
import { mapGetters, mapActions } from 'vuex'
import Icon from 'vue-inline-svg'
import productListMixin from '@/_mixins/productList'
import QuantitySelector from '@/components/_sidebar/QuantitySelector'

export default {
  name: 'ProductList',
  components: {
    Icon,
    QuantitySelector
  },
  props: ['products', 'method'],
  mixins: [productListMixin],
  computed: {
    ...mapGetters(['AppConfig', 'InventoryIncomings', 'CheckoutConfig', 'Checkout', 'ShippingMethods']),
    products_count () {
      return this.products.reduce((curr, old) => {
        return old.quantity + curr
      }, 0)
    },
    QuantitySelectorEnabled () {
      return this.AppConfig.components.resume.settings.show_quantity_selector && this.Checkout.activeStep !== 10 && this.products_count > 1
    }
  },
  methods: {
    ...mapFilters(['formatMoney', 'dateFormat']),
    ...mapActions(['GetInventoryIncomings']),
    productDiscounts (product) {
      return product.discounts.filter(discount => discount.amount > 0)
    }
  }
}
</script>
