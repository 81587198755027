<template>

    <div class="Order__Section">
        <h2 class="Checkout__Section__Title">Dados da compra:</h2>
        <el-row :gutter="20" class="Order_Review">
          <el-col :span="24">
            <el-card class="box-card Checkout__Overview--card">
              <div class="el-card__list--item">
                <h6>Método de pagamento:</h6>
                <span>
                  <div v-if="order.payment_info.type !== 'TWO_CARDS_PAYMENT'">
                    {{getPaymentType(order.payment_info.type)}} - {{`${order.payment_info.installments}x de ${formatMoney(order.payment_info.installment_value)}`}} {{`${order.payment_info.interest_rate > 0 ? `(Juros de ${order.payment_info.interest_rate}%)` : ``}`}}
                    <br>
                    <br>
                    <span v-if="order.payment_info.type === 'CREDIT_CARD'">
                      {{order.payment_info.brand}}
                      (•••• •••• •••• {{order.payment_info.last_four_numbers}})
                    </span>
                  </div>

                  <el-row :gutter="20" v-if="order.payment_info.type === 'TWO_CARDS_PAYMENT' && order.payment_instruments.length">
                    <div class="Checkout__Overview__payment-instruments">
                      <el-col :span="24">
                        <span class="Checkout__Overview__payment-type">
                          {{getPaymentType(order.payment_info.type)}} <br>
                        </span>
                      </el-col>
                      <el-col class="Checkout__Overview__payment-instrument" :span="24" v-for="(payment_instrument, index) in order.payment_instruments" :key="index">
                        <b class="Checkout__Overview__payment-instrument__label">Cobrança {{ index + 1 }}</b>
                        <div class="Checkout__Overview__payment-instrument__credit-card__number">
                          <icon
                            :src="getCCBrandIcon(payment_instrument.brand)"
                            width="20"
                            class=""
                          ></icon>
                          <b>•••• •••• •••• {{ payment_instrument.last_four_numbers }}</b>
                        </div>
                        <div class="Checkout__Overview__payment-instrument__infos">
                          {{`${payment_instrument.full_name} - ${payment_instrument.document}`}}<br>
                          {{`${payment_instrument.installment_count}x de ${formatMoney(payment_instrument.amount)}`}}
                        </div>
                      </el-col>
                    </div>
                  </el-row>
                  <el-row :gutter="20" v-if="order.payment_info.type === 'BOLETO'">
                    <div v-if="CheckoutConfig.paymentInformationConfig.provider.name !== 'PagBrasil'">
                      <el-col :span="24">
                          <el-input
                              placeholder="Email"
                              ref="order.payment_info.bankslip_line_code"
                              v-model="order.payment_info.bankslip_line_code"
                              id="copy"
                          ></el-input>
                          <br>
                          <br>
                      </el-col>
                      <el-col :span="12">
                          <el-button
                          :type="isBankslipCodeCopied?`success`:`primary`"
                          class="el-button--block Checkout__Button--primary"
                          @click="copyToClipboard()"
                          >
                          <span v-if="isBankslipCodeCopied">
                              <i class="el-icon-check"></i>
                              Código copiado
                          </span>
                          <span v-else>
                              <i class="el-icon-document-copy"></i>
                              Copiar Código
                          </span>
                          </el-button>
                      </el-col>
                      <el-col :span="12">
                          <el-button
                          :type="isBankslipDownloaded?`success`:`primary`"

                          class="el-button--block Checkout__Button--primary"
                          @click="downloadBankSlip(order.payment_info.bankslip_url)"
                          >
                          <span v-if="isBankslipDownloaded == false">
                              <i class="el-icon-download"></i>
                              {{
                                AppConfig.translations.thankyou_page.bankslip_download_button_title ?
                                AppConfig.translations.thankyou_page.bankslip_download_button_title :
                                "Baixar Boleto"
                              }}
                          </span>
                          <span v-else>
                              <i class="el-icon-check"></i>
                              {{
                                AppConfig.translations.thankyou_page.bankslip_download_button_title ?
                                AppConfig.translations.thankyou_page.bankslip_download_button_title :
                                "Baixar Boleto"
                              }}
                          </span>
                          </el-button>
                      </el-col>
                    </div>
                  </el-row>
                </span>
              </div>
              <div class="el-card__list--item  Checkout__Overview--shipping-address">
                <h6>{{  AppConfig.translations.labels && AppConfig.translations.labels.shipping_info_title ?
                        AppConfig.translations.labels.shipping_info_title :
                        "Dados de Entrega:"
                    }}
                </h6>
                <span>
                  <!-- <span>{{order.customer.full_name}} - {{order.customer.document}} - {{order.customer.phone}}</span> -->
                  <!-- <br /> -->
                  <span class="Checkout__Overview--address">
                    <span v-if="order.shipping_info.person && order.shipping_info.person.first_name">
                      <b>
                      {{
                        AppConfig.translations.contact_info_preview &&
                        AppConfig.translations.contact_info_preview.shipping_address.recipient_name_title  ?
                        AppConfig.translations.contact_info_preview.shipping_address.recipient_name_title :
                        "Nome do destinatário:"
                      }}</b>
                      {{order.shipping_info.person.first_name}} {{order.shipping_info.person.last_name}}
                      <br>
                    </span>
                    <b>Endereço:</b>
                    <span>{{order.shipping_info.address.street}}, {{order.shipping_info.address.street_number}}</span>
                    <span>{{order.shipping_info.address.complement}}</span>
                    <span>{{order.shipping_info.address.neighborhood}}</span>
                    <span>{{order.shipping_info.address.city}}</span>
                    <span>{{order.shipping_info.address.state}}</span>
                    <span>CEP: {{order.shipping_info.address.zip_code}}</span>
                  </span>
                </span>
              </div>
              <GiftMessagePreview v-if="AppConfig.custom_attributes.gift_message_from && AppConfig.custom_attributes.gift_message_from.enabled && AppConfig.custom_attributes.gift_message_to && AppConfig.custom_attributes.gift_message_to.enabled && AppConfig.custom_attributes.gift_message_content && AppConfig.custom_attributes.gift_message_content.enabled" :edit="false" />
              <div class="el-card__list--item Checkout__Overview--billing-address" v-if="AppConfig.payment_informations.billing_info_form_enabled">
                <h6>Endereço de cobrança:</h6>
                <span>
                  <!-- <span>{{order.customer.full_name}} - {{order.customer.document}} - {{order.customer.phone}}</span>
                  <br /> -->
                  <span class="Checkout__Overview--address">
                    <span v-if="order.billing_info.person && order.billing_info.person.first_name">
                      <b>Nome do destinatário:</b>
                      {{order.billing_info.person.first_name}} {{order.billing_info.person.last_name}}
                      <br>
                    </span>
                    <span>{{order.billing_info.address.street}}, {{order.billing_info.address.street_number}}</span>
                    <span>{{order.billing_info.address.complement}}</span>
                    <span>{{order.billing_info.address.neighborhood}}</span>
                    <span>{{order.billing_info.address.city}}</span>
                    <span>{{order.billing_info.address.state}}</span>
                    <span>CEP: {{order.billing_info.address.zip_code}}</span>
                  </span>
                </span>
              </div>
              <div class="el-card__list--item" v-if="Checkout.requires_shipping">
                <h6>Método de envio:</h6>
                <span>{{ActiveShippingMethod.title}} - {{`${formatMoney(ActiveShippingMethod.shipping_price)}`}}</span>
                <ProductResume
                  :products="order.products"
                  :method="ActiveShippingMethod"
                  :show-shipping-price="false"
                  v-if="ActiveShippingMethod"
                />
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'

import ProductResume from '@/components/_shippingMethods/ProductResume.vue'
import GiftMessagePreview from '@/components/_general/GiftMessagePreview.vue'

import Icon from 'vue-inline-svg'
// import { BankSlipService } from '../../services/bankSlipService'
export default {
  name: 'OrderInfos',
  components: {
    Icon,
    ProductResume,
    GiftMessagePreview
  },
  data () {
    return {
      isBankslipCodeCopied: false,
      isBankslipDownloaded: false
    }
  },
  props: ['order'],
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutConfig', 'ActiveShippingMethod', 'ActivePaymentMethod', 'PaymentOptions'])
  },
  created () {
    this.Checkout.chosen_delivery_option.shipping_price = this.Checkout.totals.shipping
    this.SetCheckoutShippingMethod(this.Checkout.chosen_delivery_option)
  },
  methods: {
    ...mapFilters(['formatMoney', 'formatDocument']),
    ...mapActions([
      'SetCheckoutShippingMethod',
      'SetActivePaymentMethod',
      '_buildSuccessAlert'
    ]),

    getPaymentType($paymentInfo = this?.order?.payment_info?.type) {
      if ($paymentInfo === 'TWO_CARDS_PAYMENT') {
        $paymentInfo = 'Dois Cartões de crédito'
      } else if ($paymentInfo === 'CREDIT_CARD') {
        $paymentInfo = 'Cartão de crédito'
      } else if ($paymentInfo === 'BOLETO') {
        $paymentInfo = 'Boleto Bancário'
      } else if ($paymentInfo === 'PIX') {
        $paymentInfo = 'PIX'
      }
      return $paymentInfo
    },
    getCCBrandIcon(CCBrand = '') {
      let CCBrandIcon = require('@/assets/icons/main/credit-card.svg')
      if (CCBrand === 'VISA') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/visa.svg')
      } else if (CCBrand === 'MASTER') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/mastercard.svg')
      } else if (CCBrand === 'ELO') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/elo.svg')
      } else if (CCBrand === 'AMEX') {
        CCBrandIcon = require('@/assets/icons/paymentOptions/_original/amex.svg')
      }
      return CCBrandIcon
    },

    copyToClipboard () {
      var bankslipCode = document.getElementById('copy')
      bankslipCode.select()
      document.execCommand('copy', false)
      this.isBankslipCodeCopied = true
      setTimeout(() => {
        this.isBankslipCodeCopied = false
      }, 3000)
      this._buildSuccessAlert({
        title: 'Código copiado',
        success: ''
      })
    },
    async downloadBankSlip (url) {
      window.open(url, '_blank')
      // let bankSlipService = new BankSlipService('bankSlipDownload')
      // bankSlipService.downloadBankSlip(url).then(() => {
      //   this.isBankslipDownloaded = true
      //   this._buildSuccessAlert({
      //     title: 'Boleto Baixado!',
      //     success: ''
      //   })
      // })
    }
  },
  watch: {
    'PaymentOptions': {
      deep: true,
      handler (val) {
        if (this.Checkout) {
          var activePaymentMethod = {
            installments: this.Checkout.payment_info.installments,
            interest_rate: this.Checkout.payment_info.interest_rate,
            total_in_installments: this.Checkout.payment_info.total_in_installments,
            value: this.Checkout.payment_info.installment_value
          }
          this.SetActivePaymentMethod(activePaymentMethod)
        }
      }
    }
  }

}
</script>
