var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Checkout__Section"},[(_vm.title)?_c('h2',{staticClass:"Checkout__Section__Title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.subtitle)?_c('p',{staticClass:"Checkout__Section__Subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),_c('el-form',{ref:"GiftMessageWidget",staticClass:"Checkout__Form--Custom Form--Custom",attrs:{"model":_vm.gift_message}},[_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"span":12}},[_c('div',{staticClass:"el-form-outer"},[_c('el-form-item',{ref:"gift_message.enabled",staticClass:"el-form-item--radio",class:{'el-form-item--active': _vm.activeInput === 'gift_message--enabled'},attrs:{"prop":"enabled","rules":{
                          required: true, message: 'Escolha uma opção', trigger: 'change'
                      }}},[_c('el-radio-group',{ref:"gift_message.enabled",attrs:{"inline":""},model:{value:(_vm.gift_message.enabled),callback:function ($$v) {_vm.$set(_vm.gift_message, "enabled", $$v)},expression:"gift_message.enabled"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v("Sim")]),_c('el-radio',{attrs:{"label":false}},[_vm._v("Não")])],1)],1)],1)])],1),(_vm.gift_message.enabled)?_c('el-row',{attrs:{"gutter":10}},[_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"span":12}},[_c('div',{staticClass:"el-form-outer"},[_c('el-form-item',{ref:"gift_message.from",class:{'el-form-item--active': _vm.activeInput === 'gift_message--from'},attrs:{"label":_vm.AppConfig.translations.forms.gift_message.from.label,"prop":"from","rules":{
                        required: true, message: 'Escreva o nome de quem está enviando este cartão', trigger: 'change'
                    }}},[_c('el-input',{ref:"gift_message.from",attrs:{"placeholder":_vm.AppConfig.translations.forms.gift_message.from.placeholder,"name":"address-level2","autocomplete":"address-level2"},on:{"focus":function($event){return _vm.setActiveInput('gift_message--from')},"blur":function($event){return _vm.setActiveInput(null)}},model:{value:(_vm.gift_message.from),callback:function ($$v) {_vm.$set(_vm.gift_message, "from", $$v)},expression:"gift_message.from"}})],1)],1)]),_c('el-col',{attrs:{"xs":24,"sm":12,"md":12,"span":12}},[_c('div',{staticClass:"el-form-outer"},[_c('el-form-item',{ref:"gift_message.to",class:{'el-form-item--active': _vm.activeInput === 'gift_message--to'},attrs:{"label":_vm.AppConfig.translations.forms.gift_message.to.label,"prop":"to","rules":{
                        required: true, message: 'Escreva o nome de quem receberá este cartão', trigger: 'change'
                    }}},[_c('el-input',{ref:"gift_message.to",attrs:{"placeholder":_vm.AppConfig.translations.forms.gift_message.to.placeholder,"name":"address-level2","autocomplete":"address-level2"},on:{"focus":function($event){return _vm.setActiveInput('gift_message--to')},"blur":function($event){return _vm.setActiveInput(null)}},model:{value:(_vm.gift_message.to),callback:function ($$v) {_vm.$set(_vm.gift_message, "to", $$v)},expression:"gift_message.to"}})],1)],1)]),_c('el-col',{attrs:{"xs":24,"sm":24,"md":24,"span":24}},[_c('div',{staticClass:"el-form-outer"},[_c('el-form-item',{ref:"gift_message.content",class:{'el-form-item--active': _vm.activeInput === 'gift_message--content'},attrs:{"label":_vm.AppConfig.translations.forms.gift_message.content.label,"prop":"content","rules":{
                        required: true, message: 'Escreva uma mensagem personalizada para ir no cartão', trigger: 'change'
                    }}},[_c('el-input',{ref:"gift_message.content",attrs:{"placeholder":_vm.AppConfig.translations.forms.gift_message.content.placeholder,"type":"textarea","maxlength":"140","show-word-limit":"","name":"address-level2","autocomplete":"address-level2"},on:{"focus":function($event){return _vm.setActiveInput('gift_message--content')},"blur":function($event){return _vm.setActiveInput(null)}},model:{value:(_vm.gift_message.content),callback:function ($$v) {_vm.$set(_vm.gift_message, "content", $$v)},expression:"gift_message.content"}})],1)],1)])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }