<template>
  <div class="Arrival">
    <div class="Loading" :class="'Loading--error'">
      <div class="Loading__Content" v-if="CheckoutStatus.Auth.authorize === 'LOADING'">
        <img alt="Vue logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Carregando....</h3>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Auth.authorize === 'ERROR'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('AUTHORIZATION_FAILED')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <small>
            <em>AUTHORIZATION_FAILED</em>
          </small>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.create === 'LOADING'">
        <img alt="Vue logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Aguarde, estamos montando seu carrinho</h3>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.create === 'CART_EMPTY'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('CART_EMPTY')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <small>
            <em>CART_EMPTY</em>
          </small>
        </div>
      </div>
      <div class="Loading__Content" v-if="CheckoutStatus.Checkout.create === 'ERROR'">
        <img alt="Checkout logo" :src="AppConfig.assets.images.splash" ref="ErrorAlertLoaderImage"/>
        <div ref="ErrorAlertLoaderDescription">
          <h3>Ops, parece que algo deu errado</h3>
          <p>Ocorreu um erro ao recuperar suas informações de carrinho</p>
          <el-button @click="goToShopSupport('API_CREATE_CHECKOUT_ERROR')" type="primary">Entre em contato com nosso suporte</el-button>
          <br />ou
          <br />
          <el-button @click="goToShop()" type="text">Volte para a loja e tente novamente</el-button>
          <br />
          <small>
            <em>API_CREATE_CHECKOUT_ERROR</em>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Animation from '@/services/animation'
import RouterService from '@/services/routerService'
import { Base64Helper, cookieHelper } from '../../helpers'

export default {
  name: 'Arrival',
  components: {},
  data () {
    return {
      CartOBJ: null
    }
  },
  props: ['cart_token'],
  computed: {
    ...mapGetters(['AppConfig', 'Cart', 'CheckoutStatus', 'Checkout', 'GetCredentials', 'CheckoutConfig'])
  },
  async beforeMount() {
    await this.Authenticate(this.GetCredentials)
    await this.GetCheckoutConfig()
    await this.checkoutCreate()
  },
  mounted () {
    const { ErrorAlertLoaderImage, ErrorAlertLoaderDescription } = this.$refs
    const timeline = Animation.timeline
    timeline
      .from(ErrorAlertLoaderImage, { duration: 0.5, y: -5, opacity: 0, scale: 2, ease: 'back.inOut(1.7)' })
      .from(ErrorAlertLoaderDescription, { duration: 0.5, y: -5, opacity: 0, ease: 'back.inOut(1.7)' }, '-=.25')
  },
  methods: {
    ...mapActions(['Authenticate', 'SetCart', 'CreateCheckout', 'CreateCheckoutWithCustomer', 'GetCheckoutConfig', 'goToShop', 'goToShopSupport']),
    goTo (url) {
      RouterService.goTo(url)
    },
    checkoutCreate () {
      const enableLogin = this.CheckoutConfig.generalConfig.enable_login

      if (localStorage.getItem('login') === 'false' ||
        !enableLogin) {
        this.CreateCheckout(this.cart_token)

        return
      }

      let userBase64 = cookieHelper.getCookie('minicart_u')

      if (userBase64) {
        const base64 = new Base64Helper()
        const user = base64.decode(userBase64)

        if (user) {
          let loggedCustomer = JSON.parse(user)
          this.CreateCheckoutWithCustomer({ cartToken: this.cart_token, customer: loggedCustomer })
          localStorage.setItem('customer_id', loggedCustomer.id)
        }
      } else {
        this.CreateCheckout(this.cart_token)
      }
    }
  },
  watch: {
    Checkout (val) {
      if (val.id) {
        let CheckoutID = val ? val.id : this.$route.params.checkout_id
        this.goTo('/Checkout/' + CheckoutID)
      } else {
        this.goTo('/Arrival')
      }
    },
    deep: true
  }
}
</script>
