<template>
  <div>
    <el-card
      class="ShippingMethod__Resume"
      v-for="(unavailableItem, index) in unavailableItems"
      :key="(index)"
    >
    <ProductList :unavailableItem="unavailableItem" />
    <div v-if="unavailableItem.available_quantity > 1" class="ShippingMethod__Resume__Price"><h4>{{ unavailableItem.available_quantity }} disponíveis </h4> </div>
    <div v-if="unavailableItem.available_quantity === 1 " class="ShippingMethod__Resume__Price"><h4>{{ unavailableItem.available_quantity }} disponível </h4> </div>
    <div v-if="unavailableItem.available_quantity < 1" class="ShippingMethod__Resume__Price"><i class="el-icon-error"></i> <h4>Indisponível </h4> </div>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductList from './ProductList.vue'

export default {
  name: 'ProductResume',
  components: {
    ProductList
  },
  data () {
    return {}
  },
  computed: {},
  props: ['unavailableItems']
}
</script>
