<template>
    <div>
        <el-alert
            class="loyalty_optin"
            title="Bem-vindo ao programa de fidelidade Inspire 360, aqui suas compras acumulam pontos e seus pontos valem novos produtos!"
            type="success"
            >
            Confira no site <a href='http://inspire360.com.br'>inspire360.com.br</a>
        </el-alert>
    </div>
</template>

<script>
export default {
  name: 'LoyaltyProgramConfirmation'
}
</script>

<style>
.el-alert--success.is-light {
    background-color: #e494ff !important;
}
.loyalty_optin .el-alert__title {
    color: #8c00bb !important;
}
.loyalty_optin .el-alert__description    {
    color: #8c00bb !important;
}
.loyalty_optin .el-alert__closebtn    {
    color: #8c00bb !important;
}
</style>
