
export class MaxiPagoCard {
  constructor(
    customerId,
    creditCardNumber,
    expirationMonth,
    expirationYear,
    billingName,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    billingCountry,
    billingPhone,
    billingEmail,
    onFilePermissions,
    onFileMaxChargeAmount
  ) {
    this.customerId = customerId
    this.creditCardNumber = creditCardNumber
    this.expirationMonth = expirationMonth
    this.expirationYear = expirationYear
    this.billingName = billingName
    this.billingAddress1 = billingAddress1
    this.billingAddress2 = billingAddress2
    this.billingCity = billingCity
    this.billingState = billingState
    this.billingZip = billingZip
    this.billingCountry = billingCountry
    this.billingPhone = billingPhone
    this.billingEmail = billingEmail
    this.onFilePermissions = onFilePermissions
    this.onFileMaxChargeAmount = onFileMaxChargeAmount
  }
}
