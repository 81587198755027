// import API from '@/api'

const state = {
  Cart: null
}
const getters = {
  Cart: function (state) {
    return state.Cart
  }
}
const actions = {
  async SetCart ({ commit }, value) {
    commit('SetCart', value)
  }
}
const mutations = {
  SetCart: function (state, Cart) {
    state.Cart = (Cart)
  },
  UpdateCart: function (state, Data) {
    state.Cart = {
      ...state.Cart,
      Data
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
