<template>
  <el-form
    ref="PaymentInformations" :model="paymentData" :rules="rules"
    class="PaymentMethod__Form--Custom Form--Custom"
  >
    <div id="adyenPayment-container" class="PaymentMethod__Form--Adyen">
      <el-row :gutter="20">
        <el-col :span="24" :sm="12">
          <div class="el-form-outer">
            <el-form-item
              prop="full_name"
              :label="`Nome impresso no cartão`"
              :class="{
                'el-form-item--active':
                  activeInput === `Nome impresso no cartão`,
              }"
            >
              <el-input
                :placeholder="``"
                name="cc-name"
                autocomplete="cc-name"
                @focus="setActiveInput(`Nome impresso no cartão`)"
                @blur="setActiveInput('')"
                v-model="paymentData.full_name"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24" :sm="12">
          <div class="el-form-outer">
            <el-form-item
              prop="document"
              :label="`CPF/CNPJ do titular do cartão`"
              v-facade="['###.###.###-##', '##.###.###/####-##']"
              :class="{
                'el-form-item--active':
                  activeInput === `CPF/CNPJ do titular do cartão`,
              }"
            >
              <el-input
                :placeholder="``"
                pattern="\d*" inputmode="numeric"
                @focus="setActiveInput(`CPF/CNPJ do titular do cartão`)"
                @blur="setActiveInput('')"
                v-model="paymentData.document"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="el-form-outer">
            <label>
                <el-form-item
                  prop="cc_number_adyen"
                  :label="`Número do cartão de crédito`"
                  :class="{'el-form-item--active': activeInput === `Número do cartão de crédito`}"
                  v-bind:data-cc-brand="CCBrandName"
                >
                  <el-input
                    v-model="encryptedCardNumberIsValid"
                    style="height: 0px; width: 0px; visibility: hidden;"
                  ></el-input>
                  <span id="encryptedCardNumber" data-cse="encryptedCardNumber"></span>
                </el-form-item>
            </label>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="el-form-outer">
            <label>
              <el-form-item
                  prop="cc_cvv_adyen"
                  :label="`CVV`"
                  :class="{'el-form-item--active': activeInput === `CVV`}"
                >
                  <el-input
                    v-model="encryptedSecurityCodeIsValid"
                    style="height: 0px; width: 0px; visibility: hidden;"
                  ></el-input>
                  <span id="encryptedSecurityCode" data-cse="encryptedSecurityCode"></span>
                </el-form-item>
            </label>
          </div>
        </el-col>
        <el-col :span="12">
            <div class="el-form-outer">
              <label>
                <el-form-item
                    prop="cc_expiration_date_adyen"
                    :label="`Data de validade`"
                    :class="{'el-form-item--active': activeInput === `Data de validade`}"
                  >
                    <el-input
                      v-model="encryptedExpiryDateIsValid"
                      style="height: 0px; width: 0px; visibility: hidden;"
                    ></el-input>
                  <span id="encryptedExpiryDate" data-cse="encryptedExpiryDate"></span>
                </el-form-item>
              </label>
            </div>
        </el-col>
        <el-col :span="24">
          <div class="el-form-outer">
            <el-form-item
              prop="cc_installments"
              :label="`Número de parcelas`"
              :class="{
                'el-form-item--active': activeInput === `Número de parcelas`,
              }"
            >
              <el-select
                v-model="paymentData.cc_installments"
                :placeholder="`Selecione`"
                @focus="setActiveInput(`Número de parcelas`)"
                @blur="setActiveInput('')"
              >
                <el-option
                  v-for="installment in EligibleInstallments"
                  :key="installment.installments"
                  :label="installmentMessage(installment)"
                  :value="installment"
                >
                  <span style>{{ installmentMessage(installment) }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'

var securedData = {}
let ccForm = null

export default {
  name: 'AdyenForm',
  data () {
    return {
      customCard: null,
      activeInput: '',
      installmentSelected: '',
      encryptedCardNumberIsValid: null,
      encryptedSecurityCodeIsValid: null,
      encryptedExpiryDateIsValid: null,
      paymentData: {
        full_name: null,
        document: null,
        cc_number_adyen: null,
        cc_cvv_adyen: null,
        cc_expiration_date_adyen: null,
        cc_brand: '',
        cc_last_4: '',
        cc_hash: null,
        cc_installments: null
      },
      rules: {
        full_name: [
          {
            required: true,
            message: 'Digite o nome impresso no cartão',
            trigger: 'change'
          },
          { validator: this.ValidateUserName, trigger: 'change' }
        ],
        document: [
          {
            required: true,
            message: `Digite seu CPF OU CNPJ`,
            trigger: 'change'
          },
          { validator: this.ValidateUserDocument, trigger: 'change' }
        ],
        'cc_number_adyen': [
          { validator: this.ValidateCardNumberAdyen }
        ],
        'cc_cvv_adyen': [
          { validator: this.ValidateCVVAdyen }
        ],
        'cc_expiration_date_adyen': [
          { validator: this.ValidateCardDateAdyen }
        ],
        cc_installments: [
          {
            required: true,
            message: 'Selecione suas parcelas',
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'CheckoutConfig']),
    EligibleInstallments () {
      let minimumInstallment = this.CheckoutConfig.paymentInformationConfig.credit_card_config.minimum_installment_value
      return this.method.installments.filter(_i => {
        return _i.installments === 1 || _i.value >= minimumInstallment
      })
    },
    CCBrandName () {
      switch (this.paymentData.cc_brand) {
        case 'amex':
          return 'Amex'
        case 'argencard':
          return 'Argencard'
        case 'bcmc':
          return 'Bancontact/Mister Cash'
        case 'bijcard':
          return 'de Bijenkorf Card'
        case 'cabal':
          return 'Cabal'
        case 'cartebancaire':
          return 'Carte Bancaires'
        case 'codensa':
          return 'Codensa'
        case 'cup':
          return 'China Union Pay'
        case 'dankort':
          return 'Dankort'
        case 'diners':
          return 'Diners Club'
        case 'discover':
          return 'Discover'
        case 'electron':
          return 'Electron'
        case 'elo':
          return 'ELO'
        case 'forbrugsforeningen':
          return 'Forbrugsforeningen'
        case 'hiper':
          return 'Hiper'
        case 'hipercard':
          return 'HiperCard'
        case 'jcb':
          return 'JCB'
        case 'karenmillen':
          return 'Karen Millen GiftCard'
        case 'laser':
          return 'Laser'
        case 'maestro':
          return 'Maestro'
        case 'maestrouk':
          return 'Maestro UK'
        case 'mc':
          return 'Mastercard'
        case 'mcalphabankbonus':
          return 'Alpha Bank Mastercard Bonus'
        case 'mir':
          return 'MIR'
        case 'naranja':
          return 'Naranja'
        case 'oasis':
          return 'Oasis GiftCard'
        case 'rupay':
          return 'RuPay'
        case 'shopping':
          return 'Tarjeta Shopping'
        case 'solo':
          return 'Solo'
        case 'troy':
          return 'Troy'
        case 'uatp':
          return 'UATP'
        case 'visa':
          return 'Visa'
        case 'visaalphabankbonus':
          return 'Alpha Bank Visa Bonus'
        case 'visadankort':
          return 'Visa Dankort'
        case 'warehouse':
          return 'Warehouse GiftCard'
        default:
          return null
      }
      // return this.paymentData.cc_brand
    }
  },
  props: ['method'],
  created () {
    if (this.EligibleInstallments.length === 1) {
      this.paymentData.cc_installments = this.EligibleInstallments[0]
    }
  },
  mounted () {
    this.initCreditCardForm()
  },
  beforeDestroy () {
    this.destroyCreditCardForm()
  },
  methods: {
    ...mapFilters(['formatMoney', 'checkUserDocument', 'onlyTextField']),
    ...mapActions([
      'UpdatePaymentState',
      'SetPaymentMethod',
      'setCheckoutStatus'
    ]),
    getForm () {
      console.log(`ccForm`, ccForm)
    },
    async initCreditCardForm () {
      var $this = this
      try {
        const configuration = {
          clientKey: $this.CheckoutConfig.paymentInformationConfig
            .credit_card_config.provider.pubKey,
          locale: 'pt_BR',
          environment: process.env.VUE_APP_ADYEN_ENVIRONMENT,
          onError: (state, component) => {
            $this.ValidateFieldWithError(state.fieldType)
          },
          onChange: (state, component) => {
            if (state.isValid) {
              securedData.encryptedCardNumber =
                state.data.paymentMethod.encryptedCardNumber
              securedData.encryptedSecurityCode =
                state.data.paymentMethod.encryptedSecurityCode
              securedData.encryptedExpiryMonth =
                state.data.paymentMethod.encryptedExpiryMonth
              securedData.encryptedExpiryYear =
                state.data.paymentMethod.encryptedExpiryYear

              sessionStorage.setItem('device_hash', btoa(JSON.stringify({
                riskId: state.data.riskData.clientData,
                browserInfo: state.data.browserInfo
              })))
              $this.paymentData.cc_hash = {
                successfullyCreated: true,
                hash: btoa(JSON.stringify(securedData))
              }
            }
          }
        }

        const checkout = new AdyenCheckout(configuration)

        ccForm = checkout
          .create('securedfields', {
            type: 'card',
            brands: [
              'amex',
              'diners',
              'discover',
              'electron',
              'elo',
              'hiper',
              'hipercard',
              'maestro',
              'maestrouk',
              'mc',
              'mcalphabankbonus',
              'visa',
              'visaalphabankbonus',
              'visadankort'
            ],
            onFocus: (state, component) => {
              var activeInput = ''

              if (state.fieldType === 'encryptedCardNumber') {
                activeInput = 'Número do cartão de crédito'
              } else if (state.fieldType === 'encryptedSecurityCode') {
                activeInput = 'CVV'
              } else if (state.fieldType === 'encryptedExpiryDate') {
                activeInput = 'Data de validade'
              }

              $this.setActiveInput(activeInput)
            },
            onFieldValid: (state, component) => {
              if (state.valid === true) {
                var lastFourNumbers = $this.paymentData.cc_last_4
                if (state.endDigits) { lastFourNumbers = state.endDigits }
                $this.ValidateAdyenCardFields(state.encryptedFieldName, lastFourNumbers)
              }
            },
            onBrand: (state, component) => {
              $this.paymentData.cc_brand = state.brand
            },
            onBlur: () => {
              $this.setActiveInput('')
            }
          })
        ccForm.mount('#adyenPayment-container')
        // console.log(`ccForm`, ccForm)
      } catch (error) {
        console.error(error)
        alert('Error occurred. Look at console for details')
      }
    },
    ValidateFieldWithError (fieldType) {
      var fieldToValidate = ''

      switch (fieldType) {
        case 'encryptedCardNumber':
          fieldToValidate = 'cc_number_adyen'
          break
        case 'encryptedSecurityCode':
          fieldToValidate = 'cc_cvv_adyen'
          break
        case 'encryptedExpiryDate':
          fieldToValidate = 'cc_expiration_date_adyen'
          break
        default:
          break
      }

      this.ValidateField(fieldToValidate)
    },
    ValidateAdyenCardFields (cardFieldName, lastFourNumbers) {
      switch (cardFieldName.toLowerCase()) {
        case 'encryptedcardnumber':
          this.paymentData.cc_last_4 = lastFourNumbers
          this.ResetFieldValidation()
          break
        case 'encryptedsecuritycode':
          this.ResetFieldValidation('cc_cvv_adyen')
          break
        case 'encryptedexpirymonth':
          this.ResetFieldValidation('cc_expiration_date_adyen')
          break
        case 'encryptedexpiryyear':
          this.ResetFieldValidation('cc_expiration_date_adyen')
          break
        default:
          break
      }
    },
    ValidateField (fieldName) {
      this.$refs['PaymentInformations'].validateField(fieldName)
    },
    ResetFieldValidation (fieldName) {
      this.$refs['PaymentInformations'].clearValidate(fieldName)
    },
    destroyCreditCardForm () {
      if (ccForm) {
        ccForm.unmount()
        ccForm = null
      }
    },
    setActiveInput (input) {
      this.activeInput = input
    },
    ValidateUserName (rule, value, callback) {
      if (this.onlyTextField(value)) {
        return callback()
      } else {
        return callback(new Error(`Números não são permitidos`))
      }
    },
    ValidateUserDocument (rule, value, callback) {
      if (!this.checkUserDocument(value)) {
        return callback(
          new Error(
            `${
              value.replace(/[^\d]+/g, '').length <= 11 ? 'CPF' : 'CNPJ'
            } inválido`
          )
        )
      } else {
        callback()
      }
    },
    ValidateCardNumberAdyen (rule, value, callback) {
      if (this.encryptedCardNumberIsValid == null || this.encryptedCardNumberIsValid == undefined || this.encryptedCardNumberIsValid == "") { //eslint-disable-line
        return callback(new Error('Número do cartão inválido'))
      }

      if (this.encryptedCardNumberIsValid === securedData.encryptedCardNumber) {
        return callback()
      }

      return this.encryptedCardNumberIsValid ? callback(new Error(this.encryptedCardNumberIsValid)) : callback()
    },
    ValidateCVVAdyen (rule, value, callback) {
      if (this.encryptedSecurityCodeIsValid == null || this.encryptedSecurityCodeIsValid == undefined || this.encryptedSecurityCodeIsValid == "") { //eslint-disable-line
        return callback(new Error('CVV inválido'))
      }

      if (this.encryptedSecurityCodeIsValid === securedData.encryptedSecurityCode) {
        return callback()
      }

      return this.encryptedSecurityCodeIsValid ? callback(new Error(this.encryptedSecurityCodeIsValid)) : callback()
    },
    ValidateCardDateAdyen (rule, value, callback) {
      if (this.encryptedExpiryDateIsValid == null || this.encryptedExpiryDateIsValid == undefined || this.encryptedExpiryDateIsValid == "") { //eslint-disable-line
        return callback(new Error('Data de validade inválida'))
      }

      if (this.encryptedExpiryDateIsValid === (securedData.encryptedExpiryMonth + securedData.encryptedExpiryYear)) {
        return callback()
      }

      return this.encryptedExpiryDateIsValid ? callback(new Error(this.encryptedExpiryDateIsValid)) : callback()
    },
    installmentMessage (installment) {
      if (installment.interest_rate !== undefined) {
        return `${installment.installments}x de ${this.formatMoney(
          installment.value
        )} | Total:  ${this.formatMoney(installment.total_in_installments)} ${
          installment.interest_rate > 0
            ? `(Juros de ${installment.interest_rate}%)`
            : ``
        }`
      }
    },
    generateHash () {
      if ((securedData.encryptedExpiryMonth && securedData.encryptedExpiryMonth !== '') &&
         (securedData.encryptedExpiryYear && securedData.encryptedExpiryYear !== '') &&
         (securedData.encryptedSecurityCode && securedData.encryptedSecurityCode !== '') &&
         (securedData.encryptedCardNumber && securedData.encryptedCardNumber !== '')) {
        return btoa(JSON.stringify(securedData))
      }
      return ''
    }
  },
  watch: {
    paymentData: {
      deep: true,

      async handler (val) {
        var paymentHash = this.generateHash()

        if (paymentHash) {
          this.UpdatePaymentState({
            hash: paymentHash,
            brand: this.paymentData.cc_brand,
            last_four_numbers: this.paymentData.cc_last_4,
            full_name: this.paymentData.full_name,
            document: this.paymentData.document
          })
        }
      }
    },
    deep: true,
    'paymentData.cc_installments': function (installment) {
      this.SetPaymentMethod(installment)
    }
  }
}
</script>
