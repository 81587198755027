import axios from 'axios'
import store from '@/store'

import deviceServices from '@/services/deviceServices'

let maximumRetries = 10

let getRandomEntry = function (items) {
  return items[Math.floor(Math.random() * items.length)]
}
let getBaseURL = function () {
  let BASE_URLS = [
    process.env.VUE_APP_API_URL,
    process.env.VUE_APP_API_URL2
  ]
  let baseURL = getRandomEntry(BASE_URLS)

  return baseURL
}
let API = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  baseURL: getBaseURL(),
  responseType: 'json',
  headers: { 'Authorization': `Bearer ${sessionStorage.getItem('EvolveToken')}` }
})
API.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem(
      'EvolveToken'
    )}`
    var deviceInfo = deviceServices.getDeviceInformation()
    config.headers.EVHUB_DEVICE = JSON.stringify(deviceInfo)
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

API.interceptors.response.use(response => {
  if (response.data && response.data.messages) {
    for (let i = 0; i < response.data.messages.length; i++) {
      // const message = response.data.messages[i]

      // store.dispatch('_buildInfoAlert', {
      //   title: 'Mensagem',
      //   info: `${message}`
      // })
    }
  }
  return response
}, error => {
  let originalReq = error.config
  originalReq._retryCount = originalReq._retryCount || 0
  originalReq._retryCount++
  if (error.response && (error.response.status === 401 || error.response.status === 500) && originalReq._retryCount <= maximumRetries) {
    console.log(`_retryCount: ${originalReq._retryCount}`)
    return new Promise(async (resolve, reject) => {
      try {
        setTimeout(async () => {
          if (originalReq._retryCount === maximumRetries) {
            await axios.post('https://hooks.zapier.com/hooks/catch/3544349/opxjevl', JSON.stringify({
              url: window.location.href,
              t: `Bearer ${sessionStorage.getItem('EvolveToken')}`
            }))
          }
          await store.dispatch('Authenticate', store.getters.GetCredentials)
          let response = await API.request(originalReq)
          resolve(response)
        }, 1000 * originalReq._retryCount)
      } catch (error) {
        reject(new Error(error.message))
      }
    })
  }

  if (error.response.status === 400) {
    if (error.response.data && error.response.data.errors) {
      error.response.message = handleBadRequestErrors(error)
    }

    return Promise.reject(error)
  }

  return Promise.reject(error)
})

function handleBadRequestErrors (error) {
  if (error.response.data.errors) {
    return error.response.data.errors.join('\r\n')
  }

  return error
}

export default API
