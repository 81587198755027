<template>
  <div class="Order__Step">
    <div class="Order__Section Order__Confirmation-text">
      <div class="Order__Confirmation-text--header">
        <div class="Order__Confirmation-text--header--icon">
          <i class="el-icon-circle-check"></i>
        </div>
        <div class="Order__Confirmation-text--header--content">
          <small>
            <span>Compra: #{{order.order.number}}</span>
          </small>
          <h1
            class="Checkout__Section__Title Order__Section__Title Section__Title"
          >Pedido aprovado, {{order.customer.first_name}}</h1>
        </div>
      </div>
      <div class="Order__Confirmation-text--content">
        <h4 v-if="AppConfig.thankyou_page.order_paid.subtitle">
          <b>{{AppConfig.thankyou_page.order_paid.subtitle}}</b>
        </h4>
        <p v-html="AppConfig.thankyou_page.order_paid.instruction_text.bankslip" v-if="order.payment_info.type === 'BOLETO'"></p>
        <p v-html="AppConfig.thankyou_page.order_paid.instruction_text.credit_card" v-if="order.payment_info.type === 'CREDIT_CARD'"></p>
        <p v-html="AppConfig.thankyou_page.order_paid.instruction_text.pix" v-if="order.payment_info.type === 'PIX'"></p>
      </div>
    </div>

    <OrderInfos :order="order"/>

  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import { mapFilters } from '@/helpers/filters'

import OrderInfos from '@/components/_order/OrderInfos.vue'

export default {
  name: 'Order',
  components: {
    OrderInfos
  },
  data () {
    return {
    }
  },
  props: ['checkout_id', 'order'],
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'Order', 'ActiveShippingMethod'])
  },
  created () {
  },
  methods: {
    ...mapFilters(['formatMoney', 'formatDocument'])
  },
  beforeRouteLeave (to, from, next) {
    if (!this.order.closed) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
