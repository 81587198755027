<template>
  <el-collapse
    v-if="ShippingMethods"
    v-model="chosenMethod"
    @change="ChooseShippingMethod(chosenMethod)"
    accordion
    class="el-collapse no-content"
>
    <el-collapse-item
    v-for="(method, index) in ShippingMethods"
    :key="index"
    :name="method.handle"
    class="ShippingMethod ShippingMethod--default"
    :class="{'el-collapse-item--selected':method.handle === chosenMethod}"
    >
    <template slot="title">
        <i class="header-icon el-collapse-item__header__icon" :class="method.handle === chosenMethod ? 'el-icon-success' : 'el-icon-truck'"></i>
        <h2 class="ShippingMethod__Title">
            <span>
              {{ GetShippingMessage(method) }}
              <small v-if="GetShippingMessage(method)">
                  {{method.title}}
              </small>
              <span v-else>
                  {{method.title}}
              </span>
            </span>
            <h4 class="moneyFormat">{{method.shipping_price ? formatMoney(method.shipping_price) : FreeShippingMessage(method)}}</h4>
        </h2>
        <!-- <el-tag
        type="primary"
        effect="dark"
        size="mini"
        v-if="method.handle === chosenMethod"
        >Selecionado</el-tag> -->
    </template>
    </el-collapse-item>
</el-collapse>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { mapFilters } from '@/helpers/filters'
// import Animation from '@/services/animation'

export default {
  name: 'ShippingMethods',
  components: {
  },
  data () {
    return {
      chosenMethod: '',
      shipping: ''
    }
  },
  validations: {
    chosenMethod: { required }
  },
  mounted () {
    // const { CheckoutStep } = this.$refs
    // const timeline = Animation.timeline
    // timeline
    //   .from(CheckoutStep.querySelectorAll('.Checkout__Section'), { duration: 1, y: -5, opacity: 1, stagger: 0.1, ease: 'expo.inOut' })
  },
  beforeMount () {
    // this.GetShippingMethods(this.Checkout.id)
  },
  computed: {
    ...mapGetters([
      'AppConfig',
      'Checkout',
      'CheckoutConfig',
      'ShippingMethods',
      'ActiveShippingMethod',
      'CheckoutStatus'
    ])
  },
  methods: {
    ...mapActions([
      'setCheckoutStatus',
      'goToPrevStep',
      'SetShippingMethods',
      'GetShippingMethods',
      'SetCheckoutShippingMethod',
      'updateShippingMethods',
      'GetShippingMessage'
    ]),
    ...mapFilters(['formatMoney']),
    nextStep (Checkout) {
      Checkout.activeStep = 2
      if (this.chosenMethod) {
        this.updateShippingMethods({
          checkoutId: this.$route.params.checkout_id,
          handle: this.chosenMethod
        })
      } else {
        this.setCheckoutStatus({
          ShippingMethods: {
            update: 'NOT_SELECTED'
          }
        })
      }
    },
    FreeShippingMessage(method) {
      if (['Retira', 'Retirada', 'Retirar'].some(i => method.title.includes(i))) {
        return 'Retirada Grátis'
      }
      return 'Frete Grátis'
    },
    GetShippingMessage (method) {
      // eslint-disable-next-line no-debugger
    //   debugger
      let shippingInfos = this.CheckoutConfig && this.CheckoutConfig.shippingInformationConfig ? this.CheckoutConfig.shippingInformationConfig : null
      var estimatedTime = method.estimate_business_days
      var minEstimatedDays = shippingInfos && shippingInfos.shipping_range ? estimatedTime + parseInt(shippingInfos.shipping_range.min) : null
      var maxEstimatedDays = shippingInfos && shippingInfos.shipping_range ? estimatedTime + parseInt(shippingInfos.shipping_range.max) : null

      minEstimatedDays = minEstimatedDays > 0 ? minEstimatedDays : 1
      maxEstimatedDays = maxEstimatedDays > 0 ? maxEstimatedDays : 2
      let $renderShippingMessage = (message) => {
        return message
          .replace(/{{maxEstimatedDays}}/g, maxEstimatedDays)
          .replace(/{{minEstimatedDays}}/g, minEstimatedDays)
          .replace(/{{pluralize:max_shipping_days}}/g, maxEstimatedDays > 1 ? 'dias úteis' : 'dia útil')
          .replace(/{{pluralize:min_shipping_days}}/g, minEstimatedDays > 1 ? 'dias úteis' : 'dia útil')
      }

      let sameDeliveryDates = $renderShippingMessage(this.AppConfig.translations.shipping_methods.shipping_messages.delivery_dates.same_delivery_dates)
      let differentDeliveryDates = $renderShippingMessage(this.AppConfig.translations.shipping_methods.shipping_messages.delivery_dates.different_delivery_dates)
      if (maxEstimatedDays === 0 || estimatedTime === 0 || !estimatedTime) {
        return ``
      }
      if (minEstimatedDays === maxEstimatedDays) {
        return sameDeliveryDates
      } else {
        return differentDeliveryDates
      }
    },
    ChooseShippingMethod (methodHandle) {
      let ShippingMethodOBJ =
        this.ShippingMethods.filter(method => {
          return method.handle === methodHandle
        })[0] ||
        this.ShippingMethods[0] ||
        null
      if (ShippingMethodOBJ) {
        this.setCheckoutStatus({
          ShippingMethods: {
            update: 'IDLE'
          }
        })
        this.chosenMethod = ShippingMethodOBJ.handle
        this.SetCheckoutShippingMethod(ShippingMethodOBJ)
      }
    }
  },
  watch: {
    ShippingMethods (methods) {
      if (methods) {
        let method = methods && methods.length ? methods[0].handle : null
        this.ChooseShippingMethod(method)
      }
      // this.ChooseShippingMethod(this.Checkout.chosen_delivery_option.handle)
    },
    deep: true
  }
}
</script>
