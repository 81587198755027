class Search {
    query = (list, key, operation, value) => {
      return list.filter((item) => {
        switch (operation) {
          case 'equals':
            return item[key] === value
          case 'not-equals':
            return item[key] !== value
          case 'contains':
            return item[key].includes(value)
          case 'not-contains':
            return !item[key].includes(value)
          default:
            return item
        }
        // Search.query(state.Discounts, 'id', 'equals', 'boleto')
      })
    }
}
export default new Search()
