import API from '@/api'
import EventBus from '@/helpers/eventBus'

const state = {
  ShippingMethods: null,
  ActiveShippingMethod: null
}
const getters = {
  ShippingMethods: function (state) {
    return state.ShippingMethods
  },
  ActiveShippingMethod: function (state) {
    return state.ActiveShippingMethod
  }
}
const actions = {

  async GetShippingMethods ({ commit, dispatch }, CheckoutID) {
    dispatch('setCheckoutStatus', {
      ShippingMethods: {
        get: 'LOADING'
      }
    })
    commit('SetShippingMethods', null)
    await API.get('/checkouts/' + CheckoutID + '/Shippings')
      .then(checkout => {
        commit('SetShippingMethods', checkout.data)

        dispatch('setCheckoutStatus', {
          ShippingMethods: {
            get: 'IDLE'
          }
        })
      })
      .catch((e) => {
        commit('SetShippingMethods', null)
        dispatch('setCheckoutStatus', {
          ShippingMethods: {
            get: 'ERROR'
          }
        })
        dispatch('_buildErrorAlert', {
          title: 'Erro no Trazer Métodos de envio',
          error: `Ocorreu um erro com seus métodos de envio, por favor tente novamente: ${e}`
        })

        dispatch('setAnalyticsError', { id: CheckoutID, method: 'GetShippingMethods', error: e.message, fatal: true })
      })
  },
  async SetShippingMethods ({ commit, dispatch }, value) {
    commit('SetShippingMethods', value)
  },
  async updateShippingMethods ({ commit, dispatch }, { checkoutId, handle }) {
    var shippingMethod = {
      chosen_delivery_option: handle
    }

    dispatch('setCheckoutStatus', {
      ShippingMethods: {
        update: 'LOADING'
      }
    })

    await API.put(`/checkouts/${checkoutId}/ShippingMethods/`, shippingMethod)
      .then(checkout => {
        checkout.data.activeStep = 3
        dispatch('setCheckoutStatus', {
          ShippingMethods: {
            update: 'IDLE'
          }
        })
        commit('SetCheckoutState', checkout.data, 'SUCCESS')
        EventBus.$emit('checkout-updated')
      })
      .catch((e) => {
        dispatch('setCheckoutStatus', {
          ShippingMethods: {
            update: 'ERROR'
          }
        })
        dispatch('setAnalyticsError', { id: `${checkoutId}/${shippingMethod}`, method: 'updateShippingMethods', error: `${e.message}`, fatal: true })
        return new Error(`updateShippingMethods: ${e.message}`)
      })
  },
  async SetCheckoutShippingMethod ({ commit, dispatch }, ShippingMethod) {
    let $this = this
    dispatch('UpdateCheckoutState', {
      // chosen_delivery_option: ShippingMethod.handle,
      totals: {
        ...$this.state.Checkout.Checkout.totals,
        shipping: ShippingMethod.shipping_price
      }
    })
    commit('SetActiveShippingMethod', ShippingMethod)
  }
}
const mutations = {
  SetShippingMethods: function (state, ShippingMethodsOBJ) {
    state.ShippingMethods = ShippingMethodsOBJ
  },
  SetActiveShippingMethod: function (state, ActiveShippingMethod) {
    state.ActiveShippingMethod = ActiveShippingMethod
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
