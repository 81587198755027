/* eslint-disable no-debugger */
/* eslint-disable no-undef */
import eventBus from '../../helpers/eventBus'
import EvHubPaymentValidator from './evHubPaymentValidator'
import { PaymentServices } from './paymentServices'
import { Base64Helper } from '../../helpers'

const evHubValidator = EvHubPaymentValidator
var cardHash = null
var errorMessage = null
var maxWaitTimeout = 5
var isBusy = false

export class PagBrasilServices extends PaymentServices {
  #base64Helper;

  constructor () {
    super()
    this.#base64Helper = new Base64Helper()
  }

  initiateFraudAnalysisToken (pagShieldToken) {
    if (pagShieldToken) {
      window.__kdt = window.__kdt || []
      window.__kdt.push({ public_key: pagShieldToken })

      var kdt = document.createElement('script')
      kdt.id = 'kdtjs'
      kdt.type = 'text/javascript'
      kdt.async = true
      kdt.src = 'https://i.k-analytix.com/k.js'
      var s = document.getElementsByTagName('body')[0]
      s.parentNode.insertBefore(kdt, s)
    }
  }

  async createPaymentHash (number, cvv, expirationMonth, expirationYear) {
    try {
      if (!(number && number.replace(/\D/g, '').length >= 15)) {
        return
      }
      if (!(cvv && cvv.replace(/\D/g, '').length >= 3)) {
        return
      }
      if (!expirationMonth) {
        return
      }
      if (!expirationYear) {
        return
      }
      cardHash = null
      errorMessage = null
      $PB.setMerchant(this.pubKey)
      $PB.setCard(
        {
          cc_cvv: cvv,
          cc_expiration: `${expirationMonth}/${expirationYear}`,
          cc_holder: this.userName,
          cc_number: number.replaceAll(' ', ''),
          soft_descriptor: '',
          cc_save: 0,
          cc_auth: 1
        }
      )

      isBusy = true
      await $PB.doAddToken(handleDoAddToken, handleDoAddTokenError)
    } catch (e) {
      isBusy = false
      errorMessage = e.message.msg
      // if (e.message.msg) {
      //   eventBus.$emit('creditCardHashFailed', errorMessage)
      // }
    } finally {
      var waitCount = 0

      // eslint-disable-next-line no-unmodified-loop-condition
      while (isBusy && waitCount < maxWaitTimeout) {
        await wait(1000)
        waitCount++
      }
    }
    var customerHashes = { cardHash, visitorId: getVisitorId() }

    eventBus.$emit('creditCardHashCreated', this.#base64Helper.encode(customerHashes))

    return this.#base64Helper.encode(customerHashes)
  }

  validateNumber (cardNumber) {
    return evHubValidator.validateCardNumber(cardNumber)
  }

  getCardBrand (cardNumber) {
    return evHubValidator.getCardBrand(cardNumber)
  }

  validateSecurityCode (cardNumber, securityCode) {
    var isValid = evHubValidator.validateSecurityCode(securityCode)

    return isValid && !errorMessage
  }

  validateExpirationDate (month, year) {
    return evHubValidator.validateExpirationDate(month, year)
  }
}

function getVisitorId () {
  var visitorId = ''
  try {
    if (window.Konduto) {
      visitorId = Konduto.getVisitorID()
    }
  } catch (error) {
    console.error(error)
  }

  return visitorId
}

var handleDoAddToken = function (ret) {
  cardHash = ret.token
  isBusy = false
}

var handleDoAddTokenError = function (ret) {
  // eventBus.$emit('creditCardHashFailed', ret.error)
}

function wait (time) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
