import evHubPaymentValidator from './evHubPaymentValidator'
export class PaymentServices {
  constructor () {
    if (new.target === PaymentServices) {
      throw new TypeError('Cannot construct PaymentServices instances directly')
    }
  }

  setPubKey (pubKey) {
    this.pubKey = pubKey
    return this
  }

  setUserName (name) {
    this.userName = name
    return this
  }

  setDocumentNumber (documentNumber) {
    this.documentNumber = documentNumber
    this.documentType = documentType(documentNumber)
    return this
  }

  async createPaymentHash (number, cvv, expirationMonth, expirationYear) {
    throw new TypeError('Abstract method createPaymentHash not implemented.')
  }

  validateNumber (cardNumber) {
    throw new Error('Abstract method validateCardNumber not implemented')
  }

  getCardBrand (cardNumber) {
    throw new Error('Abstract method getCardBrand not implemented')
  }

  validateSecurityCode (securityCode, maxLength) {
    throw new Error('Abstract method validateSecurityCode not implemented')
  }

  validateExpirationDate (month, year) {
    return evHubPaymentValidator.validateExpirationDate(month, year)
  }
}

function documentType (documentNumber) {
  if (!documentNumber) {
    throw new Error('Número do documento precisa ser fornecido.')
  }
  if (documentNumber.replace(/[.,-]/g, '').length === 11) {
    return 'CPF'
  }
  return 'CNPJ'
}
