<template>
  <div class="Promotions Promotion__List" v-loading="CheckoutStatus.Promotions.get === 'LOADING'" v-if="!HasPromotionalItemsAdded && 1 + 1 === 3">
      <!-- {{ManualPromotions}} -->
        <div class="Promotion Promotion__Item" v-for="promotion in ManualPromotions" :key="promotion._id">
            <div class="Promotion__Header">
                <h2 class="Promotion__Title">{{promotion.description}}</h2>
                <label class="Promotion__Count">
                    {{ProductsStaged.length}} de {{promotion.remainingGiftsToSelect}}
                </label>
            </div>
            <div class="Promotion__Content" v-if="promotion.products.length > 0">
                <VueSlickCarousel
                    :arrows="true" :dots="true"
                    :ref="'PromotionSwiper'"
                    v-bind="PromotionSwiperOption"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                >
                    <!-- <div v-for="promotion in ManualPromotions" :key="promotion._id"> -->
                    <div class="Promotion__Product" v-for="(product,index) in promotion.products" :key="`${product._id}_${index}`" :class="{'Promotion__Product--checked': ProductsStaged.map(p => p._id).includes(product._id)}">
                        <div class="Promotion__Product--Image"  @click="toggleStagedProducts(product, promotion.remainingGiftsToSelect)">
                            <div class="Promotion__Product--Image--Inner">
                                <img :src="product.image" />
                            </div>
                        </div>
                        <div class="Promotion__Product--Infos"  @click="toggleStagedProducts(product, promotion.remainingGiftsToSelect)">
                            <div class="Promotion__Product__Title">
                                <b>{{product.name}}</b>
                                <small v-if="product.short_description">{{product.short_description}}</small>
                            </div>
                        </div>
                    </div>
                    <template #prevArrow="arrowOption">
                      <div class="swiper-button-prev">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                      </div>
                    </template>
                    <template #nextArrow="arrowOption">
                      <div class="swiper-button-next">
                        {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                      </div>
                    </template>
                </VueSlickCarousel>

            </div>
            <el-button
                size="mini" block
                @click="setProductsToSelect(ProductsStaged)"
                v-if="ProductsStaged.length >= promotion.remainingGiftsToSelect"
                :loading="CheckoutStatus.Products.add === 'LOADING'"
                class="btn el-button--block"
            >
                Adicionar {{ProductsStaged.length}} {{ProductsStaged.length > 1 ? 'brindes':'brinde'}}
            </el-button>
        </div>
        <VariantSelector class="Promotion__VariantSelector" v-if="ProductsToSelect" :products="ProductsToSelect" @bundle-products-added="setProductsToSelect(null)"/>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapFilters } from '@/helpers/filters'
import { mapGetters, mapActions } from 'vuex'
import VariantSelector from '@/components/_promotions/VariantSelector.vue'
import EventBus from '@/helpers/eventBus'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Promotions',
  components: {
    // Splide,
    VueSlickCarousel,
    VariantSelector
  },
  created () {
    if (!this.PromotionsEventsInit) {
      this.initPromotionsEvents()
      EventBus.$emit('fetch-promotions')
    }
  },
  data () {
    return {
      ProductsStaged: [],
      ProductsToSelect: null,
      PromotionSwiperOption: {
        'dots': true,
        'focusOnSelect': true,
        'infinite': true,
        'speed': 500,
        'slidesToShow': 3,
        'slidesToScroll': 3,
        'touchThreshold': 5
      }
    }
  },

  watch: {
    ManualPromotions: function (newManualPromotions, oldManualPromotions) {
      if (JSON.stringify(newManualPromotions) !== JSON.stringify(oldManualPromotions)) {
        this.ProductsStaged = []
      }
    }
  },

  computed: {
    ...mapGetters(['Checkout', 'CheckoutStatus', 'PromotionsEventsInit', 'Promotions', 'AutomaticPromotions', 'ManualPromotions']),
    HasPromotionalItemsAdded () {
      return this.Checkout.products.some(product => product.price === 0)
    }
  },
  mounted () {
    // console.log('Current Swiper instance object', this.swiper)
    // this.swiper.slideTo(3, 1000, false)
  },
  methods: {
    ...mapFilters(['formatMoney', 'dateFormat']),
    ...mapActions(['initPromotionsEvents']),
    onSwiper (swiper) {
      console.log(swiper)
    },
    onSlideChange () {
      console.log('slide change')
    },
    toggleStagedProducts (Product, maxAllowed) {
      if (!this.ProductsStaged.find(product => product._id === Product._id)) {
        if (this.ProductsStaged.length < maxAllowed) {
          this.ProductsStaged.push(Product)
        }
      } else {
        let ProductIndex = this.ProductsStaged.findIndex(product => product._id === Product._id)
        this.ProductsStaged.splice(ProductIndex, 1)
      }

      console.log(this.ProductsStaged)
    },
    setProductsToSelect (ProductsARR) {
      this.ProductsToSelect = ProductsARR
    },
    prevSlide () {
      this.swiper.slidePrev()
    },
    nextSlide () {
      this.swiper.slideNext()
    }
  }
}
</script>
