<template>
  <el-button-group class="ProductList__Item__quantity">
    <div class="el-form-item el-quantity"  v-loading="CheckoutStatus.Products.loading">
      <el-button type="text" class="el-quantity__button" icon="el-icon-minus" @click="decrease"></el-button>
      <input type="text" name="" id="" class="el-quantity__input" :value="quantity" disabled>
      <el-button type="text" class="el-quantity__button" icon="el-icon-plus" @click="increase"></el-button>
    </div>
  </el-button-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'QuantitySelector',
  data () {
    return {
      variant_qty: this.quantity || 0
    }
  },
  created () {},
  updated () {},
  props: ['variant', 'quantity'],
  computed: {
    ...mapGetters(['CheckoutStatus'])
  },
  methods: {
    ...mapActions(['GetInventoryIncomings', 'UpdateUpsellProduct', 'RemoveUpsellProduct']),
    increase () {
      this.variant_qty++
      this.update_quantity(this.variant_qty)
    },
    decrease () {
      // eslint-disable-next-line no-debugger
      // debugger
      if (this.variant_qty > 0) {
        this.variant_qty--
        this.update_quantity(this.variant_qty)
      }
    },
    update_quantity (quantity) {
      if (quantity > 0) {
        // eslint-disable-next-line no-debugger
        // debugger
        this.UpdateUpsellProduct({
          variantId: this.variant,
          quantity: quantity
        })
      } else if (quantity === 0) {
        // eslint-disable-next-line no-debugger
        // debugger
        this.RemoveUpsellProduct({
          variantId: this.variant
        })
      }
    }
  }
}
</script>
