<template>
  <div class="Order__Section" v-if="BankslipDetails">
    <h2 class="Checkout__Section__Title">Dados do boleto:</h2>
    <el-card class="BankslipWidget Bankslip" v-if="BankslipDetails">
      <div class="Payment-Widget--bankslip__Content">
        <el-row class="Payment-Widget--bankslip__Content--header">
          <el-col :xs="24" :span="8" class="Payment-Widget--bankslip__Cell Payment-Widget--bankslip__Logo">
            <img :alt="AppConfig.name" :src="AppConfig.assets.images.logo" />
          </el-col>
          <el-col :xs="12" :span="8" class="Payment-Widget--bankslip__Cell Payment-Widget--bankslip__Amount" v-if="BankslipDetails.amount">
            <h4>Total a pagar:</h4>
            {{formatMoney(BankslipDetails.amount)}}
          </el-col>
          <el-col :xs="12" :span="8" class="Payment-Widget--bankslip__Cell Payment-Widget--bankslip__ExpirationDate" v-if="BankslipDetails.expirationDate">
            <h4>Data de vencimento:</h4>
            {{dateFormat(BankslipDetails.expirationDate)}}
          </el-col>
        </el-row>
        <el-row class="Payment-Widget--bankslip__Content--body">
          <el-col :span="24" class="Payment-Widget--bankslip__Cell Payment-Widget--bankslip__ScanArea" v-if="BankslipDetails.barcode_image">
            <h4>Escaneie o código de barras para efetuar o pagamento</h4>
            <div class="Payment-Widget--bankslip__Barcode-Image" v-if="BankslipDetails.barcode_image" v-html="BankslipDetails.barcode_image"></div>
            <span class="Payment-Widget--bankslip__LineCode--pretty" v-if="BankslipDetails.prettyNumber">
              {{BankslipDetails.prettyNumber}}
            </span>
          </el-col>
        </el-row>
        <el-row class="Payment-Widget--bankslip__Content--body">
          <el-col :span="24" class="Payment-Widget--bankslip__Cell Payment-Widget--bankslip__CopyArea" v-if="BankslipDetails.barcode_image">
            <h4>Ou copie este código para fazer o pagamento</h4>
            <div class="Payment-Widget--bankslip__LineCode" v-if="BankslipDetails.prettyNumber">
              <Clipboard :value="BankslipDetails.prettyNumber" :btn_type="'text'"/>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import BankSlipService from '../../services/bankSlipService'
import Clipboard from '@/components/_ui-components/Clipboard.vue'

export default {
  name: 'BankslipWidget',
  data () {
    return {
      isBankslipCodeCopied: false,
      isBankslipDownloaded: false,
      BankslipDetails: null
    }
  },
  props: ['line_code'],
  components: {
    Clipboard
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'ActiveShippingMethod', 'ActivePaymentMethod', 'PaymentOptions'])
  },
  created () {
    this.renderBankslipDetails(this.line_code)
  },
  methods: {
    ...mapFilters(['formatMoney', 'dateFormat', 'formatDocument']),
    ...mapActions([
      '_buildSuccessAlert'
    ]),

    copyToClipboard () {
      var bankslipCode = document.getElementById('copy')
      bankslipCode.select()
      document.execCommand('copy', false)
      this.isBankslipCodeCopied = true
      setTimeout(() => {
        this.isBankslipCodeCopied = false
      }, 3000)
      this._buildSuccessAlert({
        title: 'Código copiado',
        success: ''
      })
    },
    async downloadBankSlip (url) {
      window.open(url, '_blank')
      // let bankSlipService = new BankSlipService('bankSlipDownload')
      // bankSlipService.downloadBankSlip(url).then(() => {
      //   this.isBankslipDownloaded = true
      //   this._buildSuccessAlert({
      //     title: 'Boleto Baixado!',
      //     success: ''
      //   })
      // })
    },
    async renderBankslipDetails (lineCode = this.line_code) {
      let _BankslipDetails = await BankSlipService.GetBankslipDetails(lineCode)
      this.BankslipDetails = _BankslipDetails
    }
  },
  watch: {
  }

}
</script>
