<template>
  <div class="Clipboard Clipboard--Wrapper">
    <el-input type="text" :value="value" :size="size" ref="clipboard" :class="{'is--success':isTextCopied}">
        <el-button
            slot="append"
            :type="isTextCopied?`success`:`primary`"
            :icon="isTextCopied?'el-icon-check':'el-icon-document-copy'"
            @click="copyToClipboard()"
        >
        {{isTextCopied?'':'Copiar'}}
        </el-button>
    </el-input>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'Clipboard',
  components: {},
  props: {
    size: String,
    value: String,
    btn_type: String
  },
  data () {
    return {
      isTextCopied: false
    }
  },
  methods: {
    ...mapActions([
      '_buildSuccessAlert'
    ]),

    copyToClipboard () {
      var clipboard = this.$refs['clipboard']
      clipboard.select()
      document.execCommand('copy', false)
      clipboard.blur()
      this.isTextCopied = true
      setTimeout(() => {
        this.isTextCopied = false
      }, 3000)
      this._buildSuccessAlert({
        title: 'Código copiado',
        success: ''
      })
    }
  },
  computed: {
  }
}
</script>
