export class IOPayAntiFraud {
  constructor(securityPlan, publicKey, sessionId) {
    this.securityPlan = securityPlan
    this.publicKey = publicKey
    this.sessionId = sessionId
  }

  executeAntiFraud() {
    if (this.antiFraudAlreadyCreated()) {
      return
    }

    window.iopayAntifraudPublicKey = this.publicKey
    window.iopaySecurityPlan = this.securityPlan
    window.iopaySessionId = this.sessionId

    var iopay = document.createElement('script')
    iopay.id = 'iopayjs'
    iopay.type = 'text/javascript'
    iopay.async = true
    iopay.src = 'https://checkout.iopay.com.br/assets/js/behaviour_security.js'
    var s = document.getElementsByTagName('body')[0]
    s.parentNode.insertBefore(iopay, s)
  }

  antiFraudAlreadyCreated() {
    var ioPayTag = document.getElementById('iopayjs')

    return !!ioPayTag
  }

  removeAntiFraudTag() {
    var ioPayTag = document.getElementById('iopayjs')
    ioPayTag.remove()
  }
}
