/* eslint-disable no-debugger */
// import API from '@/api'
// import Search from '@/services/search'
import Promotions from '@/services/promotion'
import EventBus from '@/helpers/eventBus'

const state = {
  PromotionsEventsInit: false,
  Promotions: [],
  AutomaticPromotions: [],
  ManualPromotions: []
}

const getters = {
  PromotionsEventsInit: function (state) {
    return state.PromotionsEventsInit
  },
  Promotions: function (state) {
    return state.Promotions
  },
  AutomaticPromotions: function (state) {
    return state.AutomaticPromotions
  },
  ManualPromotions: function (state) {
    return state.ManualPromotions
  }
}

const actions = {

  initPromotionsEvents ({ commit, getters, dispatch }) {
    EventBus.$on('checkout-updated--xpto', function (Promotions) {
      console.log('GET_PROMOTIONS')
    })
    EventBus.$on('checkout-updated', function (Promotions) {
      dispatch('FetchPromotions')
    })

    EventBus.$on('fetch-promotions', function (Promotions) {
      dispatch('FetchPromotions')
    })

    EventBus.$on('updated-promotions', function (Promotions) {
      dispatch('SetPromotions', Promotions)
    })

    EventBus.$on('updated-automatic-promotions', function (AutomaticPromotions) {
      dispatch('SetAutomaticPromotions', AutomaticPromotions)
    })

    EventBus.$on('updated-manual-promotions', function (ManualPromotions) {
      dispatch('SetManualPromotions', ManualPromotions)
    })
    commit('TogglePromotionsEvents', true)
  },
  async FetchPromotions ({ commit, getters, dispatch }) {
    return new Promise(async (resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Promotions: {
          get: 'LOADING'
        }
      })
      try {
        let _Promotions = await Promotions.fetch()
        dispatch('setCheckoutStatus', {
          Promotions: {
            get: 'IDLE'
          }
        })
        commit('SetPromotions', _Promotions)
      } catch (error) {
        dispatch('setCheckoutStatus', {
          Promotions: {
            get: 'ERROR'
          }
        })

        dispatch('setAnalyticsError', { id: `${getters.Checkout.id}`, method: 'GetCheckout', error: error.message, fatal: true })
        return reject(new Error('Error FetchPromotions: ' + error))
      }
    })
  },
  async SetPromotions ({ commit }, Promotions) {
    commit('SetPromotions', Promotions)
  },
  async SetAutomaticPromotions ({ commit }, AutomaticPromotions) {
    commit('SetAutomaticPromotions', AutomaticPromotions)
  },
  async SetManualPromotions ({ commit }, ManualPromotions) {
    commit('SetManualPromotions', ManualPromotions)
  }

}

const mutations = {
  TogglePromotionsEvents: function (state, Status) {
    state.PromotionsEventsInit = Status
  },
  SetPromotions: function (state, Promotions) {
    state.Promotions = Promotions
  },
  SetAutomaticPromotions: function (state, AutomaticPromotions) {
    state.AutomaticPromotions = AutomaticPromotions
  },
  SetManualPromotions: function (state, ManualPromotions) {
    state.ManualPromotions = ManualPromotions
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
