import API from '@/api'
import RouterService from '@/services/routerService'

const state = {
  Payment: null,
  PaymentOptions: null,
  ActivePaymentMethod: null
}
const getters = {
  Payment: function (state) {
    return state.Payment
  },
  PaymentOptions: function (state) {
    let $paymentOptions
    if (state.PaymentOptions) {
      let $paymentOptionsReordered = [...state.PaymentOptions].sort((a, b) => {
        if (a.type === 'credit_card') {
          return -1
        }
        if (a.type === 'pix') {
          return -1
        }
        if (a.type === 'two_cards_payment') {
          return -1
        }
        return 0
      }).sort((a, b) => {
        if (a.type === 'credit_card') {
          return -1
        }
        return 0
      })
      $paymentOptions = $paymentOptionsReordered
    }
    return $paymentOptions
  },
  ActivePaymentMethod: function (state) {
    return state.ActivePaymentMethod
  }
}
const actions = {

  async GetPaymentOptions ({ commit, dispatch }, CheckoutID) {
    return new Promise((resolve, reject) => {
      dispatch('setCheckoutStatus', {
        PaymentOptions: {
          get: 'LOADING'
        }
      })
      // eslint-disable-next-line no-debugger
      // debugger
      API.get('/checkouts/' + CheckoutID + '/Payments')
        .then(checkout => {
          commit('SetPaymentOptions', { data: checkout.data })
          dispatch('setCheckoutStatus', {
            PaymentOptions: {
              get: 'SUCCESS'
            }
          })
          resolve(checkout.data)
        })
        .catch(e => {
          commit('SetPaymentOptions', { data: null })
          dispatch('setCheckoutStatus', {
            PaymentOptions: {
              get: 'ERROR'
            }
          })
          dispatch('setAnalyticsError', { id: `${CheckoutID}`, method: 'GetPaymentOptions', error: `${e.message}`, fatal: true })
          reject(new Error(`GetPaymentOptions: ${e.message}`))
        })
    })
  },
  async UpdatePaymentState ({ commit }, value) {
    commit('UpdatePaymentState', value)
  },
  async SetPaymentState ({ commit }, value) {
    commit('SetPaymentState', value)
  },
  async SetActivePaymentMethod ({ commit, dispatch, state, rootState }, PaymentMethod) {
    commit('SetActivePaymentMethod', PaymentMethod)
  },
  async SetPaymentMethod ({ commit, dispatch, state, rootState }, PaymentMethod) {
    PaymentMethod.discount_rate && PaymentMethod.discount_value
      ? dispatch('AddDiscount', {
        id: `payment_method`,
        category: 'payment_method',
        amount: PaymentMethod.discount_value,
        type: 'fixed_amount', // TODO: Exemplo desconto VAMOSJUNTOS, consumir propriedade do desconto
        name: `Installment discount`
      })
      : dispatch('DeleteDiscount', { key: 'id', value: `payment_method` })

    if (PaymentMethod.interest_rate && PaymentMethod.addition) {
      dispatch('AddAddition', {
        id: `payment_method`,
        category: 'payment_method',
        amount: PaymentMethod.addition,
        name: `Acréscimo de método de pagamento`
      })
      dispatch('UpdateCheckoutState', {
        totals: {
          ...this.state.Checkout.Checkout.totals,
          addition: PaymentMethod.addition
        }
      })
    } else {
      dispatch('DeleteAddition', { key: 'id', value: `payment_method` })
      dispatch('UpdateCheckoutState', {
        totals: {
          ...this.state.Checkout.Checkout.totals,
          addition: null
        }
      })
    }
    dispatch('UpdatePaymentState', {
      installmentId: PaymentMethod.id
    })

    // Tracking.event(`Choose Payment ${to.name}`, trackingOBJ)
    dispatch('SetActivePaymentMethod', PaymentMethod)
  },
  async completePayment ({ state, rootState, commit, dispatch }, { checkoutId, payment }) {
    dispatch('setCheckoutStatus', {
      Order: {
        close: 'LOADING'
      }
    })
    dispatch('ClearDiscounts')

    await API.put(`/checkouts/${checkoutId}/CompletePayment/`, payment)
      .then(checkout => {
        dispatch('setCheckoutStatus', {
          Order: {
            close: 'IDLE'
          }
        })
        // eslint-disable-next-line no-debugger
        // debugger
        commit('SetCheckoutState', checkout.data, 'SUCCESS')
        dispatch('setConversion', {
          order: checkout.data,
          conversionIDs: [
            {
              type: 'google_adwords',
              id: rootState.CheckoutConfig.CheckoutConfig.analyticsConfig.google_conversion_id
            }
          ]
        })
        dispatch('goToNextStep')
      })
      .catch((e) => {
        console.log('Error completePayment')
        dispatch('setCheckoutStatus', {
          Order: {
            close: 'ERROR'
          }
        })
        RouterService.goToStep(rootState.Checkout.Checkout.activeStep)
        if (e.response.status === 409) {
          dispatch('SetUnavailableItems', e.response.data)
          dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'completePayment', error: 'Itens fora de estoque', fatal: true })
        } else {
          if (e.response.data && e.response.data.id) {
            commit('SetCheckoutState', e.response.data)// CARREGA CHECKOUT COM O STATUS MAIS ATUAL APÓS O ERRO
          }

          if (containsWarningOrErrorMessages(e.response)) {
            dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'completePayment', error: `${e.message}`, fatal: true })

            dispatch('setCheckoutStatus', {
              Order: {
                close: 'IDLE'
              }
            })

            commit('SetCheckoutState', e.response.data)

            return
          }

          dispatch('_buildErrorAlert', {
            title: 'Erro no pagamento',
            error: getErrorMessage(e)
          })
          dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'completePayment', error: `${e.message}`, fatal: true })
        }
      })
  },
  async completePaymentV2 ({ state, rootState, commit, dispatch }, { checkoutId, payment }) {
    dispatch('setCheckoutStatus', {
      Order: {
        close: 'LOADING'
      }
    })
    dispatch('ClearDiscounts')

    await API.put(`/v2/checkouts/${checkoutId}/CompletePayment/`, payment)
      .then(checkout => {
        dispatch('setCheckoutStatus', {
          Order: {
            close: 'IDLE'
          }
        })
        // eslint-disable-next-line no-debugger
        // debugger
        commit('SetCheckoutState', checkout.data, 'SUCCESS')
        dispatch('setConversion', {
          order: checkout.data,
          conversionIDs: [
            {
              type: 'google_adwords',
              id: rootState.CheckoutConfig.CheckoutConfig.analyticsConfig.google_conversion_id
            }
          ]
        })
        dispatch('goToNextStep')
      })
      .catch((e) => {
        console.log('Error completePayment')
        dispatch('setCheckoutStatus', {
          Order: {
            close: 'ERROR'
          }
        })
        RouterService.goToStep(rootState.Checkout.Checkout.activeStep)
        if (e.response.status === 409) {
          dispatch('SetUnavailableItems', e.response.data)
          dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'completePayment', error: 'Itens fora de estoque', fatal: true })
        } else {
          if (e.response.data && e.response.data.id) {
            commit('SetCheckoutState', e.response.data)// CARREGA CHECKOUT COM O STATUS MAIS ATUAL APÓS O ERRO
          }

          if (containsWarningOrErrorMessages(e.response)) {
            dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'completePayment', error: `${e.message}`, fatal: true })

            dispatch('setCheckoutStatus', {
              Order: {
                close: 'IDLE'
              }
            })

            commit('SetCheckoutState', e.response.data)

            return
          }

          dispatch('_buildErrorAlert', {
            title: 'Erro no pagamento',
            error: getErrorMessage(e)
          })
          dispatch('setAnalyticsError', { id: `${checkoutId}`, method: 'completePayment', error: `${e.message}`, fatal: true })
        }
        dispatch('setCheckoutStatus', {
          PaymentOptions: {
            update: 'MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR'
          }
        })
        throw new Error('MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR')
      })
  },
  async SetCheckoutPaymentBankSlip ({ commit, dispatch }, PaymentMethod) {
    let $this = this
    dispatch('UpdateCheckoutState', {
      payment_info: {
        ...$this.state.Checkout.Checkout.payment_info,
        installments: PaymentMethod.config.installments[0].installment,
        interest_rate: PaymentMethod.config.installments[0].discount_rate ? PaymentMethod.config.installments[0].discount_rate * -1 : PaymentMethod.config.installments[0].interest_rate,
        methodID: PaymentMethod.methodId,
        type: PaymentMethod.methodName,
        total_discount: PaymentMethod.total_discount,
        total_in_installments: PaymentMethod.config.installments[0].total_in_installments,
        installment_value: PaymentMethod.config.installments[0].value,
        hash: null
      }
    })
  },
  async SetCheckoutPaymentCreditCard ({ commit, dispatch }, PaymentMethod) {
    let $this = this
    dispatch('UpdateCheckoutState', {
      payment_info: {
        ...$this.state.Checkout.Checkout.payment_info,
        installments: PaymentMethod.config.installments ? PaymentMethod.config.installments[0].installment : 1,
        full_name: null,
        document: null,
        interest_rate: null,
        methodID: PaymentMethod.methodId,
        type: PaymentMethod.methodName,
        total_in_installments: PaymentMethod.total_in_installments,
        installment_value: PaymentMethod.installment_value,
        hash: null
      }
    })
  },
  async RemoveInvalidPaymentMethod ({ commit, dispatch }) {
    let $this = this
    console.log('deveria limpar os discontos e payment e tals dois')
    console.log('$this.state.Checkout.Checkout.payment_info: ', $this.state.Checkout.Checkout.payment_info)
    dispatch('UpdateCheckoutState', {
      payment_info: {
        ...$this.state.Checkout.Checkout.payment_info,
        installments: null,
        full_name: null,
        document: null,
        interest_rate: null,
        methodID: null,
        type: null,
        total_in_installments: null,
        installment_value: null,
        hash: null
      }
    })
  },
  async SetCheckoutPaymentMethod ({ commit, dispatch }, PaymentMethod) {
    if (PaymentMethod.type === 'boleto') {
      dispatch('SetCheckoutPaymentBankSlip', PaymentMethod)
    } else {
      dispatch('SetCheckoutPaymentCreditCard', PaymentMethod)
    }
  }
}
const mutations = {
  SetPaymentState: function (state, Payment) {
    state.Payment = Payment
  },
  UpdatePaymentState: function (state, Data) {
    state.Payment = {
      ...state.Payment,
      ...Data
    }
  },
  SetPaymentOptions: function (state, PaymentOptionsOBJ) {
    state.PaymentOptions = (PaymentOptionsOBJ.data)
  },
  SetActivePaymentMethod: function (state, ActivePaymentMethod) {
    state.ActivePaymentMethod = ActivePaymentMethod
  }
}

function containsWarningOrErrorMessages (response) {
  return response.data?.messages?.filter(message => (message?.type !== 'info')).length
}

function getErrorMessage(error) {
  if (!error.response.message) {
    return 'Ocorreu um erro com seu pagamento, por favor tente novamente'
  }

  return `${error.response.message} \r\n Ou tente novamente mais tarde.`
}

export default {
  state,
  getters,
  actions,
  mutations
}
