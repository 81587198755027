import detect from 'detect.js'

class DeviceServices {
  getDeviceInformation () {
    var userAgent = detect.parse(navigator.userAgent)
    var device = {
      hash: '',
      name: userAgent.os.name,
      browser: userAgent.browser.name,
      cookies: getCookies(),
      currentPath: window.location.href
    }

    device = this.#setDeviceHash(device)
    return device
  }

  #setDeviceHash (device) {
    var deviceHash = getDeviceHash()
    if (hasDeviceId()) {
      // eslint-disable-next-line no-undef
      device.hash = deviceHash || deviceId
    } else {
      device.hash = deviceHash
    }

    return device
  }
}

function getDeviceHash () {
  return sessionStorage.getItem('device_hash')
}

function hasDeviceId () {
  try {
    // eslint-disable-next-line no-undef
    return !!deviceId
  } catch (error) {
    return false
  }
}

function getCookies () {
  try {
    var cookiesResult = []
    const cookies = document.cookie.split(';')

    const facebookCookies = getFacebookCookies(cookies)
    const shopifySACookies = getShopifySACookies(cookies)

    cookiesResult.push(...facebookCookies)
    cookiesResult.push(...shopifySACookies)

    return cookiesResult
  } catch (error) {
    return []
  }
}

function getShopifySACookies(cookies) {
  var shopifySACookiesResult = []
  var shopifySACookies = cookies.find(ck => ck.split('=')[0]?.trim() === '_shopify_sa_p').split('=')[1]

  if (shopifySACookies !== undefined && shopifySACookies.length > 0) {
    var shopifySACookieDecoded = decodeURIComponent(shopifySACookies)

    shopifySACookieDecoded.split('&').forEach(element => {
      var cookieKey = element.split('=')[0]
      var cookieValue = element.split('=')[1]

      shopifySACookiesResult.push({
        key: cookieKey,
        value: cookieValue,
        type: 1
      })
    })

    return shopifySACookiesResult
  }
  return []
}

function getFacebookCookies(cookies) {
  var facebookCookies = cookies.filter(ck => ck.split('=')[0]?.trim() === '_fbp' || ck.split('=')[0]?.trim() === '_fbc')

  if (facebookCookies.length > 0) {
    return facebookCookies.map(fbCookie => {
      var cookieKey = fbCookie.split('=')[0]
      var cookieValue = fbCookie.split('=')[1]

      return {
        key: cookieKey,
        value: cookieValue,
        type: 1
      }
    })
  }

  return []
}

export default new DeviceServices()
