import API from '@/api'

const state = {
  CheckoutConfig: null
}
const getters = {
  CheckoutConfig: function (state) {
    return state.CheckoutConfig
  }
}
const actions = {
  async GetCheckoutConfig ({ commit, dispatch }) {
    await API.get(`/CheckoutConfigurations/config`).then(res => {
      commit('SetCheckoutConfig', res.data)
      dispatch('setAnalyticsTools', res.data.analyticsConfig)
    }).catch(err => {
      dispatch('setAnalyticsError', { id: `CHECKOUTCONFIG_ERROR`, method: 'GetCheckoutConfig', error: `${err.message}`, fatal: true })
    })
  }
}
const mutations = {
  SetCheckoutConfig: function (state, checkoutConfig) {
    state.CheckoutConfig = checkoutConfig
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
