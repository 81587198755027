import API from '@/api'
import store from '@/store'
import EventBus from '@/helpers/eventBus'

class Promotions {
  _allPromotions = []
  async getIncompletePromotions (options) {
    return new Promise(async (resolve, reject) => {
      try {
        let promotionsToCheck = this._allPromotions
        if (options && options.onlyMandatory) {
          promotionsToCheck = this._allPromotions.filter(promotion => promotion.minimumSelectedGifts && promotion.minimumSelectedGifts > 0)
        }
        resolve(promotionsToCheck.filter(promotion => promotion.remainingGiftsToSelect && promotion.remainingGiftsToSelect > 0))
      } catch (error) {
        return reject(new Error('Erro ao validar as promoções'))
      }
    })
  }
  // to do: repensar essa estrutura de validação, ver se faz sentido jogar esse nível de métodos pra uma classe base de validação na classe de CHECKOUT
  async validateCheckoutPromotions () {
    return new Promise(async (resolve, reject) => {
      let IncompletePromotions = await this.getIncompletePromotions({ onlyMandatory: true })
      if (!IncompletePromotions.length) {
        resolve(true)
      } else {
        return resolve(true)
        // return reject(new Error(`${IncompletePromotions.length > 1 ? 'Promoções obrigatórias' : 'Promoção obrigatória'}: Por favor selecione os brindes faltantes para continuar`))
      }
    })
  }
  async fetch () {
    return new Promise(async (resolve, reject) => {
      let Checkout = store.getters.Checkout
      await API.get(`/checkouts/${Checkout.id}/Promotions`, {})
        .then(res => {
          let Promotions = res.data.data
          // Promotions[0].allowAddToCart = true
          // let promotionMock = {
          //   _id: 'ba6cb133-6eb9-4262-a5ba-0e75f364da10',
          //   name: 'TESTE MULTI BRINDES',
          //   description: 'Escolha seu brinde',
          //   _priority: 1,
          //   images: [],
          //   allowAddToCart: true,
          //   allowAutomatedAdd: false,
          //   minProductsAllowed: 1,
          //   remainingGiftsToSelect: 2,
          //   maximumSelectedGifts: 2,
          //   products: [
          //     {
          //       _id: 4263900577895,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 31753088532583,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263897989223,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 30770620399719,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263900577895,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 31753088532583,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263897989223,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 30770620399719,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263900577895,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 31753088532583,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263897989223,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 30770620399719,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263900577895,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 31753088532583,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263897989223,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 30770620399719,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263900577895,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 31753088532583,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     },
          //     {
          //       _id: 4263897989223,
          //       name: 'Mini espuma',
          //       variants: [
          //         {
          //           _id: 30770620399719,
          //           _active: true,
          //           name: 'Mini espuma',
          //           inventoryQty: 968,
          //           price: 9.00
          //         }
          //       ],
          //       quantity: 1,
          //       image: 'https://cdn.shopify.com/s/files/1/0266/8832/3687/products/Sabonete_Dr._JONES_-_1.png?v=1571234945',
          //       variantsCount: 1,
          //       activeVariantsCount: 1,
          //       productType: 0
          //     }
          //   ],
          //   promotion_type: 0,
          //   greater_than_value: 0,
          //   active: true
          // }
          // Promotions.push(promotionMock)
          this._allPromotions = Promotions
          EventBus.$emit('updated-promotions', Promotions)
          EventBus.$emit('updated-automatic-promotions', Promotions.filter(promotion => promotion.allowAutomatedAdd === true && promotion.allowAddToCart === true))
          EventBus.$emit('updated-manual-promotions', Promotions.filter(promotion => promotion.allowAutomatedAdd !== true && promotion.allowAddToCart === true))
          resolve(Promotions)
        })
        .catch(() => {
          return reject(new Error('Erro ao buscar as promoções'))
        })
    })
  }
}
export default new Promotions()
