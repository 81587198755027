import evHubPaymentValidator from './evHubPaymentValidator'
import { PaymentServices } from './paymentServices'
import { AppMaxApi } from '../../api/appMaxApi'
import { dateHelper } from '../../helpers/dateHelper'
import { Base64Helper } from '../../helpers/base64Helper'

export class AppMaxServices extends PaymentServices {
  setPubKey (pubKey) {
    return super.setPubKey(pubKey)
  }
  #helper = new Base64Helper();

  async initiateFraudAnalysisToken (antiFraudToken) {
    const router = require('../../router/index').default

    await this.#waitUntilCheckoutIdFulfilled(router)
  }
  // eslint-disable-next-line no-dupe-class-members
  async #waitUntilCheckoutIdFulfilled(router) {
    var count = 0
    // eslint-disable-next-line no-unmodified-loop-condition
    while (!router.currentRoute.params.checkout_id && count < 3) {
      await dateHelper.wait(1000)
      count++
    }
  }

  async createPaymentHash (number, cvv, expirationMonth, expirationYear, token) {
    var api = new AppMaxApi()

    var hashResponse = await api.tokenizeCard({
      'access-token': this.pubKey,
      card: {
        name: this.userName,
        month: Number(expirationMonth),
        year: Number(expirationYear),
        number: number.replaceAll(' ', '').replaceAll('.', ''),
        cvv: cvv
      }
    })

    var paymentHash = hashResponse.data.data.token

    var hashObj = {
      hash: paymentHash,
      code: cvv
    }

    return this.#helper.encode(hashObj)
  }

  validateNumber (cardNumber) {
    return evHubPaymentValidator.validateCardNumber(cardNumber)
  }

  getCardBrand (cardNumber) {
    return evHubPaymentValidator.getCardBrand(cardNumber)
  }

  validateSecurityCode (ccnumber, cvv) {
    return evHubPaymentValidator.validateSecurityCode(cvv)
  }
}
