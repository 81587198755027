export class CyberSource {
  constructor(orgId, sessionId, checkoutId) {
    this.orgId = orgId
    this.sessionId = sessionId
    this.checkoutId = checkoutId
  }

  executeAntiFraud() {
    if (!this.orgId ||
        !this.sessionId ||
        !this.checkoutId) {
      return
    }

    var antifraudHeaderUrl = `https://h.online-metrix.net/fp/tags.js?org_id=${this.orgId}&session_id=${this.sessionId + this.checkoutId}`
    var antifraudUrl = `https://h.online-metrix.net/fp/tags?org_id=${this.orgId}&session_id=${this.sessionId + this.checkoutId}`

    var isHeaderTagAlreadyCreated = document.getElementById('antifraudHeaderUrl')

    if (!isHeaderTagAlreadyCreated) {
      var head = document.getElementsByTagName('head').item(0)
      var cyberSourceHeadTag = document.createElement('script')
      cyberSourceHeadTag.type = 'text/javascript'
      cyberSourceHeadTag.src = antifraudHeaderUrl
      cyberSourceHeadTag.id = 'antifraudHeaderUrl'
      head.appendChild(cyberSourceHeadTag)
    }

    var isBodyTagAlreadyCreated = document.getElementById('antifraudUrl')

    if (!isBodyTagAlreadyCreated) {
      var cyberSourceMetrixTag = document.createElement('script')
      cyberSourceMetrixTag.src = antifraudUrl
      cyberSourceMetrixTag.id = 'antifraudUrl'
      document.body.appendChild(cyberSourceMetrixTag)
    }
  }
}
