<template>
    <div class="Checkout__Section">
        <h2 class="Checkout__Section__Title" v-if="title">{{title}}</h2>
        <p class="Checkout__Section__Subtitle" v-if="subtitle">{{subtitle}}</p>
        <el-form ref="GiftMessageWidget" :model="gift_message" class="Checkout__Form--Custom Form--Custom">
            <el-row :gutter="10">
                <el-col :xs="24" :sm="12" :md="12" :span="12">
                    <div class="el-form-outer">
                        <el-form-item
                          class="el-form-item--radio"
                          :class="{'el-form-item--active': activeInput === 'gift_message--enabled'}"
                          prop="enabled"
                          ref="gift_message.enabled"
                          :rules="{
                              required: true, message: 'Escolha uma opção', trigger: 'change'
                          }"
                        >
                            <el-radio-group
                                inline
                                ref="gift_message.enabled"
                                v-model="gift_message.enabled"
                            >
                                <el-radio :label="true">Sim</el-radio>
                                <el-radio :label="false">Não</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="10" v-if="gift_message.enabled">
                <el-col :xs="24" :sm="12" :md="12" :span="12">
                    <div class="el-form-outer">
                        <el-form-item
                        :label="AppConfig.translations.forms.gift_message.from.label"
                        :class="{'el-form-item--active': activeInput === 'gift_message--from'}"
                        prop="from"
                        ref="gift_message.from"
                        :rules="{
                            required: true, message: 'Escreva o nome de quem está enviando este cartão', trigger: 'change'
                        }"
                        >
                        <el-input
                            :placeholder="AppConfig.translations.forms.gift_message.from.placeholder"
                            ref="gift_message.from"
                            v-model="gift_message.from"
                            name="address-level2"
                            autocomplete="address-level2"
                            @focus="setActiveInput('gift_message--from')"
                            @blur="setActiveInput(null)"
                        ></el-input>
                        </el-form-item>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :span="12">
                    <div class="el-form-outer">
                        <el-form-item
                        :label="AppConfig.translations.forms.gift_message.to.label"
                        :class="{'el-form-item--active': activeInput === 'gift_message--to'}"
                        prop="to"
                        ref="gift_message.to"
                        :rules="{
                            required: true, message: 'Escreva o nome de quem receberá este cartão', trigger: 'change'
                        }"
                        >
                        <el-input
                            :placeholder="AppConfig.translations.forms.gift_message.to.placeholder"
                            ref="gift_message.to"
                            v-model="gift_message.to"
                            name="address-level2"
                            autocomplete="address-level2"
                            @focus="setActiveInput('gift_message--to')"
                            @blur="setActiveInput(null)"
                        ></el-input>
                        </el-form-item>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :span="24">
                    <div class="el-form-outer">
                        <el-form-item
                        :label="AppConfig.translations.forms.gift_message.content.label"
                        :class="{'el-form-item--active': activeInput === 'gift_message--content'}"
                        prop="content"
                        ref="gift_message.content"
                        :rules="{
                            required: true, message: 'Escreva uma mensagem personalizada para ir no cartão', trigger: 'change'
                        }"
                        >
                        <el-input
                            :placeholder="AppConfig.translations.forms.gift_message.content.placeholder"
                            type="textarea"
                            maxlength="140"
                            show-word-limit
                            ref="gift_message.content"
                            v-model="gift_message.content"
                            name="address-level2"
                            autocomplete="address-level2"
                            @focus="setActiveInput('gift_message--content')"
                            @blur="setActiveInput(null)"
                        ></el-input>
                        </el-form-item>
                    </div>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'GiftMessage-Widget',
  data () {
    return {
      activeInput: null,
      gift_message: {
        enabled: !!this.GiftMessageFrom || !!this.GiftMessageTo || !!this.GiftMessageContent ? true : null,
        from: this.GiftMessageFrom ? this.GiftMessageFrom.value : null,
        to: this.GiftMessageTo ? this.GiftMessageTo.value : null,
        content: this.GiftMessageContent ? this.GiftMessageContent.value : null
      }
    }
  },
  created () {
    this.gift_message = {
      enabled: !!this.GiftMessageFrom || !!this.GiftMessageTo || !!this.GiftMessageContent ? true : null,
      from: this.GiftMessageFrom ? this.GiftMessageFrom.value : null,
      to: this.GiftMessageTo ? this.GiftMessageTo.value : null,
      content: this.GiftMessageContent ? this.GiftMessageContent.value : null
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'CheckoutStatus', 'Checkout', 'CustomAttributes']),
    GiftMessageFrom () {
      return this.CustomAttributes.find(attr => attr.name === 'gift_message_from')
    },
    GiftMessageTo () {
      return this.CustomAttributes.find(attr => attr.name === 'gift_message_to')
    },
    GiftMessageContent () {
      return this.CustomAttributes.find(attr => attr.name === 'gift_message_content')
    }
  },
  watch: {
    gift_message: {
      deep: true,
      immediate: true,
      handler: async function (val, oldVal) {
        this.$emit('input', val)
      }
    }
  },
  props: ['title', 'subtitle'],
  methods: {
    // ...mapActions(['AddGiftMessage'])
    ...mapActions(['SetCustomAttribute', 'SendCustomAttributes', 'DeleteCustomAttribute']),
    setActiveInput (input) {
      this.activeInput = input
    },
    async processGiftMessages () {
      try {
        let $valid = await this.$refs['GiftMessageWidget'].validate()
        if (!$valid) {
          throw new Error()
        }
        if (this.gift_message.enabled) {
          if (this.gift_message.from) { await this.SetCustomAttribute(['gift_message_from', this.gift_message.from]) }
          if (this.gift_message.to) { await this.SetCustomAttribute(['gift_message_to', this.gift_message.to]) }
          if (this.gift_message.content) { await this.SetCustomAttribute(['gift_message_content', this.gift_message.content]) }
          if (this.CustomAttributes) { await this.SendCustomAttributes() }
        } else {
          if (this.gift_message.from) { await this.DeleteCustomAttribute('gift_message_from') }
          if (this.gift_message.to) { await this.DeleteCustomAttribute('gift_message_to') }
          if (this.gift_message.content) { await this.DeleteCustomAttribute('gift_message_content') }
        }
        return true
      } catch (e) {
        throw new Error(`${e.message}`)
      }
    }
  }
}
</script>
