<template>
  <el-row :gutter="20" ref="CustomerInformationsPreview">
    <el-col :span="24">
      <el-card class="box-card Checkout__Overview--card">
        <div class="el-card__list--item Checkout__Overview--contact-information" v-if="data.customer">
          <h6>
            Dados do Cliente:
            <el-button
              v-if="edit"
              style="float: right; padding: 3px 0"
              type="text"
              size="mini"
              @click="goTo(`/Checkout/${data.id}/contact_information`)"
            >
              <i class="el-icon-edit"></i> Editar
            </el-button>
          </h6>
          <span>{{data.customer.first_name}} {{data.customer.last_name}} | {{data.customer.email}} | {{data.customer.phone}}</span>
        </div>
        <div class="el-card__list--item Checkout__Overview--shipping-address" v-if="data.shipping_info">
          <h6>
            {{  (AppConfig.translations.labels &&
                 AppConfig.translations.labels.shipping_info_title) ?
                AppConfig.translations.labels.shipping_info_title :
                "Dados de Entrega:"
            }}
            <el-button
              v-if="edit"
              style="float: right; padding: 3px 0"
              type="text"
              size="mini"
              @click="goTo(`/Checkout/${data.id}/contact_information`)"
            >
              <i class="el-icon-edit"></i> Editar
            </el-button>
          </h6>
          <span class="Checkout__Overview--address">
            <span v-if="data.shipping_info.person && data.shipping_info.person.first_name">
              <b>
                {{
                  (AppConfig.translations.contact_info_preview &&
                   AppConfig.translations.contact_info_preview.shipping_address.recipient_name_title)  ?
                  AppConfig.translations.contact_info_preview.shipping_address.recipient_name_title :
                  "Nome do destinatário:"
                }}</b>
              {{data.shipping_info.person.first_name}} {{data.shipping_info.person.last_name}}
              <br>
            </span>
            <b>Endereço:</b>
            <span>{{data.shipping_info.address.street}}, {{data.shipping_info.address.street_number}}</span>
            <span>{{data.shipping_info.address.complement}}</span>
            <span>{{data.shipping_info.address.neighborhood}}</span>
            <span>{{data.shipping_info.address.city}}</span>
            <span>{{data.shipping_info.address.state}}</span>
            <span>CEP: {{data.shipping_info.address.zip_code}}</span>
          </span>

          <!-- <ProductResume :products="data.products" :method="data.chosen_delivery_option" v-if="showProducts && data.chosen_delivery_option"/> -->
        </div>
        <GiftMessagePreview v-if="AppConfig.custom_attributes.gift_message_from && AppConfig.custom_attributes.gift_message_from.enabled && AppConfig.custom_attributes.gift_message_to && AppConfig.custom_attributes.gift_message_to.enabled && AppConfig.custom_attributes.gift_message_content && AppConfig.custom_attributes.gift_message_content.enabled" :edit="edit" />
        <div class="el-card__list--item Checkout__Overview--billing-address" v-if="fields.includes('billing_info') && data.billing_info">
          <h6>
            Endereço de Cobrança:
            <el-button
              v-if="edit"
              style="float: right; padding: 3px 0"
              type="text"
              size="mini"
              @click="toggleBillingInfoForm"
            >
              <i class="el-icon-edit"></i> Editar
            </el-button>
          </h6>
          <span class="Checkout__Overview--address">
            <span>{{data.billing_info.address.street}}, {{data.billing_info.address.street_number}}</span>
            <span>{{data.billing_info.address.complement}}</span>
            <span>{{data.billing_info.address.neighborhood}}</span>
            <span>{{data.billing_info.address.city}}</span>
            <span>{{data.billing_info.address.state}}</span>
            <span>CEP: {{data.billing_info.address.zip_code}}</span>
          </span>

          <transition @before-enter="enterAnimation" @leave="leaveAnimation">
            <BillingInfoForm v-if="BillingInfoFormOpen" />
          </transition>
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from 'vuex'
// import ProductResume from '@/components/_shippingMethods/ProductResume.vue'
import GiftMessagePreview from '@/components/_general/GiftMessagePreview.vue'
import Animation from '@/services/animation'
import RouterService from '@/services/routerService'

export default {
  name: 'ContactInfoPreview',
  components: {
    GiftMessagePreview
  },
  props: ['edit', 'showProducts', 'fields', 'data'],
  data () {
    return {
      BillingInfoFormOpen: false
    }
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout'])
  },
  methods: {
    goTo (uri) {
      RouterService.goTo(uri)
    },
    toggleBillingInfoForm () {
      this.BillingInfoFormOpen = !this.BillingInfoFormOpen
    },
    enterAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .from(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    },
    leaveAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .to(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    }
  }
}
</script>
