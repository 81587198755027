<template>
  <el-card class="PaymentMethod PaymentMethod--MultipleCreditCard">

    <div class="PaymentMethod__CTA MultipleCreditCard__CTA" v-show="MultipleCreditCardVisibilityStatus !== 'OPEN'">
      <span class="PaymentMethod__Title">Pague com dois cartões!</span>
      <span class="PaymentMethod__SubTitle">
        Pague suas compras online com dois cartões de crédito e desfrute de mais
        flexibilidade e segurança. Adicione as informações dos cartões abaixo e
        clique em 'Finalizar'
      </span>
    </div>

    <MultipleCreditCardForm
      v-for="i in MaxCardsAllowed"
      v-show="MultipleCreditCardVisibilityStatus === 'IDLE' || (MultipleCreditCardVisibilityStatus !== 'IDLE' && MultipleCreditCardFormOpenList.includes(i))"
      :ref="'MultipleCreditCardForm--'+i"
      :key="'MultipleCreditCardForm--'+i"
      :method="method"
      :amount_remaining="PaymentAmountRemaining"
      :credit_card_index="i"
      :allow_edit_partial_amount="i < MaxCardsAllowed"
    />

    <el-alert
      v-if="MultipleCreditCardPaymentStatus === 'ERROR_FORM_NOT_EXIST'"
      title="Adicione os dois cartões para continuar"
      type="error"
      description="Você precisa adicionar os dois cartões para continuar a compra"
      show-icon
    ></el-alert>
    <el-alert
      v-if="MultipleCreditCardPaymentStatus === 'ERROR_FORM_NOT_VALID'"
      title="Preencha os dados de cartão corretamente"
      type="error"
      description="Você precisa preencher os dados de cartão corretamente para continuar a compra"
      show-icon
    ></el-alert>
    <el-alert
      v-if="MultipleCreditCardPaymentStatus === 'INVALID_UNDER_NEEDED'"
      title="Revise os valores a serem descontados em cada cartão"
      type="error"
      show-icon
    >
      Ainda faltam {{ formatMoney(PaymentAmountRemaining) }} para ser processados antes de continuar a compra. Acrescente este valor em um dos cartões inseridos e tente novamente
    </el-alert>
    <el-alert
      v-if="MultipleCreditCardPaymentStatus === 'INVALID_OVER_NEEDED'"
      title="Revise os valores a serem descontados em cada cartão"
      type="error"
      description="Você deve revisar os valores a serem descontados em cada cartão para continuar a compra"
      show-icon
    ></el-alert>
    <el-alert
      v-if="MultipleCreditCardPaymentStatus === 'MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR'"
      title="Revise os dados dos cartões inseridos"
      type="error"
      description="Não foi possível completar seu pagamento pois um dos cartões é inválido ou não possui limite suficiente, por favor revise os cartões e altere o que for necessário"
      show-icon
    ></el-alert>

  </el-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import MultipleCreditCardForm from '@/components/_paymentOptions/MultipleCreditCardForm/MultipleCreditCardForm.vue'

import eventBus from '@/helpers/eventBus'

export default {
  name: 'MultipleCreditCardsPayment',
  components: {
    MultipleCreditCardForm
  },
  data () {
    return {
      MaxCardsAllowed: 2,
      MultipleCreditCardVisibilityStatus: 'IDLE',
      MultipleCreditCardPaymentStatus: 'IDLE',
      MultipleCreditCardFormOpenList: [],
      MultipleCreditCardPayment: {
        paymentId: this.method.id,
        payment_instruments: []
      },
      PaymentReserves: []
    }
  },
  created () {
  },
  mounted() {
    let _this = this
    eventBus.$on('multiple_credit_card.form.open', (CreditCardFormInfos) => {
      let alreadyOpened = _this.MultipleCreditCardFormOpenList.find(MultipleCreditCardForm => MultipleCreditCardForm === CreditCardFormInfos.index)
      if (!alreadyOpened) {
        _this.MultipleCreditCardFormOpenList.push(CreditCardFormInfos.index)
      }
      _this.MultipleCreditCardPaymentStatus = 'IDLE'
      _this.MultipleCreditCardVisibilityStatus = 'OPEN'
    })
    eventBus.$on('multiple_credit_card.form.close', async (CreditCardFormInfos) => {
      // _this.MultipleCreditCardFormOpenList = _this.MultipleCreditCardFormOpenList.filter(MultipleCreditCardForm => MultipleCreditCardForm !== CreditCardFormInfos.index)
      _this.MultipleCreditCardPaymentStatus = 'IDLE'
      _this.MultipleCreditCardVisibilityStatus = 'IDLE'

      try {
        let CreditCardPaymentReservesValid = await this.ValidateCreditCardPaymentReserves()
        if (!CreditCardPaymentReservesValid) {
          throw new Error('CREDIT_CARD_RESERVES_INVALID')
        }
      } catch (error) {
        this.MultipleCreditCardPaymentStatus = error.message
      }
    })
    eventBus.$on('multiple_credit_card.form.fulfilled', (CreditCardInfos) => {
      if (CreditCardInfos?.id) {
        let $PaymentInstrument = {
          ...CreditCardInfos
        }
        this.handleCreditCardFulfilled($PaymentInstrument)
      }
    })
    eventBus.$on('multiple_credit_card.form.not_fulfilled', (CreditCardInfos) => {
      if (CreditCardInfos?.id) {
        let $PaymentInstrument = {
          ...CreditCardInfos
        }
        this.handleCreditCardNotFulfilled($PaymentInstrument)
      }
    })
    eventBus.$on('multiple_credit_card.form.amount.updated', (CreditCardInfos) => {
      if (CreditCardInfos?.id) {
        let $PaymentInstrument = {
          ...CreditCardInfos
        }
        this.handleCreditCardAmountUpdated($PaymentInstrument)
      }
    })
    eventBus.$on('multiple_credit_card.form.amount.invalid', (CreditCardInfos) => {
      if (CreditCardInfos?.id) {
        let $PaymentInstrument = {
          ...CreditCardInfos
        }
        this.handleCreditCardAmountInvalid($PaymentInstrument)
      }
    })
    this.ClearPaymentInstruments()
  },
  computed: {
    ...mapGetters(['Checkout', 'CheckoutConfig', 'AppConfig']),
    PaymentAmountReserved() {
      let $AmountReserved = this.PaymentReserves.reduce(
        (old, curr) => {
          return old + curr.amount
        },
        0
      )
      return $AmountReserved
    },
    PaymentAmountRemaining() {
      let $CheckoutTotal = this.Checkout.totals.total
      return $CheckoutTotal - this.PaymentAmountReserved
    },
    MultipleCreditCardsReserveStatus() {
      let $status = 'INVALID'
      if (this.PaymentAmountRemaining === 0) {
        $status = 'VALID'
      } else if (this.PaymentAmountRemaining > 0) {
        $status = 'INVALID_UNDER_NEEDED'
      } else {
        $status = 'INVALID_OVER_NEEDED'
      }
      return $status
    },
    AllCreditCardsFulfilled() {
      return this.PaymentReserves.length === this.MaxCardsAllowed
    }
  },
  props: ['method'],
  methods: {
    ...mapFilters(['formatMoney', 'checkUserDocument', 'onlyTextField']),
    ...mapActions(['UpdateCheckoutState', 'UpdatePaymentState', 'SetPaymentMethod', 'setCheckoutStatus', 'completePaymentV2']),

    SetPaymentReserve (PaymentReserve) {
      if (!PaymentReserve) {
        return
      }
      this.PaymentReserves = [
        ...this.PaymentReserves.filter(reserve => reserve.id !== PaymentReserve.id),
        {
          id: PaymentReserve.id,
          amount: PaymentReserve.amount
        }
      ]
    },
    RemovePaymentReserve (PaymentReserve) {
      if (!PaymentReserve) {
        return
      }
      this.PaymentReserves = [
        ...this.PaymentReserves.filter(Reserve => Reserve.id !== PaymentReserve.id)
      ]
    },
    ClearPaymentReserves (state) {
      this.PaymentReserves = []
    },
    SetPaymentInstrument (PaymentInstrument) {
      if (!PaymentInstrument) {
        return
      }
      this.SetPaymentReserve(PaymentInstrument)
      let PaymentInstruments = this.MultipleCreditCardPayment.payment_instruments ? [
        ...this.MultipleCreditCardPayment.payment_instruments.filter(instrument => instrument.id !== PaymentInstrument.id),
        {
          id: PaymentInstrument.id,
          installmentId: PaymentInstrument.installmentId,
          hash: PaymentInstrument.hash,
          hash_fallback: PaymentInstrument.hash_fallback,
          brand: PaymentInstrument.brand,
          last_four_numbers: PaymentInstrument.last_four_numbers,
          full_name: PaymentInstrument.full_name,
          document: PaymentInstrument.document,
          amount: PaymentInstrument.amount
        }
      ] : []

      this.MultipleCreditCardPayment = {
        ...this.MultipleCreditCardPayment,
        payment_instruments: PaymentInstruments
      }
    },
    RemovePaymentInstrument (PaymentInstrument) {
      if (!PaymentInstrument) {
        return
      }
      this.RemovePaymentReserve(PaymentInstrument)
      let PaymentInstruments = this.MultipleCreditCardPayment.payment_instruments ? [
        ...this.MultipleCreditCardPayment.payment_instruments.filter(instrument => instrument.id !== PaymentInstrument.id)
      ] : []

      this.MultipleCreditCardPayment = {
        ...this.MultipleCreditCardPayment,
        payment_instruments: PaymentInstruments
      }
    },
    ClearPaymentInstruments (state) {
      this.ClearPaymentReserves()
      this.MultipleCreditCardPayment = {
        ...this.MultipleCreditCardPayment,
        payment_instruments: []
      }
    },

    handleCreditCardFulfilled ($PaymentInstrument) {
      this.SetPaymentInstrument($PaymentInstrument)
    },
    handleCreditCardNotFulfilled ($PaymentInstrument) {
      this.RemovePaymentInstrument($PaymentInstrument)
    },
    handleCreditCardAmountUpdated ($PaymentInstrument) {
      this.SetPaymentReserve($PaymentInstrument)
    },
    handleCreditCardAmountInvalid ($PaymentInstrument) {
      this.RemovePaymentReserve($PaymentInstrument)
    },
    async ValidateCreditCardForms () {
      try {
        let CreditCardFormsValid = false
        let MultipleCreditCardForms = this.$refs
        let MultipleCreditCardFormsList = Object.values(MultipleCreditCardForms)

        let $formValidations = await Promise.all(
          MultipleCreditCardFormsList.map(async MultipleCreditCardForm => {
            let $MultipleCreditCardFormValid = false
            let $MultipleCreditCardForm = MultipleCreditCardForm[0]

            if (!$MultipleCreditCardForm) {
              return false
            }

            $MultipleCreditCardFormValid = await $MultipleCreditCardForm.ValidatePaymentForm()

            return $MultipleCreditCardFormValid
          })
        )
        CreditCardFormsValid = $formValidations.every($formValidation => $formValidation === true)
        return CreditCardFormsValid
      } catch (error) {
        throw new Error(error.message)
      }
    },
    async ValidateCreditCardPaymentHashes () {
      let CreditCardPaymentHashesValid = false
      let $PaymentInstruments = this.MultipleCreditCardPayment.payment_instruments.filter(paymentInstrument => !!paymentInstrument.hash)
      if ($PaymentInstruments.length === this.MaxCardsAllowed) {
        CreditCardPaymentHashesValid = true
      }

      return CreditCardPaymentHashesValid
    },
    async ValidateCreditCardPaymentReserves () {
      try {
        let CreditCardPaymentReservesValid = false
        if (this.MultipleCreditCardsReserveStatus === 'VALID') {
          CreditCardPaymentReservesValid = true
        }
        if (!CreditCardPaymentReservesValid) {
          throw new Error(`${this.MultipleCreditCardsReserveStatus}`)
        }
        return CreditCardPaymentReservesValid
      } catch (error) {
        throw new Error(`${this.MultipleCreditCardsReserveStatus}`)
      }
    },
    async CompletePayment() {
      try {
        this.MultipleCreditCardPaymentStatus = 'IDLE'
        let CreditCardFormsValid = await this.ValidateCreditCardForms()
        if (!CreditCardFormsValid) {
          throw new Error('CREDIT_CARD_FORMS_INVALID')
        }
        let CreditCardPaymentHashesValid = await this.ValidateCreditCardPaymentHashes()
        if (!CreditCardPaymentHashesValid) {
          throw new Error('CREDIT_CARD_HASHES_INVALID')
        }

        let CreditCardPaymentReservesValid = await this.ValidateCreditCardPaymentReserves()
        if (!CreditCardPaymentReservesValid) {
          throw new Error('CREDIT_CARD_RESERVES_INVALID')
        }
        await this.completePaymentV2({
          checkoutId: this.Checkout.id,
          payment: this.MultipleCreditCardPayment
        })
      } catch (error) {
        // MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR
        this.ClearPaymentInstruments()
        this.MultipleCreditCardPaymentStatus = error.message
        throw new Error(error.message)
      }
    }
  },
  watch: {
    deep: true,
    'paymentData.cc_installments': function (installment) {},
    'PaymentAmountRemaining': function (AmountRemaining) {
    },
    'MultipleCreditCardPaymentStatus': function (Status) {
      if (!Status) {
        return
      }
      if (Status === 'INVALID_UNDER_NEEDED') {
        eventBus.$emit('multiple_credit_card.payment.under', { PaymentAmountRemaining: this.PaymentAmountRemaining })
      }
      if (Status === 'INVALID_OVER_NEEDED') {
        eventBus.$emit('multiple_credit_card.payment.over', { PaymentAmountRemaining: this.PaymentAmountRemaining })
      }
    }
  }
}
</script>
