<template>
  <el-card class="ShippingMethod__Resume">
    <div slot="header" class="clearfix">
      <h3>Agendamento</h3>
    </div>
    <div v-loading="ProductSchedules.length === 0">
      <ul class="ShippingMethod__Resume__ProductList ProductList">
        <div v-for="(productSchedule, index) in ProductSchedules" :key="index">
          <li
            class="ProductList__Item"
            :data-product-id="productSchedule.product_id"
            :data-variant-id="productSchedule.variant_id"
            :data-product-title="productSchedule.title"
            data-customer-ready-visible
          >
            <div class="ProductList__Item__image">
              <div class="ProductList__Item-thumbnail">
                <div class="ProductList__Item-thumbnail__wrapper">
                  <img
                    :alt="productSchedule.title"
                    class="ProductList__Item-thumbnail__image"
                    :src="productSchedule.image"
                  />
                </div>
              </div>
            </div>
            <div class="ProductList__Item__description">
              <span class="ProductList__Item__description__variant ProductList__Item__description--small-text">
                {{productSchedule.title}}
              </span>
              <span class="ProductList__Item__description__name ProductList__Item__description--emphasis-text"
              v-if="productSchedule.externalId">
                <!-- {{ GetInventoryMessage(productSchedule.variant_id, method) }} -->
                Ticket: {{ productSchedule.externalId }}
              </span>
            </div>
            <div class="ProductList__Item__action">
              <el-button
                class="scheduleButton"
                :type="GetScheduleButtonType(productSchedule)"
                @click="schedule(productSchedule)"
                v-loading="GetScheduleButtonType(productSchedule) === 'block'"
                element-loading-text="Gerando ticket de agendamento"
                element-loading-spinner="el-icon-loading"

              >
                {{ GetScheduleBookDescription(productSchedule) }}
              </el-button>
            </div>
          </li>
          <br>
        </div>
      </ul>
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import productListMixin from '@/_mixins/productList'
import API from 'axios'
// import gsap from 'gsap';

export default {
  name: 'ProductScheduling',
  data () {
    return {
      intervalId: null,
      schedules: [],
      productSchedules: []
    }
  },
  async mounted () {
    await this.checkStatus()
    this.intervalId = window.setInterval(() => {
      this.checkStatus()
    }, 10000)
  },
  mixins: [productListMixin],
  props: ['products', 'method', 'show-shipping-price'],
  computed: {
    ...mapGetters(['InventoryIncomings', 'CheckoutConfig', 'Checkout', 'ShippingMethods', 'AppConfig']),
    ProductSchedules () {
      return this.productSchedules
    }
  },
  methods: {
    ...mapFilters(['formatMoney', 'dateFormat']),
    schedule (productSchedule) {
      if (this.shouldSchedule(productSchedule)) {
        // window.location.href = `${this.AppConfig.product_scheduling?.refirect_url}/${productSchedule.externalId}/holderInfo`
        window.open(' https://agenda-icp-brasil.validcertificadora.com.br/scheduler-web/pages/public/client/clientScheduler.jsf?content=3ZNvYfM%2BQQzcnFQ5A0auMyISxo%2BjpQME3cNUsqy2IMrNKeBPWGRG0vSyL5KbY0DvKzy2p4xlzjpr%0D%0AjiretcFqNg%3D%3D')
      }
    },
    GetScheduleButtonType (productSchedule) {
      if (this.shouldSchedule(productSchedule)) {
        return 'primary'
      }
      return 'block'
    },
    GetScheduleBookDescription (productSchedule) {
      if (this.shouldSchedule(productSchedule)) {
        return 'Agendar'
      }
    },
    shouldSchedule (productSchedule) {
      return productSchedule.statuses.some((status) => status.statusName === 'AvailableForSchedule')
    },
    mountProductSchedule () {
      this.productSchedules = this.schedules.map((schedule) => {
        var product = this.products.find(p => p.sku === schedule.product.sku)
        return {
          ...product,
          ...schedule
        }
      })
    },
    async checkStatus () {
      this.schedules = (await API.get(`${this.AppConfig.product_scheduling?.ticket_status_url}?checkoutId=${this.Checkout.id}`)).data
    }
  },
  watch: {
    schedules: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.mountProductSchedule()

        if (val.every((schedule) => schedule.statuses.some((status) => status.statusName === 'AvailableForSchedule'))) {
          clearInterval(this.intervalId)
        }
      }
    }
  }
}
</script>
<style>
.scheduleButton {
  min-height: 60px !important;
}

.scheduleButton .el-loading-spinner .el-loading-text {
  font-size: 11px !important;
}
</style>
