import eventBus from '../../helpers/eventBus'
import EvHubPaymentValidator from './evHubPaymentValidator'
import { PaymentServices } from './paymentServices'
import axios from 'axios'

const evHubValidator = EvHubPaymentValidator
var cardHash = null
var errorMessage = null

export class ZoopServices extends PaymentServices {
  async createPaymentHash (number, cvv, expirationMonth, expirationYear) {
    var publicKey = this.pubKey.split(':')[1]
    var marketplaceId = this.pubKey.split(':')[0]
    var ZOOP_URL = `https://api.zoop.ws/v1/marketplaces/${marketplaceId}/cards/tokens`

    number = number.split(' ').join('')

    try {
      var payload = {
        holder_name: this.userName,
        expiration_month: expirationMonth,
        expiration_year: expirationYear,
        card_number: number,
        security_code: cvv
      }
      var response = await axios.post(ZOOP_URL, payload, {
        auth: {
          username: `${publicKey}`,
          password: ''
        }
      })
      if (response.data) {
        return response.data.id
      } else {
        throw new Error('Cartão inválido')
      }
    } catch (e) {
      debugger
      errorMessage = e.message.msg
      if (e.message.msg) {
        eventBus.$emit('creditCardHashFailed', errorMessage)
      }
    }

    eventBus.$emit('creditCardHashCreated', cardHash)

    return cardHash
  }

  validateNumber (cardNumber) {
    return evHubValidator.validateCardNumber(cardNumber)
  }

  getCardBrand (cardNumber) {
    return evHubValidator.getCardBrand(cardNumber)
  }

  validateSecurityCode (cardNumber, securityCode) {
    if (securityCode && securityCode >= 3) {
      var isValid = evHubValidator.validateSecurityCode(securityCode)
      return isValid && !errorMessage
    }
    return false
  }

  validateExpirationDate (month, year) {
    return evHubValidator.validateExpirationDate(month, year)
  }
}
