import API from '@/api'

const state = {
  InventoryIncomings: null
}

const getters = {
  InventoryIncomings: (state) => {
    return state.InventoryIncomings
  }
}

const actions = {
  GetInventoryIncomings ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      API.get('/InventoryIncomings').then(resp => {
        commit('SetInventoryIncomings', resp.data)
        resolve(resp)
      }).catch(err => {
        dispatch('setAnalyticsError', { id: `ERROR_INCOMINGS`, method: 'GetInventoryIncomings', error: `${err.message}`, fatal: true })
        reject(new Error(`GetIncomings: ${err.message}`))
      })
    })
  }
}

const mutations = {
  SetInventoryIncomings: function (state, InventoryIncomings) {
    state.InventoryIncomings = InventoryIncomings
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
