import store from '../store'
import router from '../router'

class RouterService {
  _steps = [
    {
      stepNumber: 1,
      url: 'contact_information'
    },
    {
      stepNumber: 2,
      url: 'shipping_methods'
    },
    {
      stepNumber: 3,
      url: 'payment_methods'
    },
    {
      stepNumber: 10,
      url: 'Order'
    }
  ]
  goTo (uri) {
    router.push(uri)
      .then(() => {
      })
      .catch(err => {
        console.log(err)
      })
  }

  getStepObj (query, key) {
    return this._steps.filter((step) => {
      return step[key] === query
    })[0]
  }

  getActiveStep () {
    const _store = store
    if (_store.getters.Checkout) {
      return this.getStepObj(_store.getters.currentStep, 'stepNumber')
    }
    return false
  }

  async goToNextStep () {
    const _store = store
    let nextStep = _store.getters.currentStep < 3 ? _store.getters.currentStep + 1 : 10
    _store.dispatch('SetCheckoutStep', nextStep)
  }

  async goToPrevStep () {
    const _store = store
    let prevStep = _store.getters.currentStep - 1
    console.log('Prev: ', prevStep)
    _store.dispatch('SetCheckoutStep', prevStep)
  }
  async goToStep (step) {
    const _store = store
    let stepURL = this.getStepObj(step, 'stepNumber').url
    if (stepURL === 'Order') {
      this.goTo('/Order/' + _store.getters.Checkout.id)
    } else if (_store.getters.Checkout) {
      this.goTo('/Checkout/' + _store.getters.Checkout.id + '/' + stepURL)
    }
  }
}
export default new RouterService()
