import API from '@/api'

const state = {
  IsNewBillingInfo: false
}
const getters = {
  IsNewBillingInfo: (state) => {
    return state.IsNewBillingInfo
  }
}
const actions = {
  async SetBillingInfo ({ commit, dispatch }, { checkoutId, billingInfo }) {
    await API.post(`Checkouts/${checkoutId}/billingInfo`, billingInfo).then(resp => {
      dispatch('UpdateCheckoutState', resp.data)
    }).catch(err => {
      console.log('SetBillingInfo error: ', err)
    })
  },
  async SetBillingInfoFromShipping ({ commit, dispatch }, { checkoutId, shippingInfo }) {
    var billingInfo = {
      address: {
        zip_code: shippingInfo.address.zip_code,
        street_number: shippingInfo.address.street_number,
        city: shippingInfo.address.city,
        neighborhood: shippingInfo.address.neighborhood,
        street: shippingInfo.address.street,
        state: shippingInfo.address.state,
        complement: shippingInfo.address.complement
      }
    }
    await API.post(`Checkouts/${checkoutId}/billingInfo`, billingInfo).then(resp => {
      dispatch('UpdateCheckoutState', resp.data)
    }).catch(err => {
      console.log('SetBillingInfo error: ', err)
    })
  },
  IsNewBillingInfo ({ commit }, isNew) {
    commit('IsNewBillingInfo', isNew)
  }
}
const mutations = {
  IsNewBillingInfo: function (state, isNew) {
    state.IsNewBillingInfo = isNew
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
