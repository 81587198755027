<template>
  <el-dialog
    title="Produtos Indisponíveis"
    :visible.sync="ShowUnavailableItems"
    :before-close="handleClose"
  >
    <h5>Devido a falta de estoque, seu carrinho foi alterado para atender a quantidade de produtos disponíveis</h5>
    <p>Os seguintes itens foram removidos do seu carrinho</p>
    <br />
    <ProductResume :unavailableItems="UnavailableItems" />
    <span slot="footer" class="dialog-footer">
      <el-button @click="redirectToStore">
        <i v-if="this.Checkout.products.length > 0" class="el-icon-arrow-left"></i> Voltar à Loja
      </el-button>
      <el-button v-if="this.Checkout.products.length > 0" @click="handleClose" type="primary">
        Continuar compra
        <i class="el-icon-arrow-right"></i>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProductResume from './ProductResume'

export default {
  name: 'UnavailableItemsModal',
  components: {
    ProductResume
  },
  created () {
    if (this.ShowUnavailableItems) {
      this.GetCheckout(this.Checkout.id)
      this.GetPaymentOptions(this.Checkout.id)
    }
  },
  computed: {
    ...mapGetters([
      'UnavailableItems',
      'ShowUnavailableItems',
      'Checkout',
      'CheckoutConfig',
      'UpdateCheckout'
    ])
  },
  beforeDestroy () {
    this.CloseUnavailableItems()
  },
  methods: {
    ...mapActions(['CloseUnavailableItems', 'GetCheckout', 'GetPaymentOptions']),
    handleClose (done) {
      if (this.Checkout.products.length > 0) {
        this.GetCheckout(this.Checkout.id).then((_) => {
          this.CloseUnavailableItems()
        })
      } else {
        this.redirectToStore()
      }
    },
    redirectToStore () {
      window.location.href = `${this.CheckoutConfig.shopify_api.domain}`
    }
  }
}
</script>
