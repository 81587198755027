import API from '@/api'
import Search from '@/services/search'

const state = {
  Additions: []
}

const getters = {
  Additions: function (state) {
    return state.Additions
  }
}

const actions = {
  async AddAddition ({ commit, getters, dispatch }, { id, category, amount, name }) {
    if (id !== undefined && category !== undefined && amount !== undefined && name !== undefined) {
      commit('AddAddition', { id, category, amount, name })
    }
  },
  async DeleteAddition ({ commit, getters, dispatch }, { key, value }) {
    commit('DeleteAddition', { key, value })
  },
  async ApplyAdditionCode ({ commit, getters, dispatch }, { checkoutId, additionCode }) {
     //eslint-disable-line
    dispatch('setCheckoutStatus', {
      AdditionCode: {
        update: 'LOADING'
      }
    })
    await API.post(`/checkouts/Addition?checkoutId=${checkoutId}&code=${additionCode}`, {}).then(resp => {
      console.log('Addition resp: ', resp)
       //eslint-disable-line

      dispatch('setCheckoutStatus', {
        AdditionCode: {
          update: 'IDLE'
        }
      })
      dispatch('AddAddition', {
        id: `addition_coupon--${additionCode}`,
        category: 'addition_coupon',
        amount: resp.data.addition_code.amount,
        name: `Cupom ${additionCode}`
      })
      commit('UpdateCheckoutState', {
        addition_code: {
          code: additionCode,
          amount: resp.data.addition_code.amount,
          additionType: resp.data.addition_code.additionType,
          valid: resp.data.addition_code.valid
        },
        totals: {
          ...getters.Checkout.totals,
          total_addition: resp.data.totals.total_addition,
          total_without_additions: resp.data.totals.total_without_additions,
          total: resp.data.totals.total
        }
      })
    }).catch(err => {
      dispatch('setCheckoutStatus', {
        AdditionCode: {
          update: 'IDLE'
        }
      })
      dispatch('setAnalyticsError', { id: `checkoutId=${checkoutId}&code=${additionCode}`, method: 'ApplyAdditionCode', error: `Código de desconto inválido: ${err.message}`, fatal: false })
      return new Error(`Código de desconto inválido: ${err.message}`)
    })
  },
  async RemoveAdditionCode ({ commit, dispatch, getters }, { checkoutId, additionCode }) {
    await API.delete(`/checkouts/Addition?code=${additionCode}&checkoutId=${checkoutId}`).then(resp => {
      dispatch('DeleteAddition', { key: 'id', value: `addition_coupon--${additionCode}` })
    }).catch(err => {
      dispatch('setAnalyticsError', { id: `checkoutId=${checkoutId}&code=${additionCode}`, method: 'RemoveAdditionCode', error: `Código de desconto inválido: ${err.message}`, fatal: false })
      return new Error(`Código de desconto inválido: ${err.message}`)
    })
  }
}

const mutations = {
  AddAddition: function (state, addition) {
    let additionsARR = [...state.Additions]
    const additionIndex = state.Additions.findIndex((additionItem) => additionItem.id === addition.id)

    additionIndex === -1
      ? additionsARR.push(addition)
      : additionsARR[additionIndex] = addition

    state.Additions = additionsARR
  },
  DeleteAddition: function (state, { key, value }) {
    if (value) {
      state.Additions = Search.query(state.Additions, key, 'not-equals', value)
    } else {
      state.Additions = Search.query(state.Additions, key, 'equals', null)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
