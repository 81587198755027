import API from '@/api'
import EventBus from '@/helpers/eventBus'
import checkoutBase from '@/services/checkoutBase'
import Tracking from '@/services/tracking'
import RouterService from '../services/routerService'
import CustomAttributes from './Checkout/custom_attributes'
import DraftOrder from './Checkout/draft_order'
// import jsonp from 'jsonp'

const state = {
  ...CustomAttributes.state,
  ...DraftOrder.state,
  currentStep: null,
  _CheckoutStatus: 'POLLING',
  Checkout: null
}
const getters = {
  ...CustomAttributes.getters,
  ...DraftOrder.getters,
  _CheckoutStatus: function (state) {
    return state._CheckoutStatus
  },
  Checkout: function (state) {
    return state.Checkout
  },
  currentStep: function (state) {
    return state.currentStep
  }
}
const actions = {
  ...CustomAttributes.actions,
  ...DraftOrder.actions,
  setLoyaltyOptin ({ commit }, isOptinAccepted) {
    commit('setLoyaltyOptin', isOptinAccepted)
  },
  async CreateCheckout ({ commit, getters, dispatch }, cartToken) {
    dispatch('setCheckoutStatus', {
      Checkout: {
        create: 'LOADING'
      }
    })
    if (!cartToken) {
      dispatch('setCheckoutStatus', {
        Checkout: {
          create: 'CART_EMPTY'
        }
      })
      dispatch('setAnalyticsError', { id: 'CART_EMPTY', method: 'CreateCheckout', error: 'CART_EMPTY', fatal: true })
    } else {
      await API.post(`/checkouts/${cartToken}`)
        .then(checkout => {
          let _EvolveHubDiscountCode = localStorage.getItem('discount_code')
          let _EvolveHubZipCode = localStorage.getItem('zipcode')
          checkout.data.customer.state_subscription = checkout.data.customer.state_subscription || null
          if (checkout.data.activeStep === 3 && checkout.data.requires_shipping) {
            checkout.data.activeStep = 2
          }
          if (_EvolveHubZipCode) {
            checkout.data.shipping_info.address.zip_code = _EvolveHubZipCode
          }

          dispatch('setCheckoutStatus', {
            Checkout: {
              create: 'IDLE'
            }
          })
          if (_EvolveHubDiscountCode) {
            dispatch('ApplyDiscountCode', {
              checkoutId: checkout.data.id,
              discountCode: _EvolveHubDiscountCode
            })
            localStorage.removeItem('discount_code')
          }
          dispatch('GetCheckoutConfig', checkout.data.id).then(() => {
            Tracking.setCompany(getters.CheckoutConfig.company.name)
            commit('SetCheckoutState', checkout.data)
          })
          EventBus.$emit('checkout-created')
        })
        .catch((e) => {
          console.log(e)
          console.log(e.message)
          dispatch('setCheckoutStatus', {
            Checkout: {
              create: 'ERROR'
            }
          })
          dispatch('_buildErrorAlert', {
            title: 'Erro no CreateCheckout',
            error: `Ocorreu um erro com a criação do seu carrinho: ${e}`
          })
          dispatch('setAnalyticsError', { id: `${cartToken}`, method: 'CreateCheckout', error: e.message, fatal: true })
          commit('SetCheckoutState', null)
        })
    }
  },
  async CreateCheckoutWithCustomer ({ commit, getters, dispatch }, { cartToken, customer }) {
    dispatch('setCheckoutStatus', {
      Checkout: {
        create: 'LOADING'
      }
    })
    if (!cartToken) {
      dispatch('setCheckoutStatus', {
        Checkout: {
          create: 'CART_EMPTY'
        }
      })
      dispatch('setAnalyticsError', { id: 'CART_EMPTY', method: 'CreateCheckout', error: 'CART_EMPTY', fatal: true })
      return
    }

    await API.post(`v2/checkouts/${cartToken}`, {
      checkout_customer_optin: false,
      customer: {
        first_name: customer.first_name,
        last_name: customer.last_name,
        document: customer.default_address?.company,
        email: customer.email,
        phone: customer.default_address?.phone,
        date_of_birth: customer.birthday
      },
      shipping_info: {
        person: {
          full_name: `${customer.first_name} ${customer.last_name}`,
          phone: customer.default_address?.phone,
          first_name: customer.first_name,
          last_name: customer.last_name
        },
        address: {
          zip_code: customer.default_address?.zip,
          city: customer.default_address?.city,
          state: customer.default_address?.province,
          state_short: customer.default_address?.province_code,
          complement: customer.default_address?.address2
        }
      },
      billing_info: {
        first_name: customer.first_name,
        last_name: customer.last_name,
        document: customer.default_address?.company,
        email: customer.email,
        phone: customer.phone
      }
    })
      .then(checkout => {
        let _EvolveHubDiscountCode = localStorage.getItem('discount_code')
        let _EvolveHubZipCode = localStorage.getItem('zipcode')
        checkout.data.customer.state_subscription = checkout.data.customer.state_subscription || null
        if (checkout.data.activeStep === 3 && checkout.data.requires_shipping) {
          checkout.data.activeStep = 2
        }
        if (_EvolveHubZipCode) {
          checkout.data.shipping_info.address.zip_code = _EvolveHubZipCode
        }

        dispatch('setCheckoutStatus', {
          Checkout: {
            create: 'IDLE'
          }
        })
        if (_EvolveHubDiscountCode) {
          dispatch('ApplyDiscountCode', {
            checkoutId: checkout.data.id,
            discountCode: _EvolveHubDiscountCode
          })
          localStorage.removeItem('discount_code')
        }
        dispatch('GetCheckoutConfig', checkout.data.id).then(() => {
          Tracking.setCompany(getters.CheckoutConfig.company.name)
          commit('SetCheckoutState', checkout.data)
        })
        EventBus.$emit('checkout-created')
      })
      .catch((e) => {
        console.log(e)
        console.log(e.message)
        dispatch('setCheckoutStatus', {
          Checkout: {
            create: 'ERROR'
          }
        })
        dispatch('_buildErrorAlert', {
          title: 'Erro no CreateCheckout',
          error: `Ocorreu um erro com a criação do seu carrinho: ${e}`
        })
        dispatch('setAnalyticsError', { id: `${cartToken}`, method: 'CreateCheckout', error: e.message, fatal: true })
        commit('SetCheckoutState', null)
      })
  },
  async GetCheckout ({ commit, dispatch }, CheckoutID) {
    return new Promise((resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Checkout: {
          get: 'LOADING'
        }
      })
      API.get('/checkouts/' + CheckoutID)
        .then(checkout => {
          let _EvolveHubDiscountCode = localStorage.getItem('discount_code')
          let _EvolveHubZipCode = localStorage.getItem('zipcode')
          checkout.data.customer.state_subscription = checkout.data.customer.state_subscription || null

          if (checkout.data.activeStep === 3 && checkout.data.requires_shipping) {
            checkout.data.activeStep = 2
          }
          if (_EvolveHubZipCode) {
            checkout.data.shipping_info.address.zip_code = _EvolveHubZipCode
          }

          localStorage.removeItem('zipcode')
          if (_EvolveHubDiscountCode) {
            dispatch('ApplyDiscountCode', {
              checkoutId: checkout.data.id,
              discountCode: _EvolveHubDiscountCode
            })
            localStorage.removeItem('discount_code')
          }

          dispatch('setCheckoutStatus', {
            Checkout: {
              get: 'IDLE'
            }
          })
          dispatch('addLineItemsDiscounts', checkout.data)
          dispatch('GetCheckoutConfig', checkout.data.id).then(() => {
            commit('SetCheckoutState', checkout.data)
            EventBus.$emit('checkout-updated')
            resolve(checkout.data)
          })
        })
        .catch(err => {
          dispatch('setCheckoutStatus', {
            Checkout: {
              get: 'ERROR'
            }
          })
          commit('SetCheckoutState', null)
          dispatch('setAnalyticsError', { id: `${CheckoutID}`, method: 'GetCheckout', error: err.message, fatal: true })
          reject(new Error('Error GetCheckout: ' + err))
        })
    })
  },
  async GetCheckoutV2 ({ commit, dispatch }, CheckoutID) {
    return new Promise((resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Checkout: {
          get: 'LOADING'
        }
      })
      API.get(`/v2/checkouts/${CheckoutID}`)
        .then(checkout => {
          let _EvolveHubDiscountCode = localStorage.getItem('discount_code')
          let _EvolveHubZipCode = localStorage.getItem('zipcode')
          checkout.data.customer.state_subscription = checkout.data.customer.state_subscription || null

          if (checkout.data.activeStep === 3 && checkout.data.requires_shipping) {
            checkout.data.activeStep = 2
          }
          if (_EvolveHubZipCode) {
            checkout.data.shipping_info.address.zip_code = _EvolveHubZipCode
          }

          localStorage.removeItem('zipcode')
          if (_EvolveHubDiscountCode) {
            dispatch('ApplyDiscountCode', {
              checkoutId: checkout.data.id,
              discountCode: _EvolveHubDiscountCode
            })
            localStorage.removeItem('discount_code')
          }

          dispatch('setCheckoutStatus', {
            Checkout: {
              get: 'IDLE'
            }
          })
          dispatch('addLineItemsDiscounts', checkout.data)
          dispatch('GetCheckoutConfig', checkout.data.id).then(() => {
            commit('SetCheckoutState', checkout.data)
            EventBus.$emit('checkout-updated')
            resolve(checkout.data)
          })
        })
        .catch(err => {
          dispatch('setCheckoutStatus', {
            Checkout: {
              get: 'ERROR'
            }
          })
          commit('SetCheckoutState', null)
          dispatch('setAnalyticsError', { id: `${CheckoutID}`, method: 'GetCheckout', error: err.message, fatal: true })
          reject(new Error('Error GetCheckout: ' + err))
        })
    })
  },
  async RecoverCheckout ({ commit, dispatch }, { checkoutToken, recoveryKey }) {
    return new Promise((resolve, reject) => {
      if (!checkoutToken || !recoveryKey) {
        dispatch('setCheckoutStatus', {
          Checkout: {
            recover: 'MISSING_MANDATORY_FIELDS'
          }
        })
        dispatch('setAnalyticsError', { id: `Missing mandatory fields`, method: 'RecoverCheckout', error: 'Missing mandatory fields', fatal: true })
        reject(new Error('RecoverCheckout: Missing mandatory fields'))
      }
      dispatch('setCheckoutStatus', {
        Checkout: {
          recover: 'LOADING'
        }
      })
      API.get(`/checkouts/recover/${checkoutToken}/${recoveryKey}`)
        .then(checkout => {
          dispatch('setCheckoutStatus', {
            Checkout: {
              recover: 'IDLE'
            }
          })
          dispatch('addLineItemsDiscounts', checkout.data)
          dispatch('GetCheckoutConfig', checkout.data.id).then(() => {
            commit('SetCheckoutState', checkout.data)
            EventBus.$emit('checkout-updated')
            resolve(checkout.data)
          })
        })
        .catch((err) => {
          dispatch('setCheckoutStatus', {
            Checkout: {
              recover: 'ERROR'
            }
          })
          dispatch('setAnalyticsError', { id: `${checkoutToken}/${recoveryKey}`, method: 'RecoverCheckout', error: err.message, fatal: true })
          commit('SetCheckoutState', null)
          reject(new Error(`RecoverCheckout: ${err.message}`))
        })
    })
  },
  UpdateCheckout ({ commit, dispatch }, Checkout) {
    return new Promise((resolve, reject) => {
      API.put('/checkouts/' + Checkout.id, Checkout)
        .then(checkout => {
          commit('SetCheckoutState', checkout.data)
          EventBus.$emit('checkout-updated')
          RouterService.goToNextStep()
          resolve()
        })
        .catch((err) => {
          dispatch('setAnalyticsError', { id: `${Checkout.id}`, method: 'UpdateCheckout', error: err.message, fatal: true })
          reject(new Error(`UpdateCheckout: ${err.message}`))
        })
    })
  },
  async validateCheckout ({ commit, getters, dispatch }, Checkout) {
    return new Promise(async (resolve, reject) => {
      try {
        await checkoutBase.validate()
        resolve(true)
      } catch (err) {
        dispatch('setAnalyticsError', { id: `${getters.Checkout.id}`, method: 'ValidateCheckout', error: err.message, fatal: true })
        dispatch('_buildErrorAlert', {
          title: 'Erro no Checkout',
          error: `${err.message}`
        })
        reject(new Error(`validateCheckout: ${err.message}`))
      }
    })
  },
  // TODO: Mover para routerService
  goTo (uri) {
    RouterService.goTo(uri)
  },
  goToStep ({ commit }, step) {
    RouterService.goToStep(step)
  },
  async goToNextStep () {
    RouterService.goToNextStep()
  },
  async goToPrevStep () {
    RouterService.goToPrevStep()
  },
  async SetCheckoutState ({ commit }, value) {
    commit('SetCheckoutState', value)
  },
  async UpdateCheckoutState ({ commit }, value) {
    commit('UpdateCheckoutState', value)
  },
  async SetCheckoutStep ({ commit }, value) {
    commit('SetCheckoutStep', value)
  }

}
const mutations = {
  ...CustomAttributes.mutations,
  ...DraftOrder.mutations,
  setLoyaltyOptin: function (state, isOptinAccepted) {
    state.Checkout.customer.loyalty_optin = isOptinAccepted
  },
  SetCheckoutState: function (state, Checkout) {
    state.currentStep = !state.currentStep ? (Checkout ? Checkout.activeStep : 1) : state.currentStep
    state.Checkout = (Checkout)
  },
  UpdateCheckoutState: function (state, Data) {
    state.Checkout = {
      ...state.Checkout,
      ...Data
    }
  },
  SetCheckoutStep: function (state, step) {
    state.currentStep = step
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
