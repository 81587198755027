/* eslint-disable no-useless-escape */
class StringHelper {
  hasSpecialCharacters(s) {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(s)
  }

  hasLetters(s) {
    return /[a-zA-Z]/g.test(s)
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s)
  }

  removeSpecialChars(s) {
    return s.replace(/[^\w\s]/gi, '')
  }
}
var stringHelper = new StringHelper()
export { stringHelper }
