/* eslint-disable curly */
import { PaymentServices } from './paymentServices'
import axios from 'axios'

const MERCADOPAGO_URL = 'https://api.mercadopago.com'

function _setDeviceTag () {
  var scriptTag = document.createElement('script')
  scriptTag.src = 'https://www.mercadopago.com/v2/security.js'
  scriptTag.setAttribute('view', 'checkout')
  scriptTag.setAttribute('output', 'deviceId')
  document.body.appendChild(scriptTag)
}

export class MercadoPagoServices extends PaymentServices {
  constructor () {
    super()
    _setDeviceTag()
  }

  setCardBrand (brand) {
    cardBrand = brand
  }

  async createPaymentHash (number, cvv, expirationMonth, expirationYear) {
    if (this.pubKey) {
      var body = {
        'card_number': number.replace(/\s/g, ''),
        'expiration_year': 2000 + parseInt(expirationYear),
        'expiration_month': parseInt(expirationMonth),
        'security_code': cvv,
        'cardholder': {
          'identification': {
            'type': this.documentType,
            'number': this.documentNumber.replace(/[-]/g, '').replace('/', '')
          },
          'name': this.userName
        }
      }

      var response = await axios({
        method: 'post',
        url: `${MERCADOPAGO_URL}/v1/card_tokens?public_key=${this.pubKey}`,
        data: body
      })
      if (response.data.luhn_validation) {
        return response.data.id
      } else {
        throw new Error('Cartão inválido')
      }
    }
    throw new ReferenceError('PubKey cannot be null or undefined')
  }

  validateNumber (number) {
    var isValid = cards.filter(card => (card.pattern.test(number.replace(/\s+/g, '')) && card.exclusion_pattern.test(number.replace(/\s+/g, '')) === false))[0]
    if (isValid)
      return true
    return false
  }

  validateSecurityCode (securityCode) {
    var card = cards.filter(c => c.brand === cardBrand)[0]
    if (card)
      return super.validateSecurityCode(securityCode, card.security_code.length)
    return super.validateSecurityCode(securityCode, 3)
  }

  getCardBrand (number) {
    var card = cards.filter(card => (card.pattern.test(number.replace(/\s+/g, '')) && card.exclusion_pattern.test(number.replace(/\s+/g, '')) === false))[0]
    if (card) {
      this.setCardBrand(card.brand)
      return card.brand
    }
    return ''
  }
}

var cardBrand = ''
var cards = [
  {
    brand: 'master',
    pattern: /^(5|(2(221|222|223|224|225|226|227|228|229|23|24|25|26|27|28|29|3|4|5|6|70|71|720)))/,
    exclusion_pattern: /^(502121|506721|506722|506776|536969|589916|(50670[7-8])|(506715)|(50671[7-9])|(50672[0-1])|(50672[4-9])|(50673[0-3])|(506739)|(50674[0-8])|(50675[0-3])|(50677[4-8])|(50900[0-9])|(50901[3-9])|(50902[0-9])|(50903[1-5])|(50903[8-9])|(50904[0-9])|(50905[0-9])|(50906[0-4])|(50906[6-9])|(50907[0-2])|(50907[4-5])|(504175)|(50907[6-9])|(50908[0-9])|(509[0-7][0-9]{2})|(509[8]0[0-9])|(532884)|509810|506755)/,
    security_code: {
      length: 3
    }
  },
  {
    brand: 'hipercard',
    pattern: /^((606282)|(637095)|(637568)|(637599)|(637609)|(637612))/,
    exclusion_pattern: /^(0000)/,
    security_code: {
      length: 3
    }
  },
  {
    brand: 'elo',
    pattern: /^((50670[7-8])|506715|(50671[8-9])|(50672[0-1])|(50672[4-9])|(50673[0-3])|506739|(50674[1-3])|(50674[5-7])|506753|(50677[4-8])|(50900[0-2])|(50900[4-7])|509009|(50901[0-2])|509014|(50902[0-9])|509030|(50903[5-9])|(50904[0-2])|(50904[4-9])|(50905[0-9])|(50906[0-4])|(50906[6-9])|(50907[0-2])|(50907[4-9])|(50908[0-9])|(50909[1-2])|(50909[5-9])|(50910[0-1])|(50910[6-9])|(50911[0-9])|(50912[0-9])|(50913[0-9])|(50914[0-9])|(50915[0-9])|(50916[0-9])|(50917[0-9])|(50918[0-9])|(50919[0-9])|(50920[0-9])|(50921[0-9])|(50922[0-9])|(50923[0-9])|(50924[0-9])|(50925[0-9])|(50926[0-9])|(50927[0-9])|(50928[0-9])|(50929[0-9])|(50930[0-9])|(50931[0-9])|(50932[0-9])|(50933[0-9])|(50934[0-9])|(50935[0-9])|(50936[0-9])|(50937[0-9])|(50938[0-9])|(50939[0-9])|(50940[0-9])|(50941[0-9])|(50942[0-9])|(50943[0-9])|(50944[0-9])|(50945[0-9])|(50946[0-9])|(50947[0-9])|(50948[0-9])|(50949[0-9])|(50950[0-9])|(50951[0-9])|(50952[0-9])|(50953[0-9])|(50954[0-9])|(50955[0-9])|(50956[0-9])|(50957[0-9])|(50958[0-9])|(50959[0-9])|(50960[0-9])|(50961[0-9])|(50962[0-9])|(50963[0-9])|(50964[0-9])|(50965[0-9])|(50966[0-9])|(50967[0-9])|(50968[0-9])|(50969[0-9])|(50970[0-9])|(50971[0-9])|(50972[0-9])|(50973[0-9])|(50974[0-9])|(50975[0-9])|(50976[0-9])|(50977[0-9])|(50978[0-9])|(50979[0-9])|(50980[0-7])|(50983[1-9])|(50984[0-9])|(50985[0-9])|(50986[0-9])|(50987[0-7])|(50989[7-9])|509900|(50991[8-9])|(50992[0-9])|(50993[0-9])|(50994[0-9])|(50995[0-9])|(50996[0-4])|(50997[1-9])|(50998[0-6])|(50999[5-9])|636368|(65040[6-9])|(65041[0-9])|(65042[0-9])|(65043[0-9])|(65048[5-9])|(65049[0-9])|(65050[0-4])|(65050[6-9])|(65051[0-9])|(65052[0-9])|(65053[0-8])|(65055[2-9])|(65056[0-9])|(65057[0-9])|(65058[0-9])|(65059[0-8])|(65072[0-7])|(65090[1-9])|(65091[0-9])|(65092[0-2])|650928|650939|(65094[6-9])|(65095[0-9])|(65096[0-9])|(65097[0-8])|(65165[2-9])|(65166[0-9])|(65167[0-9])|(65168[0-9])|(65169[0-9])|(65170[0-4])|(65500[0-9])|(65501[0-9])|(65502[1-9])|(65503[0-9])|(65504[0-9])|(65505[0-7]))/,
    exclusion_pattern: /^(509023)/,
    security_code: {
      length: 3
    }
  },
  {
    brand: 'visa',
    pattern: /^(4)/,
    exclusion_pattern: /^(400163|400176|400178|400185|400199|423808|439267|471233|473200|476332|482481|451416|438935|(40117[8-9])|(45763[1-2])|457393|431274)/,
    security_code: {
      length: 3
    }
  },
  {
    brand: 'amex',
    pattern: /^((34)|(37))/,
    exclusion_pattern: /^((384100)|(384140)|(384160))/,
    security_code: {
      length: 4
    }
  }]
