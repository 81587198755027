/* eslint-disable no-unused-vars */
// import { XMLParser } from 'fast-xml-parser'
import axios from 'axios'
import {
  MaxiPagoCard,
  AddConsumerRequest,
  CreateCardTokenRequest } from './entities'
import { MaxiPagoCustomer } from './entities/MaxiPagoCustomer'
import { Base64Helper } from '../../helpers/base64Helper'

export class MaxiPagoApi {
  #createUserEndpoint = 'UniversalAPI/postAPI'
  #createCardTokenEndpoint = 'UniversalAPI/postAPI'
  #urlBase = 'https://testapi.maxipago.net'
  #merchantId = ''
  #merchantKey = ''
  customerId = ''
  cardToken = ''

  constructor(merchantId, merchantKey) {
    this.#merchantId = merchantId
    this.#merchantKey = merchantKey
  }

  get #successStatusCode() {
    return 200
  }

  /**
   * @param {MaxiPagoCustomer} customer
   */
  async createUser(customer) {
    // try {
    //   let addConsumerRequest = new AddConsumerRequest(this.#merchantId, this.#merchantKey)
    //   addConsumerRequest.request = customer

    //   var response = await axios.post(`${this.#urlBase}/${this.#createUserEndpoint}`,
    //     addConsumerRequest.toXml(),
    //     {
    //       headers: {
    //         'Content-Type': 'application/xml'
    //       }
    //     }
    //   )

    //   if (response.status === this.#successStatusCode) {
    //     let xmlParser = new XMLParser()
    //     var responseData = xmlParser.parse(response.data)

    //     this.customerId = responseData['api-response'].result.customerId
    //   }
    // } catch (error) {

    // }
  }

  /**
   * @param {MaxiPagoCard} card
   * @param {MaxiPagoCustomer} customer
   */
  async createCardToken(card, customer) {
    var body = { ...card, ...customer }
    var base64 = new Base64Helper()

    var response = await axios.post(`https://maxipago.azurewebsites.net/api/token?code=DDcSDc3pql1C19cAm-ruHiyqtswUPRphS9-5Bh0rJF13AzFu_MT-qQ==`,
      base64.encode(body)
    )

    if (response.status === this.#successStatusCode) {
      this.cardToken = response.data
    }

    return this.cardToken
  }

  // /**
  //  * @param {MaxiPagoCard} card
  //  */
  // async createCardToken(card) {
  //   try {
  //     let createCardTokenRequest = new CreateCardTokenRequest(this.#merchantId, this.#merchantKey)
  //     createCardTokenRequest.request = card

  //     var response = await axios.post(`${this.#urlBase}/${this.#createCardTokenEndpoint}`,
  //       createCardTokenRequest.toXml(),
  //       {
  //         headers: {
  //           'Content-Type': 'application/xml'
  //         }
  //       }
  //     )

  //     if (response.status === this.#successStatusCode) {
  //       let xmlParser = new XMLParser()
  //       var responseData = xmlParser.parse(response.data)

  //       this.cardToken = responseData['api-response'].result.token
  //     }
  //   } catch (error) {

  //   }

  //   return this.cardToken
  // }
}
