import { MaxiPagoApi } from '../../api/maxiPago/maxiPagoApi'
import { PaymentServices } from './paymentServices'
import { MaxiPagoCard, MaxiPagoCustomer } from '../../api/maxiPago/entities'
import validator from './evHubPaymentValidator'
import store from '@/store'

export class MaxiPagoServices extends PaymentServices {
  customerId = ''

  setPubKey (pubKey) {
    return super.setPubKey(pubKey)
  }

  setUserName (name) {
    return super.setUserName(name)
  }

  setDocumentNumber (documentNumber) {
    return super.setDocumentNumber(documentNumber)
  }

  async createPaymentHash (number, cvv, expirationMonth, expirationYear) {
    let merchantId = this.pubKey.split(':')[0]
    let merchantKey = this.pubKey.split(':')[1]

    const maxiPagoApi = new MaxiPagoApi(merchantId, merchantKey)
    var maxiPagoCustomer = this.#createMaxiPagoCustomer()
    // debugger
    // await maxiPagoApi.createUser(maxiPagoCustomer)
    var maxiPagoCard = new MaxiPagoCard(
      maxiPagoApi.customerId,
      number,
      expirationMonth,
      this.getExpirationYearForMaxiPago(expirationYear),
      this.userName,
      null,
      null,
      null,
      null,
      null,
      'BR',
      null,
      null,
      'use_once',
      store.getters.Checkout.totals.total
    )
    return maxiPagoApi.createCardToken(maxiPagoCard, maxiPagoCustomer)
  }

  getExpirationYearForMaxiPago(expirationYear) {
    if (!expirationYear) {
      return ''
    }

    if (expirationYear.length === 2) {
      return `20${expirationYear}`
    }

    return expirationYear
  }

  validateNumber (cardNumber) {
    return validator.validateCardNumber(cardNumber)
  }

  getCardBrand (cardNumber) {
    return validator.getCardBrand(cardNumber)
  }

  validateSecurityCode (cardNumber, securityCode) {
    var isValid = validator.validateSecurityCode(securityCode)

    return isValid
  }

  validateExpirationDate (month, year) {
    return validator.validateExpirationDate(month, year)
  }

  #createMaxiPagoCustomer() {
    return new MaxiPagoCustomer(
      1,
      store.getters.Checkout.customer.first_name,
      store.getters.Checkout.customer.last_name,
      null,
      null,
      null,
      null,
      null,
      'BR',
      null,
      null,
      '12/25/1970',
      null
    )
  }
}
