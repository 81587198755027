import axios from 'axios'

export class IOPayApi {
  #ioPayUrl = process.env.VUE_APP_IOPAY_URL
  #api = null
  constructor() {
    this.#api = axios.create({
      baseURL: this.#ioPayUrl,
      responseType: 'json'
    })
  }

  async authenticate(email, secret, sellerId) {
    return this.#api.post('/card/authentication', {
      email: email,
      secret: secret,
      io_seller_id: sellerId
    })
  }

  async tokenizeCard(authToken, body) {
    return this.#api.post('/card/tokenize/token', JSON.stringify(body), {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    })
  }
}
