import { mapGetters, mapActions } from 'vuex'
import Location from '@/services/location'
import Filters from '@/services/filters'
const geoMixin = {
  data () {
    return {
      addressObj: {},
      zipLoading: false,
      foundZipCodeResults: null
    }
  },
  computed: {
    ...mapGetters(['Checkout']),
    states () {
      return Location.states
    },
    addressForm () {
      let fullAddressForm = this.Checkout && this.Checkout.shipping_info ? { ...this.Checkout.shipping_info.address } : null
      delete fullAddressForm['zip_code']
      return fullAddressForm
    }
  },
  methods: {
    ...mapActions(['setAnalyticsError']),
    focusFirstInput () {
      let $this = this
      let $firstNullInput = Object.values($this.$refs).find(input => input && (!input.value || input.value === ''))
      // console.log($this.$refs)
      // console.log($firstNullInput)
      $firstNullInput.focus()
    },
    getZipInfos (zip) {
      const $this = this
      $this.zipLoading = true
      zip = zip.replace(/\D/g, '')
      return new Promise((resolve, reject) => {
        if (zip.length > 0 && zip.length !== 8) {
          $this.zipLoading = false
          return reject(Error('CEP não possui 8 digitos'))
        }
        return Location.zipInfo(zip)
          .then(addressObj => {
            $this.zipLoading = false
            if (!addressObj.state) {
              this.addressObj = null
              return reject(Error('CEP inválido'))
            }
            // this.showAddressForm = true
            addressObj.state = addressObj.state.name
            addressObj = Filters.objectClear(addressObj)

            $this.$nextTick(() => {
              setTimeout(() => {
                // $this.$refs['Checkout.shipping_info.address.street_number'].focus()
                this.focusFirstInput()
              }, 500)
            })
            resolve(addressObj)
          })
          .catch(function (error) {
            $this.zipLoading = false
            console.log(error)
            this.setAnalyticsError({ id: zip, method: 'getZipInfos', error: error.message, fatal: true })
          })
          .finally(function () {
            $this.zipLoading = false
          })
      })
    },
    findZipCodeResults (addressOBJ) {
      const $this = this

      addressOBJ.street = addressOBJ.street.replace(/[0-9]/g, '').replace(',', '')
      return Location.findZipCode(addressOBJ)
        .then(addressesList => {
          $this.foundZipCodeResults = addressesList.results
        })
        .catch(function (error) {
          $this.setAnalyticsError({ id: addressOBJ.street, method: 'findZipCodeResults', error: error.message, fatal: true })
          console.log(error)
        })
        .finally(function () {

        })
    }
  }
}

export default geoMixin
