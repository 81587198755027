import API from '@/api'

const state = {
  isAuth: false,
  userData: {
    token: sessionStorage.getItem('EvolveToken'),
    nome: '',
    email: '',
    photo: '',
    id: ''
  },
  accessToken: '',
  credentials: {
    'apiKey': process.env.VUE_APP_API_KEY,
    'password': process.env.VUE_APP_API_PASSWORD

  }
}

const getters = {
  IsAuth: (state) => {
    return state.isAuth
  },
  GetCredentials: (state) => {
    return state.credentials
  },
  GetUserData: (state) => {
    return state.userData
  }
}

const actions = {
  async SetCredentials ({ commit }, credentialsObj) {
    commit('SetCredentials', credentialsObj)
  },
  async Authenticate ({ commit, dispatch }, credentialsObj) {
    return new Promise((resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Auth: {
          authorize: 'LOADING'
        }
      })
      API.post(`/AuthApi/Authorize`, credentialsObj)
        .then(response => {
          commit('SetCredentials', credentialsObj)
          commit('SetAuthenticationStatus', true)
          commit('SetAccessToken', response.data.accessToken)
          sessionStorage.setItem(
            'EvolveToken',
            response.data.data.accessToken
          )
          dispatch('setCheckoutStatus', {
            Auth: {
              authorize: 'IDLE'
            }
          })
          resolve(response.data)
        })
        .catch(error => {
          dispatch('setCheckoutStatus', {
            Auth: {
              authorize: 'ERROR'
            }
          })
          dispatch('setAnalyticsError', { id: `AUTHORIZE_ERROR`, method: 'Authenticate', error: `Authenticate: ${error.message}`, fatal: true })
          commit('SetAuthenticationStatus', false)
          reject(new Error(`Authenticate: ${error.message}`))
        })
    })
  }
}

const mutations = {
  SetCredentials: (state, credentialsObj) => {
    state.userData = credentialsObj
  },
  SetAuthenticationStatus: (state, status) => {
    state.isAuth = status
  },
  SetAccessToken: (state, accessToken) => {
    state.accessToken = accessToken
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
