/* eslint-disable no-dupe-class-members */
import cardValidator from 'card-validator'

class EvHubPaymentValidator {
  validateCardNumber (cardNumber) {
    return cardValidator.number(cardNumber).isValid
  }

  validateSecurityCode (securityCode) {
    return cardValidator.cvv(securityCode, securityCode.length).isValid
  }

  validateExpirationDate (month, year) {
    return cardValidator.expirationDate({ month: month, year: year }).isValid
  }
  getCardBrand (cardNumber) {
    return cardValidator.number(cardNumber).card.type
  }
}

export default new EvHubPaymentValidator()
