<template>
  <el-card class="ShippingMethod__Resume">
        <ProductList :products="products" :method="method"/>
        <div class="ShippingMethod__Resume__Price" v-if="showShippingPrice">
          {{method.shipping_price ? formatMoney(method.shipping_price) : 'Grátis'}}
        </div>
    </el-card>
</template>

<script>
// @ is an alias to /src
import ProductList from '@/components/_shippingMethods/ProductList.vue'
import { mapFilters } from '@/helpers/filters'

export default {
  name: 'ShippingMethods',
  components: {
    ProductList
  },
  data () {
    return {}
  },
  computed: {
  },
  props: ['products', 'method', 'show-shipping-price'],
  methods: {
    ...mapFilters(['formatMoney'])
  }
}
</script>
