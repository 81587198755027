<template>
    <div>
      <el-col :span="24" v-if="ShowAlert">
          <el-alert
              :title="AlertTitle"
              type="error"
              :description="AlertDescription"
              show-icon
              :closable="IsClosable"
          ></el-alert>
          <br>
          <br>
      </el-col>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus from '@/helpers/eventBus'

export default {
  data () {
    return {
      invalidHashMessage: ''
    }
  },
  props: ['errorMessage'],
  mounted () {
    var $this = this

    eventBus.$on('creditCardHashFailed', function (error) {
      if (error) {
        $this.invalidHashMessage = $this.GetEvHubError(error)
      }

      $this.$store.commit('setAnalyticsError', {
        id: `CREDIT_CARD_VALIDATION_ERROR`,
        method: 'PaymentErrorComponent',
        error: `PaymentErrorComponent: ${error}`,
        fatal: true
      })

      $this.$store.commit('setCheckoutStatus', {
        PaymentOptions: {
          update: 'INVALID_CREDIT_CARD'
        }
      })
    })
  },
  computed: {
    ...mapGetters([
      'CheckoutStatus'
    ]),
    AlertTitle () {
      if (this.CheckoutStatus.PaymentOptions.update === 'INVALID_CREDIT_CARD') {
        return 'Cartão inválido'
      } else if (this.CheckoutStatus.PaymentOptions.update === 'NOT_SELECTED') {
        return 'Selecione um tipo de pagamento'
      } else if (this.CheckoutStatus.PaymentOptions.update === 'MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR') {
        return 'Revise os dados dos cartões inseridos'
      }

      return 'Não foi possível efetuar um pagamento com esse cartão'
    },
    AlertDescription () {
      if (this.errorMessage) {
        return this.errorMessage
      }

      if (this.invalidHashMessage) {
        return this.invalidHashMessage
      }

      if (this.CheckoutStatus.PaymentOptions.update === 'INVALID_CREDIT_CARD') {
        return 'Revise os dados do cartão ou atualize seu navegador. ' + this.invalidHashMessage
      } else if (this.CheckoutStatus.PaymentOptions.update === 'NOT_SELECTED') {
        return 'É necessário selecionar um tipo de pagamento para continuar. ' + this.invalidHashMessage
      } else if (this.CheckoutStatus.PaymentOptions.update === 'MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR') {
        return 'Não foi possível completar seu pagamento pois um dos cartões é inválido ou não possui limite suficiente, por favor revise os cartões e altere o que for necessário'
      }

      return 'Por favor tente novamente mais tarde. ' + this.invalidHashMessage
    },
    IsClosable () {
      return !(this.CheckoutStatus.PaymentOptions.update === 'INVALID_CREDIT_CARD')
    },
    ShowAlert () {
      return this.CheckoutStatus.PaymentOptions.update === 'INVALID_CREDIT_CARD' ||
      this.CheckoutStatus.PaymentOptions.update === 'NOT_SELECTED' ||
      this.CheckoutStatus.PaymentOptions.update === 'MULTIPLE_CREDIT_CARDS_COMPLETE_PAYMENT_ERROR'
    }
  },
  methods: {
    GetEvHubError (error) {
      if (error === "'Card cc_cvv' length error") {
        return 'Por favor revise o código de segurança de seu cartão.'
      }
      return error
    }
  }
}
</script>
