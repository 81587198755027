<template>
  <div class="Order__Section" v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.enabled && AppConfig.thankyou_page.post_purchase_widget.coupon.discount_code">
    <el-card class="PostPurchase--Coupon PostPurchase--Coupon__Widget" v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.enabled && AppConfig.thankyou_page.post_purchase_widget.coupon.discount_code">
      <div class="PostPurchase--Coupon__Content">
        <el-row class="PostPurchase--Coupon__Content--header">
        </el-row>
        <el-row class="PostPurchase--Coupon__Content--body">
          <el-col :span="24" class="PostPurchase--Coupon__Cell PostPurchase--Coupon__CouponArea">
            <h4 class="PostPurchase--Coupon__Content--header" v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.title" v-html="AppConfig.thankyou_page.post_purchase_widget.coupon.title"></h4>
            <span class="PostPurchase--Coupon__Content--description"  v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.description" v-html="AppConfig.thankyou_page.post_purchase_widget.coupon.description"></span>
            <div class="PostPurchase--Coupon--code" v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.discount_code">
              <Clipboard :value="AppConfig.thankyou_page.post_purchase_widget.coupon.discount_code" :btn_type="'text'"/>
            </div>
            <span class="PostPurchase--Coupon__Content--disclaimer" v-if="AppConfig.thankyou_page.post_purchase_widget.coupon.disclaimer">
                <small v-html="AppConfig.thankyou_page.post_purchase_widget.coupon.disclaimer"></small>
            </span>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import Clipboard from '@/components/_ui-components/Clipboard.vue'

export default {
  name: 'PostPurchaseCouponWidget',
  data () {
    return {
      isPostPurchaseCouponCodeCopied: false,
      PostPurchaseCouponDetails: null
    }
  },
  props: ['line_code'],
  components: {
    Clipboard
  },
  computed: {
    ...mapGetters(['AppConfig', 'Checkout', 'ActiveShippingMethod', 'ActivePaymentMethod', 'PaymentOptions'])
  },
  created () {
  },
  methods: {
    ...mapFilters(['formatMoney', 'dateFormat', 'formatDocument']),
    ...mapActions([
      '_buildSuccessAlert'
    ]),

    copyToClipboard () {
      var PostPurchaseCouponCode = document.getElementById('copy')
      PostPurchaseCouponCode.select()
      document.execCommand('copy', false)
      this.isPostPurchaseCouponCodeCopied = true
      setTimeout(() => {
        this.isPostPurchaseCouponCodeCopied = false
      }, 3000)
      this._buildSuccessAlert({
        title: 'Código copiado',
        success: ''
      })
    },
    async downloadPostPurchaseCoupon (url) {
      window.open(url, '_blank')
      // let PostPurchaseCouponService = new PostPurchaseCouponService('PostPurchaseCouponDownload')
      // PostPurchaseCouponService.downloadPostPurchaseCoupon(url).then(() => {
      //   this.isPostPurchaseCouponDownloaded = true
      //   this._buildSuccessAlert({
      //     title: 'Boleto Baixado!',
      //     success: ''
      //   })
      // })
    }
  },
  watch: {
  }

}
</script>
