import Vue from 'vue'
// import Element from 'element-ui'

import {
  Dialog,
  Input,
  InputNumber,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  RadioButton,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Form,
  FormItem,
  Tag,
  Alert,
  Row,
  Col,
  Card,
  Collapse,
  CollapseItem,
  Divider,
  Loading,
  MessageBox,
  Message,
  Notification,
  Popover,
  Link,
  Tooltip,
  DatePicker
} from 'element-ui'
import locale from 'element-ui/lib/locale'
import ptBR from 'element-ui/lib/locale/lang/pt-br'

locale.use(ptBR)

Vue.use(Dialog)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tag)
Vue.use(Alert)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Divider)
Vue.use(Link)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(DatePicker)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

// Vue.use(Element, { locale })
