import Product from '../services/product'
import EventBus from '../helpers/eventBus'
const state = {
}

const getters = {
}

const actions = {
  AddUpsellProduct ({ commit, dispatch, getters }, ProductsArray) {
    dispatch('setCheckoutStatus', {
      Products: {
        loading: true
      }
    })

    Product.add(ProductsArray).then(resp => {
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: resp.products,
        discount_codes: resp.discount_codes,
        totals: {
          ...getters.Checkout.totals,
          ...resp.totals
        }
      })
      dispatch('setCheckoutStatus', {
        Products: {
          loading: false
        }
      })
      EventBus.$emit('productsUpdated')
    }).catch((error) => {
      console.log(error)
      dispatch('setCheckoutStatus', {
        Products: {
          loading: false
        }
      })
    })
  },
  UpdateUpsellProduct ({ commit, dispatch, getters }, { variantId, quantity }) {
    dispatch('setCheckoutStatus', {
      Products: {
        loading: true
      }
    })
    Product.update(variantId, quantity).then(resp => {
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: resp.products,
        discount_codes: resp.discount_codes,
        totals: {
          ...getters.Checkout.totals,
          ...resp.totals
        }
      })

      dispatch('setCheckoutStatus', {
        Products: {
          loading: false
        }
      })
      EventBus.$emit('productsUpdated')
    }).catch(error => {
      console.log(error)
      dispatch('setCheckoutStatus', {
        Products: {
          loading: false
        }
      })
    })
  },
  RemoveUpsellProduct ({ commit, dispatch, getters }, { variantId }) {
    dispatch('setCheckoutStatus', {
      Products: {
        loading: true
      }
    })
    Product.remove(variantId).then(resp => {
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: resp.products,
        discount_codes: resp.discount_codes,
        totals: {
          ...getters.Checkout.totals,
          ...resp.totals
        }
      })
      dispatch('setCheckoutStatus', {
        Products: {
          loading: false
        }
      })
      EventBus.$emit('productsUpdated')
    }).catch(error => {
      console.log(error)
      dispatch('setCheckoutStatus', {
        Products: {
          loading: false
        }
      })
    })
  },
  AddProduct ({ commit, dispatch, getters }, ProductsArray) {
    return new Promise(async (resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Products: {
          add: 'LOADING'
        }
      })
      try {
        let _Checkout = await Product.add(ProductsArray)
        commit('UpdateCheckoutState', {
          ...getters.Checkout,
          products: _Checkout.products,
          discount_codes: _Checkout.discount_codes,
          totals: {
            ...getters.Checkout.totals,
            total_discount: _Checkout.totals.total_discount,
            total_without_discounts: _Checkout.totals.total_without_discounts,
            total: _Checkout.totals.total
          }
        })
        EventBus.$emit('productsUpdated')
        dispatch('setCheckoutStatus', {
          Products: {
            add: 'IDLE'
          }
        })
      } catch (error) {
        dispatch('setCheckoutStatus', {
          Products: {
            add: 'ERROR'
          }
        })

        dispatch('setAnalyticsError', { id: `${getters.Checkout.id}`, method: 'AddProduct', error: error.message, fatal: true })
        return reject(new Error('AddProduct: ' + error))
      }
    })
  },

  UpdateProduct ({ commit, dispatch, getters }, { variantId, quantity }) {
    return new Promise(async (resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Products: {
          add: 'LOADING'
        }
      })
      try {
        let _Checkout = await Product.update(variantId, quantity)
        commit('UpdateCheckoutState', {
          ...getters.Checkout,
          products: _Checkout.products,
          discount_codes: _Checkout.discount_codes,
          totals: {
            ...getters.Checkout.totals,
            total_discount: _Checkout.totals.total_discount,
            total_without_discounts: _Checkout.totals.total_without_discounts,
            total: _Checkout.totals.total
          }
        })
        EventBus.$emit('productsUpdated')
        dispatch('setCheckoutStatus', {
          Products: {
            add: 'IDLE'
          }
        })
        console.log('UPDATED_PROMOTIONS')
      } catch (error) {
        dispatch('setCheckoutStatus', {
          Products: {
            add: 'ERROR'
          }
        })

        dispatch('setAnalyticsError', { id: `${getters.Checkout.id}`, method: 'UpdateProduct', error: error.message, fatal: true })
        return reject(new Error('UpdateProduct: ' + error))
      }
    })
  },
  RemoveProduct ({ commit, dispatch, getters }, { variantId, quantity }) {
    return new Promise(async (resolve, reject) => {
      dispatch('setCheckoutStatus', {
        Products: {
          add: 'LOADING'
        }
      })
      try {
        let _Checkout = await Product.remove(variantId)
        commit('UpdateCheckoutState', {
          ...getters.Checkout,
          products: _Checkout.products,
          discount_codes: _Checkout.discount_codes,
          totals: {
            ...getters.Checkout.totals,
            total_discount: _Checkout.totals.total_discount,
            total_without_discounts: _Checkout.totals.total_without_discounts,
            total: _Checkout.totals.total
          }
        })
        EventBus.$emit('productsUpdated')
        dispatch('setCheckoutStatus', {
          Products: {
            add: 'IDLE'
          }
        })
      } catch (error) {
        dispatch('setCheckoutStatus', {
          Products: {
            add: 'ERROR'
          }
        })

        dispatch('setAnalyticsError', { id: `${getters.Checkout.id}`, method: 'RemoveProduct', error: error.message, fatal: true })
        return reject(new Error('RemoveProduct: ' + error))
      }
    })
  }
}

const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
