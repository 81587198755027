<template>
  <div :class="`Sidebar__Resume Resume Resume--${type}`" ref="SidebarResume">
    <div class="Resume__Content" v-if="type==='full'">
      <!-- <pre>{{Discounts}}</pre> -->
      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
        <div
          class="Resume--Item"
          v-if="TotalProducts && TotalProducts > 0"
        >
          <span>Subtotal:</span>
          <span>
            <b class="moneyFormat">{{formatMoney(TotalProducts)}}</b>
          </span>
        </div>
      </transition>
      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <div
        class="Resume--Item Resume--TotalShipping"
        :class="{'Resume--TotalShipping--Free': TotalShipping === 0}"
        v-if="ShouldDisplayShipping"
      >
        <span>Frete:</span>
        <span>
          <b class="moneyFormat text-success">{{(TotalShipping && TotalShipping > 0) ? formatMoney(TotalShipping) : 'Grátis'}}</b>
        </span>
      </div>
      </transition>
      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <div
        class="Resume--Item"
        v-if="TotalDiscounts && TotalDiscounts > 0"
      >
        <span>Descontos:</span>
        <span>
          <b class="moneyFormat">- {{formatMoney(TotalDiscounts)}}</b>
        </span>
      </div>
      </transition>

      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <div
        class="Resume--Item"
        v-if="TotalGiftCards && TotalGiftCards > 0"
      >
        <span>Cartão-Presente:</span>
        <span>
          <b class="moneyFormat">- {{formatMoney(TotalGiftCards)}}</b>
        </span>
      </div>
      </transition>

      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <div
        class="Resume--Item"
        v-if="TotalAdditions && TotalAdditions > 0"
      >
        <span>Acréscimos:</span>
        <span>
          <b class="moneyFormat">{{formatMoney(TotalAdditions)}}</b>
        </span>
      </div>
      </transition>

      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <div
        class="Resume--Item Resume--Total"
        v-if="checkoutTotal && checkoutTotal > 0"
      >
        <span>Total:</span>
        <span>
          <b class="moneyFormat">{{formatMoney(checkoutTotal)}}</b>
          <small v-loading="CheckoutStatus.PaymentOptions.get === 'LOADING'" v-html="PaymentConditionsMessage" v-if="PaymentConditionsMessage"></small>
        </span>
      </div>

      <div
        class="Resume--Item Resume--Total"
        v-if="checkoutTotal == 0"
      >
        <span>Total:</span>
        <span>
          <b>GRÁTIS &#128516;</b>
          <small v-loading="CheckoutStatus.PaymentOptions.get === 'LOADING'"></small>
        </span>
      </div>
      </transition>
    </div>
    <div class="Resume__Content" v-if="type==='small'">
      <transition @before-enter="enterAnimation" @leave="leaveAnimation">
      <div
        class="Resume--Item"
        v-if="resume.total && resume.total > 0"
      >
        <small>
          <i class="el-icon el-icon-shopping-cart-2"></i>
          <span>
            Exibir resumo da compra:
            <b class="moneyFormat">{{AppConfig.translations.resume.mobile.apply_coupon_cta}}</b>
          </span>
        </small>
        <span>
          <b class="moneyFormat">{{formatMoney(checkoutTotal)}}</b>
          <small class="Resume--Item__payment-conditions" v-loading="CheckoutStatus.PaymentOptions.get === 'LOADING'" v-html="PaymentConditionsMessage" v-if="PaymentConditionsMessage"></small>
        </span>
      </div>
      </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import { mapFilters } from '@/helpers/filters'
import Animation from '@/services/animation'

export default {
  name: 'Resume',
  components: {},
  data () {
    return {
      discountCode: ''
      // PaymentConditionsMessage: null
    }
  },
  props: ['resume', 'type'],
  created () {
    // this.initPaymentConditionsMessage()
  },
  computed: {
    ...mapGetters([
      'AppConfig',
      'CheckoutStatus',
      'Checkout',
      'CheckoutConfig',
      'ActiveShippingMethod',
      'Discounts',
      'GiftCards',
      'Additions',
      'ActivePaymentMethod',
      'PaymentOptions'
    ]),
    ShouldDisplayShipping () {
      return !this.IsContactInformationStep() &&
              this.Checkout.requires_shipping &&
              (this.ActiveShippingMethod?.handle || this.Checkout.chosen_delivery_option.handle)
    },
    TotalProducts () {
      if (this.resume.total_without_discounts) {
        return this.resume.total_without_discounts
      }
      return null
    },
    TotalShipping () {
      if (this.ActiveShippingMethod) {
        return this.ActiveShippingMethod.shipping_price
      }
      return this.resume.shipping
    },
    TotalGiftCards () {
      return this.Checkout.totals.total_gift_cards
    },
    TotalDiscounts () {
      if (this.Discounts && this.Discounts.length > 0) {
        var descontos = this.Discounts.reduce(function (prev, cur) {
          return prev + cur.amount
        }, 0)
        return this.Checkout.totals.total_discount + descontos
      }
      return this.Checkout.totals.total_discount
    },
    TotalAdditions () {
      if (this.Additions && this.Additions.length > 0) {
        return this.Additions.reduce(function (prev, cur) {
          return prev + cur.amount
        }, 0)
      }
      if (this.resume.addition) {
        return this.resume.addition
      }
      return null
    },
    checkoutTotal () {
      return ((this.TotalProducts ? this.TotalProducts : 0) + (this.TotalShipping ? this.TotalShipping : 0)) + (this.TotalAdditions ? this.TotalAdditions : 0) - (this.TotalDiscounts ? this.TotalDiscounts : 0) - (this.TotalGiftCards ? this.TotalGiftCards : 0)
    },
    PaymentConditionsMessage () {
      let PaymentConditionsMessage
      let minimumInstallment = this.CheckoutConfig.paymentInformationConfig.credit_card_config.minimum_installment_value
      if (!this.PaymentOptions) {
        return null
      }
      let Installments = this.PaymentOptions ? this.PaymentOptions.find((paymentOption) => {
        return paymentOption.type === 'credit_card'
      }).installments.filter(_i => {
        return _i.installments === 1 || _i.value >= minimumInstallment
      }) : null
      let maxInterestFreeInstallment = Installments ? Installments.filter((installment) => {
        return installment.interest_rate === 0
      }).length : null
      let lastInstallment = Installments ? Installments.filter((installment) => {
        return installment.installments === Installments.length
      })[0] : null

      if (!this.ActivePaymentMethod) {
        PaymentConditionsMessage = `Em até ${maxInterestFreeInstallment}x sem juros ${maxInterestFreeInstallment !== lastInstallment.installments ? `<br> Ou até ${lastInstallment.installments}x de ${this.formatMoney(lastInstallment.value)}` : ``}`
      } else {
        let hasInstallmentInfos = () => {
          return (
            !!this.ActivePaymentMethod &&
            !!this.ActivePaymentMethod.installments &&
            !!this.ActivePaymentMethod.value
          )
        }
        let hasInterestInfos = () => {
          return (
            !!this.ActivePaymentMethod &&
            (!!this.ActivePaymentMethod.interest_rate || !!this.ActivePaymentMethod.discount_rate)
          )
        }

        let interestRateMessage = hasInterestInfos() ? `${this.ActivePaymentMethod.interest_rate > 0 ? `Juros de ${this.ActivePaymentMethod.interest_rate}%` : this.ActivePaymentMethod.interest_rate === 0 ? 'Sem juros' : `Desconto de: ${this.ActivePaymentMethod.discount_rate}%`}` : ``
        let installmentMessage = hasInstallmentInfos() ? this.ActivePaymentMethod.installments === 1 ? `A vista` : `${this.ActivePaymentMethod.installments}x de ${this.formatMoney(this.ActivePaymentMethod.value)}` : ``

        PaymentConditionsMessage = `${installmentMessage} <br/> ${interestRateMessage}`
      }
      return PaymentConditionsMessage
    }
  },
  methods: {
    ...mapActions(['UpdateCheckoutState', 'GetPaymentOptions', 'AddDiscount', 'DeleteDiscount']),
    ...mapFilters(['formatMoney', 'formatDiscountValue']),
    IsContactInformationStep () {
      return this.Checkout.activeStep < 2
    },
    initPaymentConditionsMessage () {
      if (this.PaymentOptions) {
        // this.GetPaymentConditionsMessage()
      } else {
        this.GetPaymentOptions(this.Checkout.id).then((PaymentOptions) => {
          this.initPaymentConditionsMessage()
        })
      }
    },
    enterAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .from(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    },
    leaveAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .to(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    },
    handleClose (discountCode) {
      this.UpdateCheckoutState({
        discount_code: {
          code: '',
          valid: false
        },
        totals: {
          ...this.resume,
          discount: 0
        }
      })
    }
  },
  watch: {
    'ActivePaymentMethod': {
      deep: true,
      handler (val) {
        // this.initPaymentConditionsMessage()
      }
    }
    // 'Checkout.discount_codes': {
    //   deep: true,
    //   handler (val) {
    //     this.AddDiscount({
    //       id: `discount_coupon--${val.code}`,
    //       category: 'discount_coupon',
    //       type: val.discountType,
    //       amount: val.amount,
    //       name: `Cupom ${val.code}`
    //     })
    //   }
    // }
  }
}
</script>
