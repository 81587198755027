// import { mapGetters } from 'vuex'

const form = {
  data () {
    return {
      activeInput: null
    }
  },
  computed: {
  },
  methods: {
    setActiveInput (input) {
      this.activeInput = input
    }
  }
}

export default form
