
let config = require(`./${process.env.VUE_APP_COMPANY_NAME}/app.config.json`)
config.baseURL = process.env.BASE_URL
config.checkout_version = config.checkout_version || 'subfolder'

config.assets = config.assets || {}
config.assets.images = config.assets.images || {}
if (!config?.assets?.images?.logo) {
  let logoImagePath = require(`@/assets/logo/${config.handle}/main.png`)
  if (config.checkout_version !== 'subfolder') {
    logoImagePath = `https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${config.handle}/images/logo.png`
  }
  config.assets.images.logo = logoImagePath
}
if (!config?.assets?.images?.splash) {
  let splashImagePath = require(`@/assets/images/${config.handle}/2x/Arrival__Loading__Icon--has-token@2x.png`)
  if (config.checkout_version !== 'subfolder') {
    splashImagePath = `https://cdn-evhubcheckout.sfo3.cdn.digitaloceanspaces.com/${config.handle}/images/splash.png`
  }
  config.assets.images.splash = splashImagePath
}
export default config
