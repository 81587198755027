import API from '@/api'
import Search from '@/services/search'

const state = {
  GiftCards: []
}

const getters = {

}

const actions = {
  async ApplyGiftCard ({ commit, getters, dispatch }, { checkoutId, giftCard }) {
    dispatch('setCheckoutStatus', {
      DiscountCode: {
        add: 'LOADING'
      }
    })

    await API.post(`/checkouts/${checkoutId}/GiftCards/${giftCard}`, {}).then(res => {
      dispatch('setCheckoutStatus', {
        DiscountCode: {
          add: 'IDLE'
        }
      })
      // eslint-disable-next-line no-debugger
      // debugger
      // commit('AddGiftCard', resp.data.gift_cards)
      // commit('SetCheckoutState', resp.data)
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: res.data.products,
        gift_cards: res.data.gift_cards.filter(giftCard => { return giftCard.code }),
        totals: {
          ...getters.Checkout.totals,
          total_discount: res.data.totals.total_discount,
          total_gift_cards: res.data.totals.total_gift_cards,
          total_without_discounts: res.data.totals.total_without_discounts,
          total: res.data.totals.total
        }
      })
      // var appliedGift = resp.data.gift_cards.filter(gift => gift.Code === giftCard)[0]

      // commit('UpdateCheckoutState', {
      //   gift_cards: resp.data.gift_cards,
      //   // discount_code: {
      //   //   code: appliedGift.Code,
      //   //   amount: appliedGift.Amount,
      //   //   discountType: 'gift-card',
      //   //   valid: true
      //   // },
      //   totals: {
      //     ...getters.Checkout.totals,
      //     giftCard: resp.data.gift_cards.reduce((prev, cur) => {
      //       return prev.amount + cur.amount
      //     })
      //   }
      // })
    }).catch(err => {
      dispatch('setAnalyticsError', { id: `checkoutId=${checkoutId}&code=${giftCard}`, method: 'ApplyGiftCard', error: `${err.message}`, fatal: true })
      return new Error(`ApplyGiftCard: ${err.message}`)
    })
  },
  async RemoveGiftCard ({ commit, dispatch, getters }, { checkoutId, giftCard }) {
    dispatch('setCheckoutStatus', {
      GiftCard: {
        delete: 'LOADING'
      }
    })
    await API.delete(`/checkouts/${checkoutId}/GiftCards/${giftCard}`).then(res => {
      dispatch('setCheckoutStatus', {
        GiftCard: {
          delete: 'IDLE'
        }
      })
      commit('UpdateCheckoutState', {
        ...getters.Checkout,
        products: res.data.products,
        gift_cards: res.data.gift_cards,
        totals: {
          ...getters.Checkout.totals,
          total_discount: res.data.totals.total_discount,
          total_gift_cards: res.data.totals.total_gift_cards,
          total_without_discounts: res.data.totals.total_without_discounts,
          total: res.data.totals.total
        }
      })
    }).catch(err => {
      dispatch('setCheckoutStatus', {
        GiftCard: {
          delete: 'IDLE'
        }
      })
      dispatch('setAnalyticsError', { id: `checkoutId=${checkoutId}&code=${giftCard}`, method: 'RemoveGiftCard', error: `${err.message}`, fatal: false })
      return new Error(`RemoveGiftCard: ${err.message}`)
    })
  }
}

const mutations = {
  AddGiftCard: function (state, giftCards) {
    state.GiftCards = giftCards
    console.log('AddGiftCard: ', state.GiftCards)
  },
  RemoveGiftCard: function (state, { key, value }) {
    state.GiftCards = Search.query(state.GiftCards, key, 'not-equals', value)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
