// import API from '@/api'
// import store from '@/store'
import Promotions from '@/services/promotion'
// import EventBus from '@/helpers/eventBus'

class Checkout {
  _validators = {
    Promotions: async () => { await Promotions.validateCheckoutPromotions() }
  }
  async validate () {
    // let Checkout = store.getters.Checkout
    try {
      await this._validators.Promotions()
      return true
    } catch (error) {
      throw new Error(error.message)
    }
  }
}
export default new Checkout()
