/* eslint-disable no-unused-expressions */

import Boleto from 'boleto.js'

class BankSlipService {
  async GetBankslipDetails (lineCode) {
    var bankslip = new Boleto(lineCode)
    console.log(bankslip)

    return {

      number: bankslip.number(),
      prettyNumber: bankslip.prettyNumber(),

      barcode: bankslip.barcode(),
      barcode_image: bankslip.toSVG(),
      checksum: bankslip.checksum(),

      amount: parseFloat(bankslip.amount()),
      prettyAmount: bankslip.prettyAmount(),
      bank: bankslip.bank(),
      currency: bankslip.currency(),
      expirationDate: bankslip.expirationDate()
    }
  }
}

export default new BankSlipService()
