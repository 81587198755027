<template>
  <el-form
    ref="PaymentInformations"
    class="PaymentMethod__Form--Custom Form--Custom"
  >
    <div class="PaymentMethod__Form--PayPal">
      <el-row :gutter="20">
        <div id="ppplus">
        </div>
      </el-row>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { stringHelper, dateHelper, Base64Helper } from '../../../../helpers'
import eventBus from '@/helpers/eventBus'

export default {
  name: 'PayPalForm',
  data () {
    return {
      ppp: null,
      paymentHash: ''
    }
  },
  props: ['method'],
  mounted () {
    var $this = this
    eventBus.$on('continuePayment', function (shouldContinue) {
      if (shouldContinue) {
        $this.doContinue()
      }
    })
    // eslint-disable-next-line no-undef, no-unused-vars
    this.ppp = PAYPAL.apps.PPP({
      'approvalUrl': this.method.payment_gateway_url_reference,
      'placeholder': 'ppplus',
      'mode': 'live',
      'payerEmail': this.Checkout.customer.email,
      'payerFirstName': this.Checkout.customer.first_name,
      'payerLastName': this.Checkout.customer.last_name,
      'payerPhone': this.Checkout.customer.phone,
      'payerTaxId': stringHelper.removeSpecialChars(this.Checkout.customer.document),
      'disallowRememberedCards': true,
      'country': 'BR',
      'language': 'pt_BR',
      'merchantInstallmentSelectionOptional': true
    })
    window.addEventListener('message', $this.handlePayPalMessage, false)
  },
  computed: {
    ...mapGetters(['Checkout'])
  },
  methods: {
    ...mapActions(['UpdatePaymentState', 'SetPaymentMethod']),
    async generateHashAndUpdatePaymentInfo () {
      await this.wait()
      if (!this.paymentHash) {
        throw new Error('Por favor revise os dados do cartão.')
      }

      this.UpdatePaymentState({
        default_hash: {
          hash: this.paymentHash,
          successfullyCreated: true
        },
        fallback_hash: {
          hash: '',
          successfullyCreated: false
        },
        brand: 'paypal',
        last_four_numbers: '0000',
        full_name: this.Checkout.customer.first_name,
        document: this.Checkout.customer.document,
        installmentId: this.method.installments[0]?.id
      })

      this.SetPaymentMethod(this.method.installments[0])

      return this.paymentHash
    },
    async wait() {
      var count = 10
      while (!this.paymentHash && count > 0) {
        await dateHelper.wait(1000)
        count--
      }
    },
    doContinue () {
      this.ppp.doContinue()
    },
    handlePayPalMessage (event) {
      var data = JSON.parse(event.data)
      if (data?.result?.id) {
        var base64Helper = new Base64Helper()
        var hash = {
          cardToken: data?.result?.id,
          payerId: data?.result?.payer?.payer_info?.payer_id
        }

        this.paymentHash = base64Helper.encode(hash)
      }
    }
  }
}
</script>
