import API from '@/api'
import store from '@/store'
import EventBus from '@/helpers/eventBus'

class Product {
  async add (ProductsArray) {
    return new Promise(async (resolve, reject) => {
      let Checkout = store.getters.Checkout
      await API.post(`/checkouts/${Checkout.id}/Products`, ProductsArray)
        .then(res => {
          let Promotions = res.data
          EventBus.$emit('added-product', Promotions)
          EventBus.$emit('checkout-updated', Promotions)

          resolve(Promotions)
        })
        .catch(() => {
          return reject(new Error('Erro ao adicionar produto'))
        })
    })
  }
  async update (variantId, quantity) {
    return new Promise(async (resolve, reject) => {
      let Checkout = store.getters.Checkout
      await API.put(`/checkouts/${Checkout.id}/Products`, {
        variant_id: variantId,
        quantity
      })
        .then(res => {
          let Promotions = res.data

          EventBus.$emit('updated-product', Promotions)
          EventBus.$emit('checkout-updated', Promotions)

          resolve(Promotions)
        })
        .catch(() => {
          return reject(new Error('Erro ao atualizar produto'))
        })
    })
  }
  async remove (variantId) {
    return new Promise(async (resolve, reject) => {
      let Checkout = store.getters.Checkout
      await API.delete(`/checkouts/${Checkout.id}/Products/${variantId}`)
        .then(res => {
          let Promotions = res.data
          EventBus.$emit('deleted-product', Promotions)
          EventBus.$emit('checkout-updated', Promotions)

          resolve(Promotions)
        })
        .catch(() => {
          return reject(new Error('Erro ao remover produto'))
        })
    })
  }
}

export default new Product()
