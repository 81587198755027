const state = {
  ShowUnavailableItems: false,
  UnavailableItems: null
}

const getters = {
  UnavailableItems: function (state) {
    return state.UnavailableItems
  },
  ShowUnavailableItems: function (state) {
    return state.ShowUnavailableItems
  }
}

const actions = {
  ShowUnavailableItems ({ commit }) {
    commit('SetShowUnavailableItems', true)
  },
  CloseUnavailableItems ({ commit, dispatch }) {
    dispatch('SetUnavailableItems', null)
    commit('SetShowUnavailableItems', false)
  },
  SetUnavailableItems ({ commit, dispatch }, unavailableItems) {
    commit('SetUnavailableItems', unavailableItems)
    dispatch('ShowUnavailableItems')
  }
}

const mutations = {
  setProductDetails: function ({ rootState, state }, ProdOBJ) {
    let updatedProducts = state.Checkout.products.map(item => {
      if (item.handle === ProdOBJ.handle) {
        return {
          ...item,
          product_details: ProdOBJ.details
        }
      }
      return item
    })
    state.cart.items = updatedProducts.slice()
  },
  SetShowUnavailableItems: function (state, dialogState) {
    state.ShowUnavailableItems = dialogState
  },
  SetUnavailableItems: function (state, unavailableItems) {
    state.UnavailableItems = unavailableItems
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
