<template>
  <el-dialog title="Não sei meu CEP" :visible.sync="isModalVisible" @closed="handleModalClosed">
    <!-- <h1 @click="toggleModalVisible()">Não sei meu CEP</h1> -->
    <el-form ref="findZipForm" :model="findZipForm" :rules="rules" class="Checkout__Form--Custom Form--Custom findZip--Form">
      <div class="Checkout__Section">
        <h2 class="Checkout__Section__Title">Preencha o endereço:</h2>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24">
            <div class="el-form-outer">
              <el-form-item
                prop="street"
                label="Rua"
                :class="{'el-form-item--active': activeInput === 'Rua'}"
              >
                <el-input
                  placeholder="Rua"
                  ref="findZipForm.street"
                  v-model="findZipForm.street"
                  @focus="setActiveInput('Rua')"
                  @blur="setActiveInput(null)"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24" :md="12">
            <div class="el-form-outer">
              <el-form-item
                prop="city"
                label="Cidade"
                :class="{'el-form-item--active': activeInput === 'Cidade'}"
              >
                <el-input
                  placeholder="Cidade"
                  ref="findZipForm.city"
                  v-model="findZipForm.city"
                  @focus="setActiveInput('Cidade')"
                  @blur="setActiveInput(null)"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="24" :md="12">
            <div class="el-form-outer">
              <el-form-item
                prop="state"
                label="Estado"
                :class="{'el-form-item--active': activeInput === 'Estado'}"
              >
                <el-select
                  ref="findZipForm.state"
                  v-model="findZipForm.state"
                  placeholder="Selecione"
                  @focus="setActiveInput('Estado')"
                  @blur="setActiveInput(null)"
                >
                  <el-option
                    v-for="item in states"
                    :key="item.uf"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="center">
          <el-col :span="24" :md="12">
            <el-button
              type="primary"
              class="el-button--block Checkout__Button--primary"
              @click="findZipCode('findZipForm')"
              :loading="CheckoutStatus.ContactInfos.update === 'LOADING'"
            >
              Continuar
              <i class="el-icon-arrow-right"></i>
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <br>
    <br>
    <div v-if="foundZipCodeResults && foundZipCodeResults.length > 0" class="findZip--Results">
      <el-row :gutter="20">
        <el-col :span="24" :md="12" v-for="(result, index) in foundZipCodeResults" :key="index">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{result.zip}}</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text" @click="selectZip(result)">Selecionar</el-button> -->
            </div>
            <div class="el-card__list--item">
                <div class="item">
                  <small class="title h6">Rua:</small>
                  <span>{{result.street}}</span>
                </div>
                <div class="item">
                  <small class="title h6">Bairro:</small>
                  <span>{{result.neighborhood}}</span>
                </div>
                <div class="item">
                  <small class="title h6">Cidade:</small>
                  <span>{{result.city}}</span>
                </div>
                <div class="item">
                  <small class="title h6">Estado:</small>
                  <span>{{result.state.name}}</span>
                </div>
                <br>
                <div class="item">
                  <el-button
                    type="primary"

                    class="el-button--block"
                    @click="selectZip(result)"
                  >
                    Selecionar
                    <i class="el-icon-arrow-right"></i>
                  </el-button>
                </div>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </div>
    <el-alert
      v-if="foundZipCodeResults && foundZipCodeResults.length === 0"
      title="Ops, nada encontrado"
      type="error"
      description="Não foi possível encontrar resultado com a sua pesquisa"
      show-icon
    ></el-alert>
    </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Animation from '@/services/animation'
import geoMixin from '@/_mixins/geoMixin'
import formMixin from '@/_mixins/form'

export default {
  name: 'FindZipCodeModal',
  components: {
  },
  props: ['visible'],
  mixins: [geoMixin, formMixin],
  data () {
    return {
      isModalVisible: false,
      findZipForm: {
        street: null,
        city: null,
        state: null
      },
      rules: {
        'street': [
          { required: true, message: 'Digite sua rua', trigger: 'change' }
        ],
        'city': [
          { required: true, message: 'Digite sua cidade', trigger: 'change' }
        ],
        'state': [
          { required: true, message: 'Digite seu estado', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['Checkout', 'CheckoutStatus']),
    modalVisible () {
      return this.visible
    }
    // isModalVisible
  },

  methods: {
    ...mapActions(['UpdateCheckoutState']),
    enterAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .from(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    },
    leaveAnimation (el) {
      const timeline = Animation.timeline
      timeline
        .to(el, { duration: 0.5, y: -5, opacity: 0, stagger: 0.1, ease: 'expo.inOut' })
    },
    toggleModalVisible () {
      this.isModalVisible = !this.isModalVisible
    },
    handleModalClosed () {
      this.$emit('modal-closed')
    },
    findZipCode (zipForm) {
      this.$refs[zipForm].validate(valid => {
        if (valid) {
          this.findZipCodeResults(this.findZipForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    selectZip (address) {
      let addressObj = address

      this.UpdateCheckoutState({
        shipping_info: {
          ...this.Checkout.shipping_info,
          address: {
            ...this.Checkout.shipping_info.address,
            zip_code: addressObj.zip,
            street: addressObj.street,
            neighborhood: addressObj.neighborhood,
            city: addressObj.city,
            state: addressObj.state.name
          }
        }
      })
      this.toggleModalVisible()
    }
  },
  watch: {
    visible: function (val) {
      this.isModalVisible = val
    }
  }
}
</script>
