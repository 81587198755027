/* eslint-disable curly */
/* eslint-disable indent */
import { PaymentServices } from './paymentServices'
import { MoipCreditCard, MoipValidator } from 'moip-sdk-js'
import JSEncrypt from 'node-jsencrypt'

export class MoipServices extends PaymentServices {
  async createPaymentHash (number, cvc, expirationMonth, expirationYear) {
    var publishableKey = this.pubKey
    if (publishableKey) {
      var paymentHash = await MoipCreditCard.setEncrypter(JSEncrypt, 'node')
        .setPubKey(publishableKey)
        .setCreditCard({
          number: number.replace(/\s/g, ''),
          cvc: cvc,
          expirationMonth: expirationMonth,
          expirationYear: expirationYear
        })
        .hash()
      return paymentHash
    }
    throw new ReferenceError('PubKey cannot be null or undefined')
  }

  getCardBrand (cardNumber) {
    var cardBrand = MoipValidator.cardType(cardNumber)
    if (cardBrand)
        return cardBrand.brand
    return ''
  }

  validateNumber (cardNumber) {
    return MoipValidator.isValidNumber(cardNumber)
  }

  validateExpirationDate (month, year) {
    return MoipValidator.isExpiryDateValid(month, year)
  }

  validateSecurityCode (cardNumber, securityCode) {
    return MoipValidator.isSecurityCodeValid(cardNumber, securityCode)
  }
}
